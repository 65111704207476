import Auth from "@aws-amplify/auth";
import React from "react";
import i18n from "../i18n";
import { Provider } from "../libs/ContextApi";

export default class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      user: "",
      isRemembered: false,
      userGroup: "",
      idPublisher: "",
      userLanguage: ""
    };
  }

  async componentWillMount() {
    let user = await Auth.currentSession();

    if (user) {
      this.setUser(user);
    } else {
      await Auth.signOut();
      this.userHasAuthenticated(false);
      window.localStorage.clear();
      this.props.history.push("/");
    }
  }

  userHasAuthenticated = (authenticated) => {
    window.localStorage.setItem("isAuthenticated", authenticated);
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    let userGroup = "";
    let idPublisher = "";
    let userLanguage = "";
    window.localStorage.setItem("user", JSON.stringify(user));
    const userInfo = { ...user };

    if (
      userInfo.idToken.payload["cognito:groups"] &&
      userInfo.idToken.payload["cognito:groups"][0]
    ) {
      userGroup = userInfo.idToken.payload["cognito:groups"][0];
      window.localStorage.setItem("userGroup", userGroup);
    }

    if (userInfo.idToken.payload["custom:org"]) {
      idPublisher = userInfo.idToken.payload["custom:org"];
      window.localStorage.setItem("idPublisher", idPublisher);
    }

    if (userInfo.idToken.payload["custom:preferred_language"]) {
      userLanguage = userInfo.idToken.payload["custom:preferred_language"];
      window.localStorage.setItem("userLanguage", userLanguage);
      i18n.changeLanguage(userLanguage);
      document.documentElement.lang = userLanguage;
    }
    this.userHasAuthenticated(true);
    this.setState({ user: userInfo, userGroup, idPublisher, userLanguage });
  };

  setRememberMe = (isRemembered) => {
    this.setState({ isRemembered });
    window.localStorage.setItem("isRemembered", isRemembered);
  };

  render() {
    return (
      <Provider
        value={{
          userHasAuthenticated: this.userHasAuthenticated,
          isAuthenticated: this.state.isAuthenticated,
          setUser: this.setUser,
          user: this.state.user,
          setRememberMe: this.setRememberMe,
          isRemembered: this.state.isRemembered,
          userGroup: this.state.userGroup,
          idPublisher: this.state.idPublisher,
          userLanguage: this.state.userLanguage
        }}>
        {this.props.children}
      </Provider>
    );
  }
}
