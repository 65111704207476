import HttpRequest from "./HttpRequest";
import Storage from "@aws-amplify/storage";
import config from "../config";

export default class HttpDataSource {
  static async getDocumentsData(group, id_publisher) {
    const response = await HttpRequest.fetchDocumentsChunk(group, id_publisher);

    return response;
  }

  static async getPublishersData(group, id_publisher) {
    const response = await HttpRequest.fetchPublishersChunk(
      group,
      id_publisher
    );

    return response;
  }

  static async getDocumentsDetailsData(id) {
    const response = await HttpRequest.fetchDocumentsDetailsChunk(id);

    return response;
  }

  static async getGroupesDocumentDetailsData(id) {
    const response = await HttpRequest.fetchGroupesDocumentsDetailsChunk(id);
    return response;
  }

  static async updateDocumentsDetailsData(data) {
    const response = await HttpRequest.updateDocumentsDetailsChunk(data);

    return response;
  }

  static async getDemandesData(group, showAll, id_publisher) {
    const response = await HttpRequest.fetchDemandesChunk(
      group,
      showAll,
      id_publisher
    );

    return response;
  }

  static async getDemandesDetailsData(statut, id) {
    const response = await HttpRequest.fetchDemandesDetailsChunk(statut, id);

    return response;
  }

  static async getDemandesDetailsDocumentsData(isbn) {
    const response = await HttpRequest.fetchDemandesDetailsDocumentsChunk(isbn);

    return response;
  }

  static async createDemandesDetailsData(data) {
    const response = await HttpRequest.createDemandesDetailsChunk(data);

    return response;
  }

  static async approuveOrRejectDemandesDetailsData(data) {
    const response = await HttpRequest.approuveOrRejectDemandesDetailsChunk(
      data
    );

    return response;
  }

  static async getDocumentsAchetesUtilisateursData(group, id_publisher) {
    const response = await HttpRequest.fetchDocumentsAchetesUtilisateursChunk(
      group,
      id_publisher
    );

    return response;
  }

  static async getDocumentsAchetesUtilisateursDetailsData(id) {
    const response = await HttpRequest.fetchDocumentsAchetesUtilisateursDetailsChunk(
      id
    );

    return response;
  }

  static async getCommandesAchatsData(group, id_publisher) {
    const response = await HttpRequest.fetchCommandesAchatsChunk(
      group,
      id_publisher
    );

    return response;
  }

  static async getCommandesAchatsDocumentsDetailsData(id) {
    const response = await HttpRequest.fetchCommandesAchatsDocumentsDetailsChunk(
      id
    );

    return response;
  }

  static async getCommandesAchatsOrderDetailsData(id) {
    const response = await HttpRequest.fetchCommandesAchatsOrderDetailsChunk(
      id
    );

    return response;
  }

  static async approveOrRefuseCommandesAchatsOrderDetailsData(data) {
    const response = await HttpRequest.approveOrRefuseCommandesAchatsOrderDetailsChunk(
      data
    );

    return response;
  }

  static async getHistoriquesRepresentantsData(group, id_publisher) {
    const response = await HttpRequest.fetchHistoriquesRepresentantsChunk(
      group,
      id_publisher
    );

    return response;
  }

  static async getHistoriquesRepresentantsOrderDetailsData(id) {
    const response = await HttpRequest.fetchHistoriquesRepresentantsOrderDetailsChunk(
      id
    );

    return response;
  }

  static async getHistoriquesRepresentantsDocumentsDetailsData(id) {
    const response = await HttpRequest.fetchHistoriquesRepresentantsDocumentsDetailsChunk(
      id
    );
    return response;
  }

  static async postPublication(data) {
    const response = await HttpRequest.postPublicationChunk(data);

    return response;
  }

  static async S3getSignedURL(filename) {
    try {
      Storage.configure({
        bucket: process.env.REACT_APP_MASTER_BUCKET,
        customPrefix: {
          public: ""
        }
      });
      const response = await Storage.get(filename, { level: "public" });
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static S3Upload(filename, file) {
    try {
      const response = Storage.put(filename, file, {
        contentType: file.type,
        contentDisposition: "attachment",
        level: "protected",
        bucket: config.s3.BUCKET
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static S3ImageUpload(filename, file) {
    Storage.configure({
      customPrefix: {
        public: "public/"
      }
    });

    try {
      const response = Storage.put(filename, file, {
        bucket: config.s3Image.BUCKET,
        contentType: file.type,
        level: "public"
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async S3DeleteImage(file) {
    try {
      const response = Storage.remove(file, {
        bucket: config.s3Image.BUCKET,
        level: "public"
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async S3Delete(file) {
    const response = await HttpRequest.S3DeleteChunk(file);

    return response;
  }

  static async S3Get(file) {
    const response = await HttpRequest.S3GetChunk(file);

    return response;
  }

  static async S3Copy(file, newFile, levelType, fileType) {
    try {
      Storage.configure({
        customPrefix: {
          public: "public/"
        }
      });
      const response = Storage.copy(
        { key: file, level: levelType },
        { key: newFile, level: levelType },
        {
          bucket:
            fileType === "image" ? config.s3Image.BUCKET : config.s3.BUCKET
        }
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async getOffresGroupesData(group, id_publisher) {
    const response = await HttpRequest.fetchOffresGroupesChunk(
      group,
      id_publisher
    );

    return response;
  }

  static async getDocumentDetailsOffresGroupesData(id) {
    const response = await HttpRequest.fetchDocumentDetailsOffresGroupesChunk(
      id
    );

    return response;
  }

  static async createDocumentDetailsOffresGroupesData(data) {
    const response = await HttpRequest.createDocumentDetailsOffresGroupesChunk(
      data
    );

    return response;
  }

  static async updateDocumentDetailsOffresGroupesData(data) {
    const response = await HttpRequest.updateDocumentDetailsOffresGroupesChunk(
      data
    );

    return response;
  }

  static async getComboOffresGroupesData(group, id_publisher) {
    const response = await HttpRequest.fetchComboOffresGroupesChunk(
      group,
      id_publisher
    );

    return response;
  }

  static async getDocumentsGroupesData(id) {
    const response = await HttpRequest.fetchDocumentsGroupesChunk(id);

    return response;
  }

  static async getRaisonsData(group, id_publisher) {
    const response = await HttpRequest.fetchRaisonsChunk(group, id_publisher);

    return response;
  }
}
