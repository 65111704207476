import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.css";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
