import React from "react";
import PropTypes from "prop-types";

const Button = React.forwardRef((props, ref) => {
  const { Tag, children, disabled, className, ...rest } = props;
  return (
    <Tag {...rest} className={className} disabled={disabled} ref={ref}>
      {children}
    </Tag>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  Tag: PropTypes.oneOf(["button", "a"]),
  disabled: PropTypes.bool,
  className: PropTypes.string
};

Button.defaultProps = {
  Tag: "button"
};

Button.displayName = "Button";

export default Button;
