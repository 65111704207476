import cogoToast from "cogo-toast";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DebouncedInput from "react-debounce-input";
import Table from "../components/Table";
import HttpDataSource from "../api/HttpDataSource";
import MessageBlock from "../components/MessageBlock";

class DocumentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      documents: [],
      originalDocuments: [],
      searchDocumentsTerm: "",
      showMore: false,
      errorMessage: ""
    };
  }

  async componentDidMount() {
    window && window.scroll(0, 0);

    let documents = [];
    let options = { position: "bottom-right" };
    const { t } = this.props;
    const userGroup =
      this.props.userGroup || window.localStorage.getItem("userGroup");
    const idPublisher =
      this.props.idPublisher || window.localStorage.getItem("idPublisher");

    try {
      const data = await HttpDataSource.getDocumentsData(
        userGroup,
        idPublisher
      );

      data.result &&
        data.result.map((x) => {
          if (x.type_document !== "offre groupée") {
            let price = parseFloat(x.prix_vente).toFixed(2);
            x.prix_vente = price.toString();

            let lang = x.langue;
            if (lang === "fr" || lang === "français") {
              x.langue = t("Français");
            } else if (lang === "en") {
              x.langue = t("English");
            } else if (lang === "es") {
              x.langue = t("Español");
            }
            return documents.push(x);
          }
        });

      let data_length = documents.length;
      this.setState({
        documents: this.props.boardTable
          ? documents.slice(0, 10)
          : documents.sort(function (a, b) {
              var titreA = a.titre_document.toUpperCase();
              var titreB = b.titre_document.toUpperCase();
              if (titreA < titreB) {
                return -1;
              }
              if (titreA > titreB) {
                return 1;
              }
              return 0;
            }),
        originalDocuments: this.props.boardTable
          ? documents.slice(0, 10)
          : documents.sort(function (a, b) {
              var titreA = a.titre_document.toUpperCase();
              var titreB = b.titre_document.toUpperCase();
              if (titreA < titreB) {
                return -1;
              }
              if (titreA > titreB) {
                return 1;
              }
              return 0;
            }),
        isLoading: false,
        showMore: data_length > 10,
        errorMessage: ""
      });
    } catch (error) {
      let message = "";
      if (error.message) {
        message = error.message + "!,please contact the site administrador";
      } else {
        message =
          error.response.data.error + "!,please contact the site administrador";
      }
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({
        errorMessage: message
      });
    }
  }

  UploadDocument = () => {
    this.props.history.push("/produits-deposer");
  };
  onChange = (e) => {
    let originalDocuments = this.state.originalDocuments;
    let searchDocumentsTerm = e.target.value;
    this.setState({
      searchDocumentsTerm
    });

    if (searchDocumentsTerm) {
      let filterDocuments = originalDocuments.filter(function (item) {
        return (
          (item.titre_document &&
            item.titre_document
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(
                searchDocumentsTerm
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )) ||
          (item.nom_org.nom &&
            item.nom_org.nom
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(
                searchDocumentsTerm
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )) ||
          (item.type_document &&
            item.type_document
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(
                searchDocumentsTerm
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )) ||
          (item.prix_vente && item.prix_vente.includes(searchDocumentsTerm)) ||
          (item.langue &&
            item.langue
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(
                searchDocumentsTerm
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )) ||
          (item.isbn_issn && item.isbn_issn.includes(searchDocumentsTerm))
        );
      });
      this.setState({
        documents: filterDocuments,
        searchDocumentsTerm
      });
    } else {
      this.setState({
        documents: originalDocuments,
        searchDocumentsTerm
      });
    }
    this.ref.focus();
  };

  render() {
    const { t } = this.props;

    this.columns = [
      {
        dataField: "lien_fichier_image",
        text: this.props.t("Image de couverture"),
        headerClasses: "w-40 whitespace-normal text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        classes:
          "px-2 py-2 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        formatter: (cell, row) => (
          <div style={{ textAlign: "-webkit-center" }}>
            <img
              src={
                !row.lien_fichier_image
                  ? row.type_document === "offre groupée"
                    ? "https://dona-cover-images.s3.amazonaws.com/public/cover-combo.png"
                    : "https://dona-cover-images.s3.amazonaws.com/public/cover-livre.png"
                  : row.lien_fichier_image
              }
              alt=""
              style={{
                height: "55px"
              }}
            />
          </div>
        )
      },

      {
        dataField: "titre_document",
        text: t("Titre du produit"),
        classes:
          "px-5 py-4 text-left whitespace-normal border-b border-gray-200 text-md text-donaPurple leading-5 underline font-bold",
        sort: this.props.boardTable ? false : true,
        headerClasses: "w-72 whitespace-no-wrap text-donaPurple font-bold",
        headerAttrs: {
          scope: "col",
          tabIndex: this.props.boardTable ? -1 : 0
        },
        attrs: { role: "rowheader", scope: "row" },
        formatter: (cell, row) => (
          <Link
            to={
              row.type_document === "offre groupée"
                ? `offres-groupes-details/${row.id_doc}`
                : `produits-details/${row.id_doc}`
            }>
            {row.titre_document}
          </Link>
        )
      },
      {
        dataField: "isbn_issn",
        text: t("Identifiant"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        sort: this.props.boardTable ? false : true,
        headerClasses: "w-48 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 }
      },
      {
        dataField: "nom_org.nom",
        text: t("Maison d'édition"),
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black whitespace-normal",
        headerClasses: "w-48 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "langue",
        text: t("Langue"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-32 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "type_document",
        text: t("Type"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",

        headerClasses: "w-32 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "prix_vente",
        text: t("Prix"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-32 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell) => `${cell ? cell + "$" : ""}`
      }
    ];

    const CaptionElements = (props) => (
      <>
        {this.props.boardTable ? (
          <div className="flex justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h2 className="text-white font-bold mt-2">
              {t("Liste des produits")}{" "}
            </h2>
          </div>
        ) : (
          <>
            <div className="lg:flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
              <div className="flex lg:my-auto my-2">
                <h1 className="text-white font-bold">
                  {t("Liste des produits")}
                </h1>
                <div className="text-white ml-1" aria-live="polite">
                  {this.state.isLoading === false &&
                    "(" +
                      this.state.documents.length +
                      " " +
                      (this.state.documents.length > 1
                        ? t("enregistrements")
                        : t("enregistrement")) +
                      ")"}
                </div>
              </div>
              <div className="lg:mx-auto lg:text-center text-left my-2">
                <Link
                  to={"/produits-deposer"}
                  className="w-48 donaButton button-color--invertTurquoisepale my-0"
                  style={{ marginTop: "0", marginBottom: "0" }}>
                  {t("Déposer un produit")}
                </Link>
              </div>
              <div className="relative lg:my-auto text-left">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute pointer-events-none  inset-y-0 ml-3 mt-3 flex items-center"
                />
                <DebouncedInput
                  inputRef={(ref) => {
                    this.ref = ref;
                  }}
                  minLength={1}
                  debounceTimeout={1200}
                  type="search"
                  onChange={this.onChange}
                  className="shadow  appearance-none border rounded  py-2 px-3 text-gray-700 pl-10 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={t("Recherche sur table")}
                  value={this.state.searchDocumentsTerm}
                  aria-label={t("Rechercher dans le tableau")}
                />
                {props.ExportCSV}
              </div>
            </div>
          </>
        )}
      </>
    );
    return (
      <>
        {!this.props.boardTable && (
          <Helmet>
            <title>{t("Produits Liste")}</title>
            <meta name="description" content={t("Produits Liste")} />
          </Helmet>
        )}

        {this.state.errorMessage ? (
          <MessageBlock
            alertMessage={this.state.errorMessage}
            className="border px-4 py-3 rounded relative mt-6 mb-2"
            roleType="alert"
          />
        ) : (
          <Table
            keyField="id_doc"
            withoutTabIndex={true}
            showMore={this.state.showMore}
            boardTable={this.props.boardTable}
            data={this.state.documents}
            caption={<CaptionElements />}
            columns={this.columns}
            loading={this.state.isLoading}
            classes="table-auto block"
            noDataMessage={t("Pas de produits disponible")}
            showMorePagePath="/produits"
            csvFileName={t("mes_produits.csv")}
            t={t}
          />
        )}
      </>
    );
  }
}

DocumentsList.propTypes = {
  boardTable: PropTypes.bool
};

DocumentsList.defaultProps = {
  boardTable: false
};

export default DocumentsList;
