import cogoToast from "cogo-toast";
import { Helmet } from "react-helmet";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { dateConverter } from "../libs/utility";
import Err403 from "../containers/Err403";
import HttpDataSource from "../api/HttpDataSource";
import Table from "../components/Table";

class HistoriquesRepresentantsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orderDocumentDetails: [],
      orderDetails: [],
      raison_refus: "",
      total: 0,
      taxes: 0,
      frais: 0,
      errorStatusCode: ""
    };
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let options = { position: "bottom-right" };
    try {
      const orderDetails = await HttpDataSource.getHistoriquesRepresentantsOrderDetailsData(
        this.props.match.params.id
      );
      const orderDocumentDetails = await HttpDataSource.getHistoriquesRepresentantsDocumentsDetailsData(
        this.props.match.params.id
      );

      this.setState({
        orderDetails: orderDetails.result,
        orderDocumentDetails: orderDocumentDetails.result,
        formData: {
          ...this.state.formData,
          id_demande: orderDetails.result[0].id_demande
        },
        isLoading: false,
        total: parseFloat(orderDetails.result[0].prix),
        taxes: parseFloat(orderDetails.result[0].taxes),
        frais: parseFloat(orderDetails.result[0].frais)
      });
    } catch (error) {
      if (error.response !== undefined) {
        let message = error.response.data.error;

        cogoToast.error(
          <div aria-live="polite">{this.props.t(message)}</div>,
          options
        );
        this.setState({ errorStatusCode: error.response.status });
      } else {
        this.props.history.push("/404");
        window.location.reload();
      }
    }
  }

  render() {
    const { t } = this.props;

    if (this.state.errorStatusCode === 403) {
      return <Err403 />;
    }

    function authorsFormatter(cell, row) {
      let auteurs = "";
      if (row.auteurs) {
        for (let i = 0; i < row.auteurs.length; i++) {
          if (row.auteurs[i].pseudonyme) {
            if (auteurs) {
              auteurs += "," + row.auteurs[i].pseudonyme;
            } else {
              auteurs += row.auteurs[i].pseudonyme;
            }
            continue;
          } else {
            if (auteurs) {
              auteurs += `${
                "," + row.auteurs[i].prenom + "," + row.auteurs[i].nom
              }`;
            } else {
              auteurs += `${row.auteurs[i].prenom + "," + row.auteurs[i].nom}`;
            }
            continue;
          }
        }
        return auteurs;
      }
    }

    this.columns = [
      {
        dataField: "titre_produit",
        text: t("Titre du produit"),
        classes:
          "px-5 py-4 text-center break-all border-b border-gray-200 text-md leading-5 text-black",
        headerClasses:
          "w-72 whitespace-no-wrap text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "identifiant",
        text: t("Identifiant"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        attrs: { scope: "row" }
      },
      {
        dataField: "prix",
        text: t("Prix ​​unitaire"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-32 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell) => `${parseFloat(cell).toFixed(2)}$`
      },
      {
        dataField: "quantite",
        text: t("Quantité"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-32 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "nombre_telechargements",
        text: t("Quantité") + " " + t("téléchargée"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "maison_edition",
        text: t("Maison d'édition"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-48 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell, row) => (
          <span>{`${JSON.parse(row.maison_edition).nom} `}</span>
        )
      },
      {
        dataField: "langue",
        text: t("Langue"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "type_produit",
        text: t("Type d'œuvre"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "niveau",
        text: t("Niveau"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-48 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "auteurs",
        text: t("Auteurs"),
        classes:
          "px-4 text-center border-b border-gray-200 text-md leading-5 text-black flex-wrap break-words",
        headerClasses: "w-48 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: authorsFormatter
      },

      {
        dataField: "date_parution",
        text: t("Année de parution"),
        classes:
          "px-4 text-center border-b border-gray-200 text-md leading-5 text-black flex-wrap",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell, row) => (
          <span>
            {row.date_parution
              ? dateConverter(row.date_parution).split("-")[0]
              : ""}
          </span>
        )
      }
      // {
      //   dataField: "lieu_parution",
      //   text: t("Lieu de parution"),
      //   classes:
      //     "px-4 text-center border-b border-gray-200 text-md leading-5 text-black flex-wrap",
      //   headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
      //   headerAttrs: { scope: "col", tabIndex: "-1" }
      // }
    ];
    const CaptionElements = () => (
      <div className="flex  justify-between flex-wrap-6 bg-donaBlue p-2 border border-donaBlue rounded">
        <h2 className="text-white font-bold mt-2">
          {t("Liste des produits dans le bon de commande")}
        </h2>
      </div>
    );

    const SkeletonLoader = (props) => {
      const { Tag, rows } = props;
      let skeletonLoaderItems = [];

      for (var i = 0; i < rows; i++) {
        skeletonLoaderItems.push(
          <tr key={i}>
            <td className="w-64 border-none font-bold">
              <Skeleton width={150} height={30} />
            </td>
            <td>
              <Skeleton width={260} height={30} />
            </td>
          </tr>
        );
      }

      return Tag !== "" ? (
        <Tag className="mb-3">
          <tbody>{skeletonLoaderItems}</tbody>
        </Tag>
      ) : (
        <tbody>{skeletonLoaderItems}</tbody>
      );
    };

    return (
      <>
        <Helmet>
          <title>{t("Détails du bon de commande")}</title>
          <meta name="description" content={t("Détails du bon de commande")} />
        </Helmet>
        <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
          <h1 className="text-white font-bold">
            {t("Détails du bon de commande")}
          </h1>
        </div>

        <div className="px-2 mb-5">
          {this.state.isLoading ? (
            <SkeletonLoader Tag="table" rows="10" />
          ) : (
            <ul className="xl:w-3/4 xl:mb-5 lg:w-3/5 lg:mb-5 w-full my-2 bg-white  mb-4">
              <li className="bg-white md:flex block">
                <strong className="inline-block w-64 border-none text-left">
                  {t("Numéro de la commande")}
                </strong>
                <p className="w-72">{this.state.orderDetails[0].id_commande}</p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-64 border-none text-left">
                  {t("Date de la commande")}
                </strong>
                <p className="w-72">
                  {dateConverter(this.state.orderDetails[0].date_et_heure)}
                </p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-64 border-none text-left">
                  {t("Nom du requérant")}
                </strong>
                <p className="w-72">
                  {`${this.state.orderDetails[0].prenom} 
                  ${this.state.orderDetails[0].nom}`}
                </p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-64  border-none text-left">
                  {t("Institution")}
                </strong>
                <p className="w-72">
                  {this.state.orderDetails[0].nom_institution &&
                    this.state.orderDetails[0].nom_institution.nom}
                </p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-64  border-none text-left">
                  {t("Centre de services scolaire")}
                </strong>
                <p className="w-72">
                  {this.state.orderDetails[0].nom_institution_mere
                    ? this.state.orderDetails[0].nom_institution_mere.nom
                    : ""}
                </p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-64  border-none text-left">
                  {t("Statut")}
                </strong>
                <p className="w-72">{this.state.orderDetails[0].statut}</p>
              </li>
              {this.state.orderDetails[0].statut === "refusée" && (
                <>
                  <li className="bg-white md:flex block">
                    <strong className="inline-block w-64  border-none text-left">
                      {t("Raison")}
                    </strong>
                    <p className="w-72">
                      {this.state.orderDetails[0].raison_refus}
                    </p>
                  </li>
                </>
              )}
            </ul>
          )}

          <h2 className="lg:w-3/5 w-full flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded-t text-white font-bold">
            {t("Transaction")}
          </h2>
          <table className="lg:w-3/5 w-full shadow-lg border-2 border-donaPurple xl:mb-10 lg:mb-10 mb-6">
            {this.state.isLoading ? (
              <SkeletonLoader Tag="" rows="10" />
            ) : (
              <>
                <tbody>
                  <tr>
                    <th className="w-64 border-none font-bold text-left">
                      {t("Total des produits")}
                    </th>
                    <td>
                      {(
                        this.state.total -
                        this.state.taxes -
                        this.state.frais
                      ).toFixed(2)}
                      $
                    </td>
                  </tr>
                  <tr className="border-b border-black">
                    <th className="w-64 border-none text-left">
                      {t("Frais de gestion")}
                    </th>
                    <td>
                      {this.state.frais.toFixed(2)}$
                      <span className="text-sm pl-3 font-bold">
                        {t("Frais de 6,00$ par oeuvre")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th className="w-64 border-none text-left">
                      {t("Total avant taxes")}
                    </th>
                    <td>{(this.state.total - this.state.taxes).toFixed(2)}$</td>
                  </tr>
                  <tr className="border-b border-black">
                    <th className="w-64 border-none text-left">{t("Taxes")}</th>
                    <td>{this.state.taxes.toFixed(2)}$</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="bg-donaGray bg-opacity-50">
                    <th className="w-64 border-none font-bold text-left">
                      {t("Total avec taxes")}
                    </th>
                    <td>{this.state.total.toFixed(2)}$</td>
                  </tr>
                </tfoot>
              </>
            )}
          </table>
          <Table
            keyField="identifiant"
            loading={this.state.isLoading}
            showMore={false}
            boardTable={false}
            data={this.state.orderDocumentDetails}
            caption={<CaptionElements />}
            columns={this.columns}
            classes="my-5"
            t={t}
          />
        </div>
      </>
    );
  }
}

export default HistoriquesRepresentantsDetails;
