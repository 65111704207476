import cogoToast from "cogo-toast";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Table from "../components/Table";
import HttpDataSource from "../api/HttpDataSource";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DebouncedInput from "react-debounce-input";
import Button from "../components/Button";
import { dateConverter } from "../libs/utility";
import { Helmet } from "react-helmet";

class CommandesAchatsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      commandesAchats: [],
      originalCommandesAchats: [],
      searchCommandesAchats: "",
      showMore: false,
      errorMessage: ""
    };
  }

  async FetchcommandesAchats() {
    let commandesAchats = [];
    const userGroup = window.localStorage.getItem("userGroup");

    const data = await HttpDataSource.getCommandesAchatsData(
      this.props.userGroup || userGroup,
      this.props.idPublisher
    );
    data.result &&
      data.result.map((x) => {
        return commandesAchats.push(x);
      });

    let data_length = commandesAchats.length;
    this.setState({
      commandesAchats: this.props.boardTable
        ? commandesAchats
            .sort(function (a, b) {
              return b.id_commande - a.id_commande;
            })
            .slice(0, 10)
        : commandesAchats,
      originalCommandesAchats: this.props.boardTable
        ? commandesAchats
            .sort(function (a, b) {
              return b.id_commande - a.id_commande;
            })
            .slice(0, 10)
        : commandesAchats,
      isLoading: false,
      showMore: data_length > 10
    });
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let options = { position: "bottom-right" };
    try {
      this.FetchcommandesAchats();
    } catch (error) {
      let message = "";
      if (error.message) {
        message = error.message + "!,please contact the site administrador";
      } else {
        message =
          error.response.data.error + "!,please contact the site administrador";
      }
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );

      this.setState({
        errorMessage: message
      });
    }
  }

  UploadDocument = () => {
    this.props.history.push("/produits-deposer");
  };

  onChange = (e) => {
    let originalCommandesAchats = this.state.originalCommandesAchats;
    let searchCommandesAchats = e.target.value;

    if (searchCommandesAchats) {
      let filterCommandesAchats = originalCommandesAchats.filter(function (
        item
      ) {
        if (Number(searchCommandesAchats)) {
          return (
            Number(item.total_docs) === Number(searchCommandesAchats) ||
            (item.cout_total &&
              Number(
                item.cout_total.includes(Number(searchCommandesAchats))
              )) ||
            item.id_commande === Number(searchCommandesAchats) ||
            (item.date_et_heure &&
              dateConverter(item.date_et_heure).includes(searchCommandesAchats))
          );
        } else {
          return (
            (item.institution.nom &&
              item.institution.nom
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchCommandesAchats
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                )) ||
            (item.representant &&
              item.representant
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchCommandesAchats
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                )) ||
            (item.date_et_heure &&
              dateConverter(item.date_et_heure).includes(searchCommandesAchats))
          );
        }
      });
      this.setState({
        commandesAchats: filterCommandesAchats,
        searchCommandesAchats
      });
    } else {
      this.setState({
        commandesAchats: originalCommandesAchats,
        searchCommandesAchats
      });
    }
    this.ref.focus();
  };

  async ApproveCommand(button, id_commande) {
    let options = {
      position: "bottom-right"
    };
    try {
      button.setAttribute("disabled", true);
      button.setAttribute("class", "donaButton button-color--invertMauve");
      button.style.pointerEvents = "none";
      var icon = document.createElement("i");
      icon.className = "fa fa-spinner fa-spin";
      icon.style = "font-size:20px";
      icon.setAttribute("disabled", true);
      button.innerHTML = this.props.t("Veuillez patienter");
      button.appendChild(icon);

      let formData = {
        values: { id_commande, statut: "approuvée", raison_refus: null },
        user: "admin@copibec"
      };
      let succesMessage = this.props.t(
        "Bon de commande approuvé. Votre décision a été transmise à la personne requérante."
      );

      const data = await HttpDataSource.approveOrRefuseCommandesAchatsOrderDetailsData(
        formData
      );
      if (data) {
        setTimeout(() => this.FetchcommandesAchats(), 2420);
        this.forceUpdate();
        cogoToast.success(
          <div aria-live="assertive">{this.props.t(succesMessage)}</div>,
          options
        );
      }
    } catch (error) {
      let message = "";
      if (error.message) {
        message = error.message + "!,please contact the site administrador";
      } else {
        message =
          error.response.data.error + "!,please contact the site administrador";
      }
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );

      this.setState({
        errorMessage: message
      });
    }
  }

  render() {
    const { t } = this.props;

    this.columns = [
      {
        dataField: "id_commande",
        text: t("No."),
        headerClasses:
          "w-20 px-4 py-2 whitespace-no-wrap text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        attrs: { role: "rowheader", scope: "row" },
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-donaPurple underline font-bold break-words",
        sort: this.props.boardTable ? false : true,
        formatter: (cell, row) => (
          <Link to={`commande-details/${row.id_commande}`}>
            {row.id_commande}
          </Link>
        )
      },
      {
        dataField: "date_et_heure",
        text: t("Date"),
        headerClasses:
          "w-40 px-4 py-2 whitespace-no-wrap text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black break-words",
        formatter: (cell, row) => (
          <span>{dateConverter(row.date_et_heure)}</span>
        ),
        sort: this.props.boardTable ? false : true
      },
      {
        dataField: "cout_total",
        text: t("Coût total avec taxes"), // data not showing ask humberto
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-48 px-4 py-2 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell) => `${cell ? cell + "$" : ""}`
      },

      {
        dataField: "total_docs",
        text: t("Quantité") + " " + t("totale doc") + "." + t("achetés"), // data not showing ask humberto
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-48 px-4 py-2 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "institution.nom",
        text: t("Institution"),
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 px-4 py-2  text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "representant",
        text: t("Requérant"),
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 px-4 py-2 text-donaPurple font-bold ",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "",
        text: t("Approbation"),
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-48 px-4 py-2 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell, row) => (
          <Button
            ref={(button) => {
              this.ApproveButton = button;
            }}
            name={"button"[row.id_commande]}
            className="donaButton button-color--turquoisepale"
            style={{ marginBottom: "1rem" }}
            onClick={(e) => this.ApproveCommand(e.target, row.id_commande)}>
            {t("Approuver")}
          </Button>
        )
      }
    ];

    const CaptionElement = () => (
      <>
        {this.props.boardTable ? (
          <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h2 className="text-white font-bold mt-2">
              {t("Bons de commande en attente d'approbation")}
            </h2>
            <div className="lds-dual-ring"></div>
          </div>
        ) : (
          <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h1 className="text-white font-bold mt-2">
              {t("Bons de commande en attente d'approbation")}
            </h1>

            <div
              className="text-white text-left self-center mr-auto pl-1"
              aria-live="polite">
              {this.state.isLoading === false &&
                "(" +
                  this.state.commandesAchats.length +
                  " " +
                  (this.state.commandesAchats.length > 1
                    ? t("enregistrements")
                    : t("enregistrement")) +
                  ")"}
            </div>
            <div className="relative">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute pointer-events-none  inset-y-0 ml-3 mt-3 flex items-center"
              />
              <DebouncedInput
                inputRef={(ref) => {
                  this.ref = ref;
                }}
                minLength={1}
                debounceTimeout={1200}
                type="search"
                onChange={this.onChange}
                className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 pl-10 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={t("Recherche sur table")}
                value={this.state.searchCommandesAchats}
                aria-label={t("Recherche sur table")}
              />
            </div>
          </div>
        )}
      </>
    );
    return (
      <>
        {!this.props.boardTable && (
          <Helmet>
            <title>{t("Bons de commande en attente d'approbation")}</title>
            <meta
              name="description"
              content={t("Bons de commande en attente d'approbation")}
            />
          </Helmet>
        )}
        <Table
          keyField="id_commande"
          showMore={this.state.showMore}
          boardTable={this.props.boardTable}
          data={this.state.commandesAchats}
          caption={<CaptionElement />}
          columns={this.columns}
          loading={this.state.isLoading}
          classes="table-auto"
          showMorePagePath="/bons-de-commande"
          noDataMessage={t("Aucune commande en attente d'approbation")}
          csvFileName={t("commandes_achats_en_attente_approbation.csv")}
          t={t}
        />
      </>
    );
  }
}

CommandesAchatsList.propTypes = {
  boardTable: PropTypes.bool
};

CommandesAchatsList.defaultProps = {
  boardTable: false
};

export default CommandesAchatsList;
