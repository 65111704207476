import cogoToast from "cogo-toast";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusCircle,
  faTrashAlt,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import AutoCompleteSelect from "../components/AutoCompleteSelect";
import Button from "../components/Button";
import { dateConverter } from "../libs/utility";
import DatePicker from "react-datepicker";
import DraggableCards from "../components/DraggableCards";
import MessageBlock from "../components/MessageBlock";
import HttpDataSource from "../api/HttpDataSource";
import { processImages } from "../libs/utility";
import "../styles/DocumentDetails.css";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/datepicker.css";
import Err403 from "../containers/Err403";
import { Helmet } from "react-helmet";

library.add(faPlusCircle, faTrashAlt, faInfoCircle);

export default class DocumentDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auteurs: [{ prenom: "", nom: "", pseudonyme: "" }],
      isLoading: true,
      isSaveLoading: false,
      isNewFile: false,
      id_produit: "",
      identifiant: "",
      identifiant_old: "",
      langue: null,
      titre: "",
      type_publication: "",
      maison_edition: { value: "", label: "" },
      id_maison_edition: "",
      prix: "",
      disponible: false,
      vendre_separe: false,
      date_parution: null,
      description: "",
      nom_fichier: "",
      nom_fichier_body: "",
      hyperlien_image: [],
      hyperlien_image_body: "",
      nom_fichier_old: "",
      niveau: "",
      errorMessage: "",
      errorStatusCode: "",
      documentsGroupeoffres: [],
      maisons_editions: [],
      deleted_images: [],
      editeur_public: "",
      invalidInput: ""
    };
    this.initialState = "";
    this.authorsInitialState = "";
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let documentDetailsData = [];
    let maison_edition = "";
    let signedURL = "";
    let options = { position: "bottom-right" };
    let format_maisons_editions = [];

    const userGroup =
      this.props.userGroup || window.localStorage.getItem("userGroup");
    const idPublisher =
      this.props.idPublisher || window.localStorage.getItem("idPublisher");

    try {
      const maisons_editions = await HttpDataSource.getPublishersData(
        userGroup,
        idPublisher
      );

      if (userGroup !== "superadmin") {
        maisons_editions.result.map((item) => {
          format_maisons_editions.push({
            id_organisation: item.id_organisation,
            nom: item.nom.nom
          });
        });
        this.setState({
          maisons_editions: format_maisons_editions
        });
      } else {
        this.setState({
          maisons_editions: maisons_editions.result
        });
      }
      const data = await HttpDataSource.getDocumentsDetailsData(
        this.props.match.params.id
      );

      documentDetailsData = data.result ? data.result[0] : {};

      if (documentDetailsData.nom_fichier) {
        signedURL = await HttpDataSource.S3getSignedURL(
          documentDetailsData.nom_fichier
        );
      }

      if (Object.keys(documentDetailsData).length > 0) {
        if (documentDetailsData.maison_edition.nom) {
          maison_edition = documentDetailsData.maison_edition.nom;
        }

        if (documentDetailsData.maison_edition.prenom) {
          maison_edition += documentDetailsData.maison_edition.prenom;
        }

        const groupData = await HttpDataSource.getGroupesDocumentDetailsData(
          this.props.match.params.id
        );

        documentDetailsData.auteurs !== null &&
          documentDetailsData.auteurs.map((element) => {
            if (!element.pseudonyme) {
              element.pseudonyme = "";
            }
          });

        this.setState({
          isLoading: false,
          hyperlien_image: documentDetailsData.hyperlien_image || [],
          nom_fichier: documentDetailsData.nom_fichier || "",
          signedURL: signedURL,
          old_nom_fichier: documentDetailsData.nom_fichier || "",
          nom_fichier_old: documentDetailsData.nom_fichier || "",
          niveau: documentDetailsData.niveau || "",
          id_produit: documentDetailsData.id_produit,
          auteurs:
            documentDetailsData.auteurs !== null
              ? documentDetailsData.auteurs
              : this.state.auteurs,
          identifiant: documentDetailsData.identifiant,
          identifiant_old: documentDetailsData.identifiant,
          langue: documentDetailsData.langue || "",
          type_publication:
            documentDetailsData.type_publication || "aide-mémoire",
          titre: documentDetailsData.titre || "",
          maison_edition: {
            value: documentDetailsData.id_maison_edition || "",
            label: maison_edition
          },
          id_maison_edition: documentDetailsData.id_maison_edition || "",
          prix: documentDetailsData.prix || "",
          disponible: documentDetailsData.disponible,
          vendre_separe: documentDetailsData.vendre_separe,
          date_parution: documentDetailsData.date_parution
            ? new Date(
                documentDetailsData.date_parution.substring(0, 10) +
                  "T12:00:00.000Z"
              )
            : null,
          description: documentDetailsData.description || "",
          documentsGroupeoffres: groupData,
          editeur_public: documentDetailsData.metadata
            ? documentDetailsData.metadata.editeur_public
              ? documentDetailsData.metadata.editeur_public
              : ""
            : ""
        });
        this.initialState = this.state;
        this.authorsInitialState = JSON.stringify(
          documentDetailsData.auteurs || [
            { prenom: "", nom: "", pseudonyme: "" }
          ]
        );
      } else {
        this.setState({
          isLoading: false
        });
      }
    } catch (error) {
      let message = error.response.data.error;
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({ errorStatusCode: error.response.status });
    }
  }

  handleChange = (event) => {
    /*to prevent from pasting characters on identifier field*/
    if (event.target.id === "identifiant") {
      let numbers = event.target.value.replace(/[^0-9]/g, "");
      this.setState({
        [event.target.id]: numbers
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
    event.target.setCustomValidity("");
  };

  handleChoice = (event, name) => {
    this.setState({
      [name]: event
    });
  };

  handleChangeAuteurs = (event) => {
    const cpauteurs = [...this.state.auteurs];
    const inputName = event.target.id.split("-")[0];
    const inputIndex = parseInt(event.target.id.split("-")[1]);
    const value = event.target.value;
    cpauteurs[inputIndex][inputName] = value;
    this.setState({ auteurs: cpauteurs });
  };

  setSelectValue = (selected) => {
    if (selected === "") {
      this.setState({
        id_maison_edition: null,
        maison_edition: { value: "", label: "" }
      });
    } else {
      this.setState({
        id_maison_edition: selected.value,
        maison_edition: { value: selected.value, label: selected.label }
      });
    }
  };

  addAuteurs = (e) => {
    e.preventDefault();
    const defaultValue = {
      prenom: "",
      nom: "",
      pseudonyme: ""
    };

    const newAuteurs = [...this.state.auteurs, defaultValue];
    this.setState({ auteurs: newAuteurs });
  };

  removeAuteurs = (e, idx) => {
    e.preventDefault();
    const cpauteurs = [...this.state.auteurs];
    cpauteurs.splice(idx, 1);
    this.setState({ auteurs: cpauteurs });
    document.getElementById("add").focus();
  };

  addDocuments = (e) => {
    if (e.target.files[0].name.split(".")[1] !== "pdf") {
      let options = { position: "bottom-right" };
      let message = "Veuillez ajouter un fichier pdf valide!";
      this.setState({ errorMessage: this.props.t(message), isLoading: false });
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
    } else {
      const nom_fichier = e.target.files[0].name;
      const nom_fichier_body = e.target.files[0];
      this.setState({ nom_fichier, nom_fichier_body });
    }
  };

  addLien_fichier_image = (e) => {
    let cover_images = [];
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      let images = {
        content: files[i],
        isNew: true
      };
      cover_images.push(images);
    }

    const hyperlien_image = [...this.state.hyperlien_image, ...cover_images];

    this.setState({
      hyperlien_image,
      hyperlien_image_body: ""
    });
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    let isValide = true;
    let errorMessage = "";
    let hyperlien_image = [];
    let document = {};
    let processedImages = [];
    let options = { position: "bottom-right" };
    let processDocument = this.state.nom_fichier;
    this.setState({ isSaveLoading: true });

    try {
      if (
        this.state.identifiant.length < 10 ||
        this.state.identifiant.length > 13
      ) {
        window && window.scroll(0, 0);
        isValide = false;
        errorMessage = this.props.t(
          "Svp veuillez rentrer un identifiant d'un minimum 10 et maximun 13 chiffres"
        );

        this.setState({
          isSaveLoading: false,
          errorMessage
        });
        cogoToast.error(<div aria-live="polite">{errorMessage}</div>, options);
        return false;
      }

      if (!this.state.nom_fichier_body && !this.state.nom_fichier) {
        isValide = false;
        errorMessage = this.props.t("Svp veuillez ajouter un fichier!");

        this.setState({
          isSaveLoading: false,
          errorMessage
        });
        cogoToast.error(<div aria-live="polite">{errorMessage}</div>, options);
        return false;
      }

      if (isValide) {
        if (this.state.hyperlien_image.length > 0) {
          //upload images to s3

          processedImages = await processImages(
            this.state.hyperlien_image,
            this.state.identifiant,
            this.state.identifiant_old
          );

          if (this.state.identifiant !== this.state.identifiant_old) {
            hyperlien_image = [...processedImages];
          } else {
            hyperlien_image = [
              ...this.state.hyperlien_image.filter((x) => !x.isNew),
              ...processedImages
            ];
          }
        }

        if (this.state.nom_fichier !== this.state.nom_fichier_old) {
          let extension = this.state.nom_fichier_body.type.split("/")[1];
          let filename = `${this.state.identifiant}.${extension}`;

          let uploadedDocument = await HttpDataSource.S3Upload(
            filename,
            this.state.nom_fichier_body
          );
          processDocument = uploadedDocument.key;
          this.setState({ isNewFile: true });
        } else {
          this.setState({ isNewFile: false });
        }
        /*check if identifiant has been changed*/
        // if (this.state.identifiant !== this.state.identifiant_old) {
        //   if (this.state.nom_fichier === this.state.nom_fichier_old) {
        //     let extension = this.state.nom_fichier.split(".")[1];
        //     let filename = `${this.state.identifiant}.${extension}`;
        //     let filename_old = `${this.state.identifiant_old}.${extension}`;
        //     let documentCopy = await HttpDataSource.S3Copy(
        //       filename_old,
        //       filename,
        //       "protected",
        //       "file"
        //     );
        //     this.setState({ isNewFile: true });
        //     processDocument = documentCopy.key;
        //   }
        // }

        let isAuteurs = this.state.auteurs[0].nom ? true : false;

        document = {
          values: {
            id_produit: this.state.id_produit,
            identifiant: this.state.identifiant,
            id_maison_edition: this.state.id_maison_edition,
            niveau: this.state.niveau,
            auteurs: isAuteurs ? JSON.stringify(this.state.auteurs) : null,
            type_publication: this.state.type_publication,
            titre: this.state.titre,
            date_parution: this.state.date_parution
              ? dateConverter(this.state.date_parution)
              : null,
            lieu_parution: null,
            maison_edition: this.state.maison_edition,
            langue: this.state.langue,
            disponible: this.state.disponible,
            prix: this.state.prix,
            vendre_separe: this.state.vendre_separe,
            hyperlien_image,
            nom_fichier: processDocument,
            isNewFile: this.state.isNewFile,
            metadata:
              `${JSON.stringify({
                editeur_public: this.state.editeur_public || null
              })}` || null,
            description: this.state.description
          },
          user: "admin@copibec"
        };

        let data = await HttpDataSource.updateDocumentsDetailsData(document);

        if (data) {
          // delete old document
          if (this.state.identifiant !== this.state.identifiant_old) {
            if (this.state.nom_fichier === this.state.nom_fichier_old) {
              await HttpDataSource.S3Delete(this.state.nom_fichier);
            }
          }

          cogoToast.success(
            <div aria-live="polite">
              {this.props.t("global_formsubmit_sauvegarde_produit")}
            </div>,
            options
          );
          this.setState({
            errorMessage: "",
            isSaveLoading: false,
            deleted_images: []
          });
          setTimeout(() => this.props.history.push("/produits"), 2300);
        }
      }
    } catch (error) {
      console.log(error);

      if (error.message) {
        this.setErrorMessage(error.message);
        return false;
      } else {
        let message = error.response.data.error;
        if (this.state.identifiant !== this.state.identifiant_old) {
          if (this.state.nom_fichier === this.state.nom_fichier_old) {
            let extension = this.state.nom_fichier.split(".")[1];
            let filename = `${this.state.identifiant}.${extension}`;
            let filename_old = `${this.state.identifiant_old}.${extension}`;
            let documentCopy = await HttpDataSource.S3Copy(
              filename,
              filename_old,
              "protected",
              "file"
            );
            processDocument = documentCopy.key;
            // deleting new document in case of error
            if (processDocument.key) {
              await HttpDataSource.S3Delete(filename);
            }
          }
        } else {
          // removing document from s3
          if (
            this.state.nom_fichier &&
            this.state.nom_fichier !== this.state.nom_fichier_old
          ) {
            await HttpDataSource.S3Delete(this.state.nom_fichier);
          }

          // removing upload images from S3
          processedImages.length > 0 &&
            processedImages.map(async (s3Image, index) => {
              await HttpDataSource.S3DeleteImage(
                s3Image.substring(s3Image.lastIndexOf("/") + 1)
              );
            });
        }

        this.setErrorMessage(message);
      }
    }
  };

  setErrorMessage = (message) => {
    let options = { position: "bottom-right" };
    this.setState({
      errorMessage: this.props.t(message),
      isSaveLoading: false
    });
    cogoToast.error(
      <div aria-live="polite">{this.props.t(message)}</div>,
      options
    );
  };

  onChangeContent = (items) => {
    this.setState({ hyperlien_image: items });
  };

  removeCard = (index) => {
    const cpitems = [...this.state.hyperlien_image];

    let deleted_image = cpitems[index];

    if (typeof deleted_image === "string") {
      this.setState({
        deleted_images: [...this.state.deleted_images, deleted_image]
      });
    }

    cpitems.splice(index, 1);
    this.setState({
      hyperlien_image: cpitems
    });
  };
  //to be decided  if we leave this
  clearChanges = (event) => {
    event.preventDefault();
    this.setState(this.initialState);
    let tempAuthors = JSON.parse(this.authorsInitialState);
    this.setState({ auteurs: tempAuthors });
    this.state.invalidInput && this.state.invalidInput.setCustomValidity("");
    this.props.history.push("/produits");
  };

  InvalidMsg(t, input) {
    var validityState_object = input.target.validity;
    if (validityState_object.valueMissing) {
      input.target.setCustomValidity(t("Veuillez remplir ce champ svp"));
      this.setState({ invalidInput: input.target });
    }
  }
  onChangeDate = (date) => {
    this.setState({
      date_parution: date
    });
  };

  render() {
    const { t } = this.props;
    let skeletonLoaderItems = [];
    let formatedNomFichier = [];

    const SkeletonInputsLoader = () => (
      <>
        <div className="flex flex-col">
          <div>
            <Skeleton width={160} height={30} />
          </div>
          <div>
            <Skeleton width={500} height={30} />
          </div>
        </div>
      </>
    );

    for (var i = 0; i < 5; i++) {
      skeletonLoaderItems.push(<SkeletonInputsLoader key={i} />);
    }

    const SkeletonLoader = () => (
      <>
        <div className="flex flex-col">
          <div>
            <Skeleton width={520} height={130} />
          </div>
        </div>
      </>
    );
    this.state.nom_fichier &&
      formatedNomFichier.push(
        <li key={0}>
          {
            <a href={this.state.signedURL} target="_blank" download>
              {this.state.nom_fichier.split("/").pop()}
            </a>
          }
        </li>
      );

    if (this.state.errorStatusCode === 403) {
      return <Err403 />;
    }

    return (
      <>
        <Helmet>
          <title>{t("Produit Détails")}</title>
          <meta name="description" content={t("Produit Détails")} />
        </Helmet>
        <div>
          <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h1 className="text-white font-bold">
              {t("Mon produit - détails du produit sélectionné")}
            </h1>
          </div>
          {this.state.documentsGroupeoffres.result && (
            <>
              <div className="xl:w-3/5 lg:w-4/5 w-11/12 xl:mx-auto lg:mx-auto mt-5">
                <div>
                  <table>
                    <caption className="flex-wrap-6 bg-donaBlue p-2 border border-donaBlue rounded text-white">
                      {t("Offres groupées associées")}
                    </caption>
                    <tbody>
                      <tr className="bg-white text-donaBlue">
                        <th
                          scope="col"
                          className="border-donaBlue border-bottom-2 px-4 py-1">
                          {t("No.")}
                        </th>
                        <th
                          scope="col"
                          className="border-donaBlue border-bottom-2 px-1 py-1">
                          {t("Titre")}
                        </th>
                        <th
                          scope="col"
                          className="border-donaBlue border-bottom-2 px-1 py-1">
                          {t("Identifiant de l'offre groupée")}
                        </th>
                      </tr>
                      {this.state.documentsGroupeoffres.result.map(
                        (item, index) => (
                          <tr
                            key={index}
                            className="bg-donaGray border-bottom border-white border-2">
                            <td className="w-10 p-2 text-center border-left border-white border-2">
                              {item.id_combo}
                            </td>
                            <td className="w-1/2 py-2 text-center">
                              {item.titre}
                            </td>
                            <td className="w-1/2 py-2 text-center">
                              {item.id_savia}{" "}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="xl:flex lg:flex block">
            <form
              className="xl:w-3/5 lg:w-4/5 w-11/12 pt-3 m-0 mb-5 mx-auto"
              onInvalid={(e) => this.InvalidMsg(t, e)}
              onSubmit={this.handleFormSubmit}>
              {this.state.isLoading ? (
                <>
                  {skeletonLoaderItems}
                  <SkeletonLoader />
                </>
              ) : (
                <>
                  {this.state.errorMessage && (
                    <MessageBlock
                      alertMessage={this.state.errorMessage}
                      className="border px-4 py-3 rounded relative mt-6 mb-2"
                      roleType="alert"
                    />
                  )}
                  <div className="text-left">
                    <strong className="text-sm text-donaPurple">
                      {t(
                        "Tous les champs marqués d'un astérisque (*) sont obligatoires."
                      )}
                    </strong>
                  </div>
                  <fieldset className="mt-3 border border-donaGray px-10 py-6 mb-5 shadow-lg">
                    <legend>{t("Information Produit")}</legend>
                    <label className="donaFormFieldLabel" htmlFor="identifiant">
                      {t("Identifiant")}*
                    </label>
                    <input
                      className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                      id="identifiant"
                      name="isbn"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={this.handleChange}
                      value={this.state.identifiant}
                      required
                      autoFocus
                    />
                    <small>
                      Format:1234567897 min:10 max:13 {t("chiffres")}
                    </small>
                    <label className="donaFormFieldLabel" htmlFor="titre">
                      {t("Titre du document")}*
                    </label>
                    <input
                      className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                      id="titre"
                      name="title"
                      type="text"
                      required
                      value={this.state.titre}
                      onChange={this.handleChange}
                    />
                    <label
                      className="donaFormFieldLabel"
                      htmlFor="type_publication">
                      {t("Type")}*
                    </label>
                    <select
                      className="w-full h-8 bg-donaGray donaButton"
                      id="type_publication"
                      name="type"
                      value={this.state.type_publication}
                      onChange={this.handleChange}
                      required>
                      <option value="aide-mémoire">{t("Aide-mémoire")}</option>
                      <option value="cahier">{t("Cahier")}</option>
                      <option value="carnet">{t("Carnet")}</option>
                      <option value="code grammatical">
                        {t("Code grammatical")}
                      </option>
                      <option value="essai">{t("Essai")}</option>
                      <option value="fascicule">{t("Fascicule")}</option>
                      <option value="guide de stage">
                        {t("Guide de stage")}
                      </option>
                      <option value="ligne du temps">
                        {t("Ligne du temps")}
                      </option>
                      <option value="manuel">{t("Manuel")}</option>
                      <option value="méthode de soins">
                        {t("Méthode de soins")}
                      </option>
                      <option value="recueil de solutions">
                        {t("Recueil de solutions")}
                      </option>
                      <option value="recueil de textes">
                        {t("Recueil de textes")}
                      </option>
                      <option value="roman">{t("Roman")}</option>
                    </select>
                    <label className="donaFormFieldLabel" htmlFor="niveau">
                      {t("Niveau")}*
                    </label>
                    <select
                      className="w-full h-8 bg-donaGray donaButton"
                      id="niveau"
                      name="level"
                      value={this.state.niveau}
                      onChange={this.handleChange}>
                      <option value="primaire/secondaire">
                        {t("Primaire/Secondaire")}
                      </option>
                      <option value="collégial/universitaire">
                        {t("Collégial/Universitaire")}
                      </option>
                    </select>
                    <label className="donaFormFieldLabel" htmlFor="langue">
                      {t("Langue")}
                    </label>

                    <select
                      className="w-full h-8 bg-donaGray donaButton"
                      id="langue"
                      name="language"
                      onChange={this.handleChange}
                      value={this.state.langue}>
                      <option value="fr">{t("Français")}</option>
                      <option value="en">{t("Anglais")}</option>
                      <option value="es">{t("Espagnol")}</option>
                    </select>

                    <label className="donaFormFieldLabel" htmlFor="description">
                      {t("Description")}
                    </label>
                    <textarea
                      rows="10"
                      cols="5"
                      className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                      id="description"
                      name="description"
                      type="text"
                      value={this.state.description}
                      onChange={this.handleChange}
                      style={{ height: "6rem" }}></textarea>
                  </fieldset>
                  <fieldset className="border border-donaGray px-10 py-6 pb-6 mb-5 shadow-lg">
                    <legend>{t("Auteur(s)")}</legend>
                    {this.state.auteurs.map((element, idx) => {
                      return (
                        <fieldset
                          key={idx}
                          className="bg-donaGray border-2 border-white p-2 mb-6">
                          <legend className="bg-white p-1">
                            {t("Auteur")} - {idx + 1}
                          </legend>
                          <div
                            className="flex flex-wrap -mx-3 mb-3 pl-3"
                            key={idx}>
                            <div className="md:w-3/12 w-full mr-3">
                              <label
                                className="donaFormFieldLabel"
                                htmlFor={`prenom-${idx}`}>
                                {t("Prénom")}
                              </label>
                              <input
                                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                                id={`prenom-${idx}`}
                                name="given-name"
                                type="text"
                                value={element.prenom}
                                onChange={this.handleChangeAuteurs}
                              />
                            </div>
                            <div className="md:w-3/12 w-full mr-3">
                              <label
                                className="donaFormFieldLabel"
                                htmlFor={`nom-${idx}`}>
                                {t("Nom")}
                              </label>
                              <input
                                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                                id={`nom-${idx}`}
                                name="family-name"
                                type="text"
                                value={element.nom}
                                onChange={this.handleChangeAuteurs}
                              />
                            </div>
                            <div className="md:w-3/12 w-full mr-3">
                              <label
                                className="donaFormFieldLabel whitespace-no-wrap"
                                htmlFor={`pseudonyme-${idx}`}>
                                {t("Pseudonyme")}
                              </label>
                              <input
                                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                                id={`pseudonyme-${idx}`}
                                name="pseudonyme"
                                type="text"
                                value={element.pseudonyme}
                                onChange={this.handleChangeAuteurs}
                              />
                            </div>

                            <div className="w-2/12 mt-6">
                              {idx === 0 && (
                                <>
                                  <button
                                    aria-label="add"
                                    id="add"
                                    onClick={this.addAuteurs}>
                                    <FontAwesomeIcon
                                      icon={faPlusCircle}
                                      size="2x"
                                      color="#348280"
                                      className="cursor-pointer"
                                    />
                                  </button>
                                  {this.state.auteurs.length > 1 && (
                                    <button
                                      aria-label="remove"
                                      id="remove"
                                      onClick={(e) =>
                                        this.removeAuteurs(e, idx)
                                      }>
                                      <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className="cursor-pointer"
                                        size="2x"
                                        color="#84277E"
                                      />
                                    </button>
                                  )}
                                </>
                              )}
                              {idx > 0 && (
                                <>
                                  <button alt="add" id="add">
                                    <FontAwesomeIcon
                                      icon={faPlusCircle}
                                      size="2x"
                                      color="#348280"
                                      className="cursor-pointer"
                                      onClick={this.addAuteurs}
                                    />
                                  </button>
                                  <button alt="remove" id="remove">
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      className="cursor-pointer"
                                      size="2x"
                                      color="#84277E"
                                      onClick={(e) =>
                                        this.removeAuteurs(e, idx)
                                      }
                                    />
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </fieldset>
                      );
                    })}
                  </fieldset>
                  <fieldset className="border border-donaGray px-10 py-6 mb-5 shadow-lg">
                    <legend>{t("Informations de l'éditeur")}</legend>
                    <label
                      className="donaFormFieldLabel"
                      htmlFor="react-select-3-input">
                      {t("Éditeur")}*
                    </label>
                    <AutoCompleteSelect
                      placeholder={t("Filtrer Éditeur")}
                      data={this.state.maisons_editions}
                      name="id_maison_edition"
                      fields={
                        this.props.userGroup === "superadmin"
                          ? { value: "id", label: "nom_maison_edition" }
                          : { value: "id_organisation", label: "nom" }
                      }
                      onChange={this.setSelectValue}
                      initValue={this.state.maison_edition}
                      required={true}
                    />
                    <label
                      className="donaFormFieldLabel mt-3"
                      htmlFor="editeur_public">
                      {t("Nom d'éditeur affiché dans le catalogue DONA")}
                    </label>
                    <input
                      className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                      id="editeur_public"
                      name="organization"
                      type="text"
                      value={this.state.editeur_public}
                      onChange={this.handleChange}
                    />
                    <label
                      className="donaFormFieldLabel"
                      htmlFor="date_parution">
                      {t("Année de publication")}
                    </label>
                    <DatePicker
                      className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                      selected={this.state.date_parution}
                      onChange={this.onChangeDate}
                      dateFormat="yyyy"
                      placeholderText={t("aaaa")}
                      showYearPicker
                      isClearable
                    />
                  </fieldset>
                  <fieldset className="border border-donaGray px-10 py-6 mb-5 shadow-lg">
                    <legend>{t("Préférences de vente")}</legend>
                    <label className="donaFormFieldLabel" htmlFor="disponible">
                      {t("Disponible sur Dona")}*
                    </label>
                    <div className="md:flex md:items-center mb-3">
                      <div className="md">
                        <Switch
                          onChange={(e) => this.handleChoice(e, "disponible")}
                          checked={this.state.disponible}
                          id="disponible"
                          className="react-switch"
                        />
                      </div>
                      <div className="md ml-2">
                        {this.state.disponible ? t("Oui") : t("Non")}.
                      </div>
                    </div>

                    <label
                      className="donaFormFieldLabel"
                      htmlFor="vendre_separe">
                      {t("Vente individuelle")}*
                    </label>
                    <div className="md:flex md:items-center mb-3">
                      <div className="md">
                        <Switch
                          onChange={(e) =>
                            this.handleChoice(e, "vendre_separe")
                          }
                          checked={this.state.vendre_separe}
                          className="react-switch"
                          id="vendre_separe"
                        />
                      </div>
                      <div className="md ml-2">
                        {this.state.vendre_separe ? t("Oui") : t("Non")}.
                      </div>
                    </div>

                    <label className="donaFormFieldLabel" htmlFor="prix">
                      {t("Prix de la version papier")}*
                    </label>
                    <input
                      className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                      id="prix"
                      name="price"
                      type="number"
                      placeholder="$"
                      step=".01"
                      value={this.state.prix}
                      onChange={this.handleChange}
                      required
                    />
                  </fieldset>
                  <fieldset
                    className="border border-donaGray px-10 pt-4 pb-6 mb-5 shadow-lg"
                    style={{ textAlign: "-webkit-center" }}>
                    <legend>
                      {t("Fichier numérique du produit")}
                      <button
                        type="button"
                        onClick={() => {
                          let isVisible = document.getElementById("fileSpec")
                            .style.display;
                          if (isVisible === "none") {
                            document.getElementById("fileSpec").style.display =
                              "block";
                          } else {
                            document.getElementById("fileSpec").style.display =
                              "none";
                          }
                        }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="1x"
                          color="#348280"
                          className="cursor-pointer mx-2"
                        />
                      </button>
                    </legend>
                    <p
                      id="fileSpec"
                      style={{
                        display: "none"
                      }}
                      className="bg-donaGray text-xs italic text-black p-4 rounded mb-4 text-left mx-auto">
                      Type de fichier: PDF seulement
                      <br />
                      Taille maximale du fichier: 250 Mo
                    </p>
                    {formatedNomFichier.length > 0 && (
                      <ul className="mb-4">{formatedNomFichier}</ul>
                    )}
                    <label className="rounded-full button-color--invertTurquoisepale text-center px-2 w-56 block text-md text-bold">
                      <input
                        type="file"
                        className="opacity-0 w-0"
                        onChange={this.addDocuments}
                        accept=".pdf"
                        tabIndex="0"
                      />
                      {formatedNomFichier.length > 0
                        ? t("Remplacer le fichier")
                        : t("Ajouter un fichier")}
                      *
                    </label>
                  </fieldset>

                  <fieldset
                    className="border border-donaGray px-10 pt-4 pb-6 mb-5 shadow-lg"
                    style={{ textAlign: "-webkit-center" }}>
                    <legend>
                      {t("Image(s) couverture(s)")}
                      <button
                        type="button"
                        onClick={() => {
                          let isVisible = document.getElementById("imageSpec")
                            .style.display;
                          if (isVisible === "none") {
                            document.getElementById("imageSpec").style.display =
                              "block";
                          } else {
                            document.getElementById("imageSpec").style.display =
                              "none";
                          }
                        }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="1x"
                          color="#348280"
                          className="cursor-pointer mx-2"
                        />
                      </button>
                    </legend>
                    <p
                      id="imageSpec"
                      style={{
                        display: "none"
                      }}
                      className="bg-donaGray text-xs italic text-black p-4 rounded text-left mx-auto">
                      Taille (recommandée): 2560px x 1600px (pour le mode
                      portrait)
                      <br />
                      Type de fichier: JPG, PNG
                      <br />
                      Résolution: 72 PPP
                      <br />
                      Taille maximale du fichier: 2 Mo
                    </p>

                    {this.state.hyperlien_image.length > 0 && (
                      <DraggableCards
                        items={this.state.hyperlien_image}
                        onChange={this.onChangeContent}
                        removeCard={this.removeCard}
                      />
                    )}
                    <div className="mt-6">
                      <label className="rounded-full button-color--invertTurquoisepale text-center px-2 w-56 mt-4 block text-md text-bold">
                        <input
                          value={this.state.hyperlien_image_body}
                          type="file"
                          multiple
                          className="opacity-0 w-0"
                          onChange={this.addLien_fichier_image}
                          accept=".png, .jpg, .jpeg, .gif"
                        />
                        {t("Ajouter une(des) image(s)")}
                      </label>
                    </div>
                  </fieldset>

                  <div className="mt-5 block text-center">
                    <Button
                      className="donaButton button-color--purple w-64"
                      style={{ margin: "20px" }}
                      type="submit"
                      disabled={this.state.isSaveLoading}>
                      {this.state.isSaveLoading ? (
                        <FontAwesomeIcon icon="spinner" spin />
                      ) : (
                        t("Sauvegarder")
                      )}
                    </Button>
                    <Button
                      className="donaButton button-color--invertMauve w-64"
                      onClick={this.clearChanges}>
                      {t("Annuler")}
                    </Button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </>
    );
  }
}
