import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";

export const Modal = (props) => {
  useEffect(() => {
    const allFocusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector(`#${props.name}`); // select the modal by it's id
    const firstFocusableButtonElement = modal.querySelectorAll(
      allFocusableElements
    )[0];

    const lastFocusableButtonElement = modal.querySelectorAll(
      allFocusableElements
    )[1];

    document.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableButtonElement) {
          lastFocusableButtonElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableButtonElement) {
          firstFocusableButtonElement.focus();
          e.preventDefault();
        }
      }
    });

    firstFocusableButtonElement.focus();
  }, [props.isModalOpen, props.name]);

  const classes = ["dona-modal"];

  const CloseModal = (event) => {
    event.preventDefault();
    props.setModalOpen(!props.isModalOpen);
    document.getElementById("logout").focus();
  };

  if (!props.isModalOpen) {
    classes.push("hidden");
  } else {
    classes.splice(0, 1);
  }

  return (
    <div className={classes.join(" ")} role="dialog" tabIndex="-1">
      <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div
          id={props.name}
          className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="document"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              {props.icon && (
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <FontAwesomeIcon icon={props.icon} color="darkviolet" />
                </div>
              )}
              <div className="text-center sm:mt-2 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline">
                  {props.headerText}
                </h3>
                <div className="mt-2">{props.children}</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex">
            <Button
              onClick={CloseModal}
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {props.cancelButtonText}
            </Button>
            <Button
              onClick={props.actionButtonFunction}
              className="inline-flex sm:ml-3  justify-center w-full rounded-md border border-transparent px-4 py-2 bg-donaPurple text-base leading-6 font-medium text-white shadow-sm hover:bg-purple-400 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {props.actionButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  actionButtonText: PropTypes.string,
  children: PropTypes.node,
  closeModal: PropTypes.func,
  actionButtonFunction: PropTypes.func,
  cancelButtonText: PropTypes.string,
  icon: PropTypes.any
};

export default Modal;
