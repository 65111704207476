import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import logocopibec from "../images/logodona.png";
import logogvcanada from "../images/logogvcanada.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EMAIL: ""
    };
  }

  handleChange = (event, data) => {
    this.setState({
      [data.id]: data.value
    });
  };

  render() {
    return (
      <footer className="bg-donaGray footer">
        <div className="w-full flex w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="w-1/2 block my-auto mx-5 py-3 lg:flex-grow">
            <img
              width={120}
              height={100}
              src={logocopibec}
              alt="Logo Copibec"
            />
          </div>
          <div className="mr-5 my-4">
            <a
              href="https://www.canada.ca"
              target="_blank"
              rel="noopener noreferrer">
              <img
                className="inline-block"
                width="250px"
                src={logogvcanada}
                alt="Funded by the government of Canada - Financé par le gouvernement du Canada"
              />
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
export default withTranslation()(Footer);
