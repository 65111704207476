const config = {
  s3: {
    REGION: "us-east-1",
    BUCKET: process.env.REACT_APP_BUCKET
  },
  s3Image: {
    REGION: "us-east-1",
    BUCKET: process.env.REACT_APP_S3_IMAGE_BUCKET
  },
  cognito: {
    APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    REGION: "us-east-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID
  },
  encryptUtilsApi: {
    DOCUMENTS_API_URL: process.env.REACT_APP_DOCUMENTS_API_URL,

    DOCUMENTS_COPIBEC_API_URL: process.env.REACT_APP_DOCUMENTS_COPIBEC_API_URL,

    DOCUMENTS_DETAILS_API_URL: process.env.REACT_APP_DOCUMENTS_DETAILS_API_URL,

    GROUPES_DOCUMENTS_DETAILS_API_URL:
      process.env.REACT_APP_GROUPES_DOCUMENTS_DETAILS_API_URL,

    UPDATE_DOCUMENTS_DETAILS_API_URL:
      process.env.REACT_APP_UPDATE_DOCUMENTS_DETAILS_API_URL,

    DEMANDES_API_URL: process.env.REACT_APP_DEMANDES_API_URL,

    DEMANDES_ALL_API_URL: process.env.REACT_APP_DEMANDES_ALL_API_URL,

    DEMANDES_COPIBEC_ALL_API_URL:
      process.env.REACT_APP_DEMANDES_COPIBEC_ALL_API_URL,

    DEMANDES_COPIBEC_API_URL: process.env.REACT_APP_DEMANDES_COPIBEC_API_URL,

    DEMANDE_DETAILS_API_URL: process.env.REACT_APP_DEMANDE_DETAILS_API_URL,

    DEMANDE_DETAILS_COPIBEC_API_URL:
      process.env.REACT_APP_DEMANDE_DETAILS_COPIBEC_API_URL,

    DEMANDE_DETAILS_APPROUVEE_API_URL:
      process.env.REACT_APP_DEMANDE_DETAILS_APPROUVEE_API_URL,

    DEMANDE_DETAILS_REFUSEE_API_URL:
      process.env.REACT_APP_DEMANDE_DETAILS_REFUSEE_API_URL,

    DEMANDES_DETAILS_DOCUMENTS_API_URL:
      process.env.REACT_APP_DEMANDES_DETAILS_DOCUMENTS_API_URL,

    CREATE_DEMANDE_DETAILS_API_URL:
      process.env.REACT_APP_CREATE_DEMANDE_DETAILS_API_URL,

    CREATE_DEMANDE_DETAILS_APPROUVE_REJETE_API_URL:
      process.env.REACT_APP_CREATE_DEMANDE_DETAILS_APPROUVE_REJETE_API_URL,

    DOCUMENTS_ACHETES_UTILISATEURS_API_URL:
      process.env.REACT_APP_DOCUMENTS_ACHETES_UTILISATEURS_API_URL,

    DOCUMENTS_COPIBEC_ACHETES_UTILISATEURS_API_URL:
      process.env.REACT_APP_DOCUMENTS_COPIBEC_ACHETES_UTILISATEURS_API_URL,

    DOCUMENTS_ACHETES_UTILISATEURS_DETAILS_API_URL:
      process.env.REACT_APP_DOCUMENTS_ACHETES_UTILISATEURS_DETAILS_API_URL,

    COMMANDES_ACHATS_API_URL: process.env.REACT_APP_COMMANDES_ACHATS_API_URL,

    COMMANDES_COPIBEC_ACHATS_API_URL:
      process.env.REACT_APP_COMMANDES_COPIBEC_ACHATS_API_URL,

    COMMANDES_ACHATS_DOCUMENTS_DETAILS_API_URL:
      process.env.REACT_APP_COMMANDES_ACHATS_DOCUMENTS_DETAILS_API_URL,

    COMMANDES_ACHATS_ORDER_DETAILS_API_URL:
      process.env.REACT_APP_COMMANDES_ACHATS_ORDER_DETAILS_API_URL,

    APPROVE_OR_REFUSE_COMMANDES_ACHATS_ORDER_DETAILS_API_URL:
      process.env
        .REACT_APP_APPROVE_OR_REFUSE_COMMANDES_ACHATS_ORDER_DETAILS_API_URL,

    HISTORIQUES_REPRESENTANTS_API_URL:
      process.env.REACT_APP_HISTORIQUES_REPRESENTANTS_API_URL,

    HISTORIQUES_COPIBEC_REPRESENTANTS_API_URL:
      process.env.REACT_APP_HISTORIQUES_COPIBEC_REPRESENTANTS_API_URL,

    HISTORIQUES_REPRESENTANTS_ORDER_DETAILS_API_URL:
      process.env.REACT_APP_HISTORIQUES_REPRESENTANTS_ORDER_DETAILS_API_URL,

    HISTORIQUES_REPRESENTANTS_DOCUMENTS_DETAILS_API_URL:
      process.env.REACT_APP_HISTORIQUES_REPRESENTANTS_DOCUMENTS_DETAILS_API_URL,

    CREATE_PUPLICATION_API_URL:
      process.env.REACT_APP_CREATE_PUPLICATION_API_URL,

    PUBLISHERS_API_URL: process.env.REACT_APP_PUBLISHERS_API_URL,

    PUBLISHERS_COPIBEC_API_URL:
      process.env.REACT_APP_PUBLISHERS_COPIBEC_API_URL,

    OFFRES_GROUPES_API_URL: process.env.REACT_APP_OFFRES_GROUPES_API_URL,

    OFFRES_GROUPES_COPIBEC_API_URL:
      process.env.REACT_APP_OFFRES_GROUPES_COPIBEC_API_URL,

    DOCUMENT_DETAILS_OFFRES_GROUPES_API_URL:
      process.env.REACT_APP_DOCUMENT_DETAILS_OFFRES_GROUPES_API_URL,

    CREATE_DOCUMENT_DETAILS_GROUPES_API_URL:
      process.env.REACT_APP_CREATE_DOCUMENT_DETAILS_GROUPES_API_URL,

    UPDATE_DOCUMENT_DETAILS_GROUPES_API_URL:
      process.env.REACT_APP_UPDATE_DOCUMENT_DETAILS_GROUPES_API_URL,

    COMBO_OFFRES_GROUPES_API_URL:
      process.env.REACT_APP_COMBO_OFFRES_GROUPES_API_URL,

    COMBO_COPIBEC_OFFRES_GROUPES_API_URL:
      process.env.REACT_APP_COMBO_COPIBEC_OFFRES_GROUPES_API_URL,

    DOCUMENTS_GROUPES_API_URL: process.env.REACT_APP_DOCUMENTS_GROUPES_API_URL,
    RAISONS_SUPER_ADMIN_API_URL:
      process.env.REACT_APP_RAISONS_SUPER_ADMIN_API_URL,
    RAISONS_ADMIN_API_URL: process.env.REACT_APP_RAISONS_ADMIN_API_URL,
    FAQ_SUPER_ADMIN_FR: process.env.REACT_APP_FAQ_SUPER_ADMIN_FR,
    FAQ_SUPER_ADMIN_EN: process.env.REACT_APP_FAQ_SUPER_ADMIN_EN,
    FAQ_ADMIN_FR: process.env.REACT_APP_FAQ_ADMIN_FR,
    FAQ_ADMIN_EN: process.env.REACT_APP_FAQ_ADMIN_EN,
    FAQ_APPROUVER_FR: process.env.REACT_APP_FAQ_APPROUVER_FR,
    FAQ_APPROUVER_EN: process.env.REACT_APP_FAQ_APPROUVER_EN
  }
};

export default {
  ...config
};
