import cogoToast from "cogo-toast";
import { Helmet } from "react-helmet";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import Button from "../components/Button";
import { dateConverter } from "../libs/utility";
import Err403 from "../containers/Err403";
import HttpDataSource from "../api/HttpDataSource";
import Table from "../components/Table";
import MessageBlock from "../components/MessageBlock";

class CommandesAchatsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showRejectionBox: false,
      orderDocumentDetails: [],
      orderDetails: [],
      raison_refus: "",
      total: 0,
      taxes: 0,
      frais: 0,
      errorStatusCode: "",
      errorMessage: ""
    };
  }

  async componentDidMount() {
    let options = { position: "bottom-right" };
    try {
      const orderDetails = await HttpDataSource.getCommandesAchatsOrderDetailsData(
        this.props.match.params.id
      );

      const orderDocumentDetails = await HttpDataSource.getCommandesAchatsDocumentsDetailsData(
        this.props.match.params.id
      );

      this.setState({
        orderDetails: orderDetails.result,
        orderDocumentDetails: orderDocumentDetails.result,
        formData: {
          ...this.state.formData,
          id_commande: orderDetails.result[0].id_commande
        },
        isLoading: false,
        total: parseFloat(orderDetails.result[0].prix),
        taxes: parseFloat(orderDetails.result[0].taxes),
        frais: parseFloat(orderDetails.result[0].frais)
      });
    } catch (error) {
      let message = error.response.data.error;
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({ errorStatusCode: error.response.status });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      formData: {
        ...this.state.formData,
        [event.target.id]: event.target.value
      }
    });
  };

  handleSubmit = async (event, statut, button) => {
    event.preventDefault();

    let succesMessage = "";

    let options = {
      position: "bottom-right"
    };

    try {
      let formData = this.state.formData;

      button.setAttribute("disabled", true);
      button.setAttribute("class", "donaButton button-color--invertMauve");
      button.style.pointerEvents = "none";
      var icon = document.createElement("i");
      icon.className = "fa fa-spinner fa-spin";
      icon.style = "font-size:20px";
      icon.setAttribute("disabled", true);
      button.innerHTML = this.props.t("Veuillez patienter");
      button.appendChild(icon);

      if (statut === "approuvée") {
        formData = {
          values: { ...formData, statut: "approuvée", raison_refus: null },
          user: "admin@copibec"
        };
        succesMessage = this.props.t(
          "Bon de commande approuvé. Votre décision a été transmise à la personne requérante."
        );
      } else {
        formData = {
          values: {
            ...formData,
            statut: "refusée",
            raison_refus: this.state.raison_refus
          },
          user: "admin@copibec"
        };
        succesMessage = this.props.t(
          "Bon de commande rejeté. Votre décision a été transmise à la personne requérante."
        );
      }

      const data = await HttpDataSource.approveOrRefuseCommandesAchatsOrderDetailsData(
        formData
      );
      if (data) {
        cogoToast.success(
          <div aria-live="polite">{this.props.t(succesMessage)}</div>,
          options
        );
        setTimeout(() => this.props.history.push("/bons-de-commande"), 2300);
      }
    } catch (error) {
      let message = "";

      if (error.response.status === 409) {
        message = "ISBN already exists, please enter a new one";
        this.identifiant.focus();
      } else {
        message = error.response.data.error;
      }

      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({ errorMessage: message });
    }
  };

  render() {
    if (this.state.errorStatusCode === 403) {
      return <Err403 />;
    }

    const { t } = this.props;

    this.columns = [
      {
        dataField: "titre_produit",
        text: t("Titre du produit"),
        sort: true,
        classes:
          "px-5 py-4 text-center break-all border-b border-gray-200 text-md leading-5 text-black",
        headerClasses:
          "w-64 whitespace-no-wrap text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "0" }
      },
      {
        dataField: "identifiant",
        text: t("Identifiant"),
        sort: true,
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "0" },
        attrs: { scope: "row" }
      },
      {
        dataField: "prix",
        text: t("Prix ​​unitaire"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell) => `${parseFloat(cell).toFixed(2)}$`
      },
      {
        dataField: "quantite",
        text: t("Quantité"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "maison_edition.nom",
        sort: true,
        text: t("Maison d'édition"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-48 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "0" },
        formatter: (cell, row) => (
          <span>{`${JSON.parse(row.maison_edition).nom} `}</span>
        )
      },
      {
        dataField: "langue",
        text: t("Langue"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "type_produit",
        text: t("Type d'œuvre"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",

        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "niveau",
        text: t("Niveau"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "auteurs",
        text: t("Auteurs"),
        classes:
          "px-4 text-center border-b border-gray-200 text-md leading-5 text-black flex-wrap",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell, row) => (
          <span>{`${
            row.auteurs
              ? (row.auteurs[1] && row.auteurs[1].pseudonyme) ||
                row.auteurs[0].nom + "," + row.auteurs[0].prenom
              : ""
          } `}</span>
        )
      },
      {
        dataField: "date_parution",
        text: t("Année de parution"),
        classes:
          "px-4 text-center border-b border-gray-200 text-md leading-5 text-black flex-wrap",
        headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell, row) => (
          <span>
            {row.date_et_heure
              ? dateConverter(row.date_et_heure).split("-")[0]
              : ""}
          </span>
        )
      }
      // {
      //   dataField: "lieu_parution",
      //   text: t("Lieu de parution"),
      //   classes:
      //     "px-4 text-center border-b border-gray-200 text-md leading-5 text-black flex-wrap",
      //   headerClasses: "w-40 p-4 text-donaBlue font-bold border-donaBlue",
      //   headerAttrs: { scope: "col", tabIndex: "-1" }
      // }
    ];
    const CaptionElements = () => (
      <div className="flex  justify-between flex-wrap-6 bg-donaBlue p-2 border border-donaBlue rounded">
        <h2 className="text-white font-bold">
          {t("Liste des produits dans le bon de commande")}
        </h2>
      </div>
    );

    const SkeletonLoader = (props) => {
      const { Tag, rows } = props;
      let skeletonLoaderItems = [];

      for (var i = 0; i < rows; i++) {
        skeletonLoaderItems.push(
          <tr key={i}>
            <td className="w-56 border-none font-bold">
              <Skeleton width={150} height={30} />
            </td>
            <td>
              <Skeleton width={260} height={30} />
            </td>
          </tr>
        );
      }

      return Tag !== "" ? (
        <Tag className="mb-3">
          <tbody>{skeletonLoaderItems}</tbody>
        </Tag>
      ) : (
        <tbody>{skeletonLoaderItems}</tbody>
      );
    };
    return (
      <>
        <Helmet>
          <title>{t("Détails du bon de commande")}</title>
          <meta name="description" content={t("Détails du bon de commande")} />
        </Helmet>

        <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-3">
          <h1 className="text-white font-bold">
            {t("Détails du bon de commande")}
          </h1>
        </div>

        {this.state.errorMessage.length > 0 && (
          <MessageBlock
            alertMessage={this.state.errorMessage}
            className="border px-4 py-3 rounded mt-6 mb-2"
            roleType="alert"
          />
        )}

        <div className="px-2">
          {this.state.isLoading ? (
            <SkeletonLoader Tag="table" rows="10" />
          ) : (
            <ul className="xl:w-1/2 xl:mb-5 lg:w-3/5 lg:mb-5 w-full my-2 bg-white  mb-4">
              <li className="bg-white md:flex block">
                <strong className="inline-block w-56 border-none text-left">
                  {t("Numéro de la commande")}
                </strong>
                <p>{this.state.orderDetails[0].id_commande}</p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-56 border-none text-left">
                  {t("Date de la commande")}
                </strong>
                <p>{dateConverter(this.state.orderDetails[0].date_et_heure)}</p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-56 border-none text-left">
                  {t("Nom du requérant")}
                </strong>
                <p>{`${this.state.orderDetails[0].prenom} 
                  ${this.state.orderDetails[0].nom}`}</p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-56  border-none text-left">
                  {t("Institution")}
                </strong>
                <p>{this.state.orderDetails[0].nom_institution.nom}</p>
              </li>
              <li className="bg-white md:flex block">
                <strong className="inline-block w-56  border-none text-left">
                  {t("Centre de services scolaire")}
                </strong>
                <p>
                  {this.state.orderDetails[0].nom_institution_mere
                    ? this.state.orderDetails[0].nom_institution_mere.nom
                    : ""}
                </p>
              </li>
            </ul>
          )}
          <h2 className="lg:w-3/5 w-full flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded-t text-white font-bold">
            {t("Transaction")}
          </h2>

          <table className="lg:w-3/5 w-full shadow-lg border-2 border-donaPurple xl:mb-10 lg:mb-10 mb-6">
            {this.state.isLoading ? (
              <SkeletonLoader Tag="" rows="10" />
            ) : (
              <>
                <tbody>
                  <tr>
                    <th className="w-56 border-none font-bold text-left">
                      {t("Total des produits")}:
                    </th>
                    <td>
                      {(
                        this.state.total -
                        this.state.taxes -
                        this.state.frais
                      ).toFixed(2)}
                      $
                    </td>
                  </tr>
                  <tr className="border-b border-black">
                    <th className="w-56 border-none text-left">
                      {t("Frais de gestion")}
                    </th>
                    <td>
                      {this.state.frais}$
                      <span className="text-sm pl-3 font-bold">
                        {t("Frais de 6,00$ par oeuvre")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th className="w-56 border-none text-left">
                      {t("Total avant taxes")}
                    </th>
                    <td>
                      {this.state.orderDetails[0] &&
                        (this.state.total - this.state.taxes).toFixed(2)}
                      $
                    </td>
                  </tr>
                  <tr className="border-b border-black">
                    <th className="w-56 border-none text-left">{t("Taxes")}</th>
                    <td>{this.state.taxes}$</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="bg-donaGray bg-opacity-50">
                    <th className="w-56 border-none font-bold text-left">
                      {t("Total avec taxes")}
                    </th>
                    <td>{this.state.total.toFixed(2)}$</td>
                  </tr>
                </tfoot>
              </>
            )}
          </table>

          <Table
            keyField="identifiant"
            loading={this.state.isLoading}
            showMore={false}
            boardTable={false}
            data={this.state.orderDocumentDetails}
            caption={<CaptionElements />}
            columns={this.columns}
            t={t}
            className="w-3/5"
          />

          {this.state.isLoading ? (
            <SkeletonLoader Tag="table" rows="10" />
          ) : (
            <div className="my-5 text-center">
              <Button
                className="donaButton button-color--purple "
                onClick={(e) => this.handleSubmit(e, "approuvée", e.target)}>
                {t("Approuver")}
              </Button>
              <Button
                className="donaButton button-color--purple ml-5"
                onClick={() =>
                  this.setState({
                    showRejectionBox: !this.state.showRejectionBox
                  })
                }>
                {t("Refuser")}
              </Button>
              <Button
                className="donaButton button-color--invertMauve ml-5"
                onClick={() => {
                  this.props.history.push("/bons-de-commande");
                }}>
                {t("Retourner")}
              </Button>
            </div>
          )}

          {this.state.showRejectionBox && (
            <fieldset className="w-full border border-donaGray px-10 py-6 mb-5 shadow-lg xl:mt-10 lg:mt-10 mt-6 mb-10">
              <legend className="text-left shadow-md p-1">
                {t(
                  "Inscrire l'explication à transmettre au (à la) requérant(e)"
                )}
              </legend>
              {this.state.isLoading ? (
                <SkeletonLoader Tag="table" rows="10" />
              ) : (
                <>
                  <div className=" border-donaGray block">
                    <label htmlFor="raison_refus">{t("Raison du refus")}</label>
                    <div>
                      <textarea
                        onChange={this.handleChange}
                        value={this.state.raison_refus}
                        rows="10"
                        cols="5"
                        className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                        id="raison_refus"
                        type="text"
                        style={{ width: "100%", height: "6rem" }}></textarea>
                    </div>

                    <Button
                      disabled={!this.state.raison_refus}
                      className={
                        this.state.raison_refus
                          ? "donaButton button-color--invertMauve m-0"
                          : "donaButton button-color--grey m-0 px-4"
                      }
                      style={{ marginTop: "1rem" }}
                      onClick={(e) =>
                        this.handleSubmit(e, "refusée", e.target)
                      }>
                      {t("Sauvegarder")}
                    </Button>
                    <Button
                      className="donaButton button-color--invertMauve ml-5"
                      onClick={() => {
                        this.setState({ showRejectionBox: false });
                      }}>
                      {t("Fermer")}
                    </Button>
                  </div>
                </>
              )}
            </fieldset>
          )}
        </div>
      </>
    );
  }
}

export default CommandesAchatsDetails;
