import cogoToast from "cogo-toast";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Table from "../components/Table";
import HttpDataSource from "../api/HttpDataSource";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DebouncedInput from "react-debounce-input";
import MessageBlock from "../components/MessageBlock";
import { dateConverter } from "../libs/utility";

class DemandesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      demandes: [],
      originalDocuments: [],
      searchDemandesTerm: "",
      showMore: false,
      errorMessage: ""
    };

    this.getUrlStatut = (statut) => {
      let route = "";
      switch (statut) {
        case "révision par Copibec":
          route = "revision";
          break;

        case "en attente":
          route = "attente";
          break;

        case "approuvée":
          route = "approuvee";
          break;

        default:
          route = "refuse";
      }
      return route;
    };
  }

  dynamicSort = (a, b) => {
    return b.value - a.value;
  };

  async componentDidMount() {
    window && window.scroll(0, 0);
    let data = "";
    let demandes = [];
    let options = { position: "bottom-right" };

    const userGroup =
      this.props.userGroup || window.localStorage.getItem("userGroup");

    const idPublisher =
      this.props.idPublisher || window.localStorage.getItem("idPublisher");

    try {
      data = await HttpDataSource.getDemandesData(
        userGroup,
        this.props.boardTable ? false : true,
        idPublisher
      );
      data.result &&
        data.result.map((x) => {
          return demandes.push(x);
        });

      let data_length = demandes.length;

      this.setState({
        demandes: this.props.boardTable ? demandes.slice(0, 10) : demandes,
        originalDocuments: this.props.boardTable
          ? demandes.slice(0, 10)
          : demandes,
        isLoading: false,
        showMore: data_length > 10
      });
    } catch (error) {
      let message = "";
      if (error.message) {
        message = error.message + "!,please contact the site administrador";
      } else {
        message =
          error.response.data.error + "!,please contact the site administrador";
      }
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({
        errorMessage: message
      });
    }
  }

  UploadDocument = () => {
    this.props.history.push("/produits-deposer");
  };

  onChange = (e) => {
    let originalDocuments = this.state.originalDocuments;
    let searchDemandesTerm = e.target.value;

    if (searchDemandesTerm) {
      let filterDocuments = originalDocuments.filter(function (item) {
        if (Number(searchDemandesTerm)) {
          return item.id_demande === Number(searchDemandesTerm);
        } else {
          return (
            (item.titre_produit &&
              item.titre_produit
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchDemandesTerm
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )) ||
            (item.identifiant &&
              item.identifiant
                .toLowerCase()
                .includes(searchDemandesTerm.toLowerCase())) ||
            (item.nom_org &&
              item.nom_org.nom
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchDemandesTerm
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                )) ||
            // (item.auteurs &&
            //   item.auteurs.nom
            //     .toLowerCase()
            //     .includes(searchDemandesTerm.toLowerCase())) ||
            (item.statut &&
              item.statut
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchDemandesTerm
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ))
          );
        }
      });
      this.setState({ demandes: filterDocuments, searchDemandesTerm });
    } else {
      this.setState({ demandes: originalDocuments, searchDemandesTerm });
    }
    this.ref.focus();
  };

  MyFunctionalComponent(props) {
    return <h1>I am functional</h1>;
  }

  render() {
    const { t } = this.props;

    this.columns = [
      {
        dataField: "id_demande",
        text: t("No."),
        headerClasses:
          "xl:auto w-20 text-left text-donaPurple px-5 py-4 font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        attrs: { role: "rowheader", scope: "row" },
        classes:
          "px-5 py-4 text-left whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-donaPurple underline font-bold",
        formatter: (cell, row) => (
          <Link
            to={{
              pathname: `demandes-details/${row.id_demande}/${this.getUrlStatut(
                row.statut
              )}`
            }}>
            {row.id_demande}
          </Link>
        )
      },
      {
        dataField: "titre_produit",
        text: t("Titre du produit"),
        classes:
          "px-5 py-4 text-left border-b border-gray-200 text-md leading-5 text-black",

        sort: this.props.boardTable ? false : true,
        headerClasses:
          "px-5 py-4  xl:auto w-84 text-left text-donaPurple font-bold break-words",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 }
      },
      {
        dataField: "identifiant",
        text: t("Identifiant"),
        classes:
          "px-5 py-4 text-left border-b border-gray-200 text-md leading-5 text-black",
        sort: this.props.boardTable ? false : true,
        headerClasses:
          "px-5 py-4  xl:auto w-36 text-left text-donaPurple font-bold break-words",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 }
      },
      {
        dataField: "nom_org.nom",
        text: t("Maison d'édition"),
        classes:
          "px-3 py-1 text-left border-b border-gray-200 text-md leading-5 text-black",
        headerClasses:
          "px-3 py-1 xl:auto w-48 text-left whitespace-no-wrap text-donaPurple font-bold break-words",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        sort: this.props.boardTable ? false : true
      },
      {
        dataField: "date_et_heure",
        text: t("Date"),
        headerClasses:
          "xl:auto px-3 py-1 text-left whitespace-no-wrap text-donaPurple font-bold",
        headerAttrs: { scope: "col" },
        classes:
          "px-3 py-1 text-left border-b border-gray-200 text-md leading-5 text-black break-words",
        formatter: (cell, row) => (
          <span>
            {row.date_et_heure ? dateConverter(row.date_et_heure) : ""}
          </span>
        ),
        sort: this.props.boardTable ? false : true
      },
      {
        dataField: "statut",
        text: t("Statut"),
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        classes:
          "px-3 py-1 text-left border-b border-gray-200 text-md leading-5 text-black",
        headerClasses:
          "px-3 py-1 xl:w-60 w-36 text-left text-donaPurple font-bold",
        sort: this.props.boardTable ? false : true,
        sortFunc: (a, b, order) => {
          let value_a, value_b;
          if (a === "révision par Copibec") {
            value_a = 4;
          } else if (a === "en attente") {
            value_a = 3;
          } else if (a === "approuvée") {
            value_a = 2;
          } else {
            value_a = 1;
          }

          if (b === "révision par Copibec") {
            value_b = 4;
          } else if (b === "en attente") {
            value_b = 3;
          } else if (b === "approuvée") {
            value_b = 2;
          } else {
            value_b = 1;
          }
          if (order === "asc") {
            return value_a - value_b;
          }
          return value_b - value_a; // desc
        }
      }
    ];

    const CaptionElements = () => (
      <>
        {this.props.boardTable ? (
          <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h2 className="text-white font-bold mt-2">
              {this.props.t("Demandes de nouveaux produits")}
            </h2>
          </div>
        ) : (
          <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h1 className="text-white font-bold mt-2">
              {this.props.t("Demandes de nouveaux produits")}
            </h1>
            <div
              className="text-white text-left self-center mr-auto pl-1"
              aria-live="polite">
              {this.state.isLoading === false &&
                "(" +
                  this.state.demandes.length +
                  " " +
                  (this.state.demandes.length > 1
                    ? t("enregistrements")
                    : t("enregistrement")) +
                  ")"}
            </div>
            <div className="relative">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute pointer-events-none  inset-y-0 ml-3 mt-3 flex items-center"
              />
              <DebouncedInput
                key={"debounceInput"}
                inputRef={(ref) => {
                  this.ref = ref;
                }}
                minLength={1}
                forceNotifyOnBlur={true}
                forceNotifyByEnter={true}
                debounceTimeout={1200}
                type="search"
                onChange={this.onChange}
                className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 pl-10 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={this.props.t("Recherche sur table")}
                value={this.state.searchDemandesTerm}
                aria-label={this.props.t("Rechercher dans le tableau")}
              />
            </div>
          </div>
        )}
      </>
    );

    return (
      <>
        {!this.props.boardTable && (
          <Helmet>
            <title>{t("Demandes de nouveaux produits")}</title>
            <meta
              name="description"
              content={t("Demandes de nouveaux produits")}
            />
          </Helmet>
        )}
        {this.state.errorMessage ? (
          <MessageBlock
            alertMessage={this.state.errorMessage}
            className="border px-4 py-3 rounded relative mt-6 mb-2"
            roleType="alert"
          />
        ) : (
          <>
            <Table
              keyField="id_demande"
              showMore={this.state.showMore}
              boardTable={this.props.boardTable}
              data={this.state.demandes}
              onChange={this.onChange}
              searchDocumentsTerm={this.state.searchDemandesTerm}
              caption={<CaptionElements />}
              columns={this.columns}
              loading={this.state.isLoading}
              classes="table-auto"
              noDataMessage={t("Pas de demandes disponible")}
              showMorePagePath="/demandes"
              csvFileName={t("demandes_de_produits.csv")}
              t={t}
            />
          </>
        )}
      </>
    );
  }
}

DemandesList.propTypes = {
  boardTable: PropTypes.bool
};

DemandesList.defaultProps = {
  boardTable: false
};

export default DemandesList;
