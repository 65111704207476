import Amplify from "@aws-amplify/core";
import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import HomeRedirect from "./containers/HomeRedirect";
import Login from "./containers/Login";
import BoardTableLayout from "./containers/BoardTableLayout";
import DocumentsList from "./containers/DocumentsList";
import DemandesList from "./containers/DemandesList";
import DemandesHistoriquesList from "./containers/DocumentsAchetesUtilisateursList";
import HistoriquesRepresentantsList from "./containers/HistoriquesRepresentantsList";
import CommandesAchatsList from "./containers/CommandesAchatsList";
import SignedOutHOC from "./containers/SignedOutHoc";
import SignedInHOC from "./containers/SignedInHoc";
import UploadDocument from "./containers/UploadDocument";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import TermsAndConditions from "./containers/TermsAndConditions";
import Settings from "./containers/Settings";
import DocumentDetails from "./containers/DocumentDetails";
import DemandDetails from "./containers/DemandesDetails";
import CommandesAchatsDetails from "./containers/CommandesAchatsDetails";
import DocumentsAchetesUtilisateursDetails from "./containers/DocumentsAchetesUtilisateursDetails";
import Help from "./containers/Help";
import Faq from "./containers/Faq";
import HistoriquesRepresentantsDetails from "./containers/HistoriquesRepresentantsDetails";
import OffresGroupes from "./containers/OffresGroupes";
import OffresGroupesDetails from "./containers/OffresGroupesDetails";
import Err404 from "./containers/Err404";
import { far } from "@fortawesome/free-regular-svg-icons";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import awsconfigs from "./aws-configs";

Amplify.configure(awsconfigs);
library.add(far, faSpinner, faCheck);

const Routes = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomeRedirect} />
        <Route
          path="/tableau-de-bord"
          component={SignedInHOC(BoardTableLayout)}
        />
        <Route exact path="/home" component={HomeRedirect} />
        <Route exact path="/connexion" component={SignedOutHOC(Login)} />
        <Route
          path="/produits-deposer"
          component={SignedInHOC(UploadDocument)}
        />
        <Route
          path="/connexion/forgotpassword"
          component={SignedOutHOC(ForgotPassword)}
        />
        <Route
          path="/connexion/resetpassword/"
          component={SignedOutHOC(ResetPassword)}
        />
        <Route path="/produits" component={SignedInHOC(DocumentsList)} />
        <Route
          path="/produits-details/:id/"
          component={SignedInHOC(DocumentDetails)}
        />

        <Route path="/demandes" component={SignedInHOC(DemandesList)} />
        <Route
          exact
          path="/demandes-details/:id/:statut"
          component={SignedInHOC(DemandDetails)}
        />
        <Route
          path="/historique-achats-produits"
          component={SignedInHOC(DemandesHistoriquesList)}
        />
        <Route
          path="/historique-achats-produits-details/:id"
          component={SignedInHOC(DocumentsAchetesUtilisateursDetails)}
        />
        <Route
          path="/bons-de-commande"
          component={SignedInHOC(CommandesAchatsList)}
        />
        <Route
          path="/commande-details/:id"
          component={SignedInHOC(CommandesAchatsDetails)}
        />
        <Route
          path="/historique-achats-representants"
          component={SignedInHOC(HistoriquesRepresentantsList)}
        />
        <Route
          path="/historique-achats-representants-details/:id"
          component={SignedInHOC(HistoriquesRepresentantsDetails)}
        />
        <Route path="/parametres" component={SignedInHOC(Settings)} />

        <Route path="/offres-groupes" component={SignedInHOC(OffresGroupes)} />

        <Route
          path="/offres-groupes-details/:id"
          component={SignedInHOC(OffresGroupesDetails)}
        />
        <Route
          path="/offres-groupes-details"
          component={SignedInHOC(OffresGroupesDetails)}
        />
        <Route
          path="/conditions-d-acces"
          component={SignedInHOC(TermsAndConditions)}
        />
        <Route path="/aide" component={SignedInHOC(Help)} />
        <Route path="/faq" component={SignedInHOC(Faq)} />
        <Route component={Err404} />
      </Switch>
    </Router>
  );
};

export default Routes;
