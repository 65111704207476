import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import AutoCompleteSelect from "../components/AutoCompleteSelect";
import Button from "../components/Button";
import { dateConverter } from "../libs/utility";
import Err403 from "../containers/Err403";
import HttpDataSource from "../api/HttpDataSource";
import MessageBlock from "../components/MessageBlock";

function PublisherSelectionDropDown(props) {
  //Conditional re-rendering, prevents code to rerender, on state change.
  if (!props.show) {
    return null;
  } else {
    return (
      <fieldset
        className="w-full border border-donaGray px-10 py-6 mb-5 "
        id="processRequestApprove">
        <legend style={{ color: "#707070" }}>
          {props.t("Assigner à l'éditeur")}
        </legend>
        <span>
          {props.langue === "fr"
            ? "Sélectionnez l'éditeur pour l'associer"
            : "Choose the publisher to assign the request"}
        </span>
        <div className="w-full mt-2">
          <AutoCompleteSelect
            placeholder={props.t("Filtrer Éditeur")}
            data={props.data}
            name="id_maison_edition"
            fields={{ value: "id", label: "nom_maison_edition" }}
            initValue={{ value: "", label: "" }}
            onChange={props.setSelectValue}
            required={true}
          />
        </div>
        <Button
          className={
            !props.id_maison_edition || props.isSaving
              ? "donaButton button-color--grey"
              : "donaButton button-color--invertTurquoisepale text-right"
          }
          style={{
            maxWidth: "none",
            marginTop: "1rem",
            marginBottom: "1rem"
          }}
          disabled={!props.id_maison_edition || props.isSaving ? true : false}
          onClick={props.submit}>
          {props.isSaving ? (
            <FontAwesomeIcon icon="spinner" spin />
          ) : (
            props.t("Sauvegarder")
          )}
        </Button>
        <Button
          className="donaButton button-color--invertMauve"
          style={{
            marginLeft: "20px"
          }}
          onClick={props.togglePublisherDropDownMenu}>
          {props.t("Fermer")}
        </Button>
      </fieldset>
    );
  }
}

/*
    DocumentSelectionDropDown Component
    -Precondition: All props functions not empty. List of documents is ready to execute.
    - Action One: Select Document from drop down. 
    - Action Two: Submit Form and Document on status approved.
    - Action Three: Close modal. 
    - Postcondition: 
        - On action two and document exist, handleConfirmSubmit. 
        - On action three, component is reset to original state. 
    */
function DocumentSelectionDropDown(props) {
  //Conditional re-rendering, prevents code to rerender, on state change.
  if (!props.show) {
    return null;
  } else {
    return (
      <fieldset
        className="w-full border border-donaGray px-10 py-6 mb-5 "
        id="processRequestApprove">
        <legend style={{ color: "#707070" }}>
          {props.t("Approbation de la demande de produit")}
        </legend>

        {props.statut === "révision" && (
          <>
            <span>{props.t("Sélectionnez l'éditeur pour l'associer")}</span>
            <div className="w-full mt-2">
              <AutoCompleteSelect
                placeholder={props.t("Filtrer Éditeur")}
                data={props.maisonsEditionsData}
                name="id_maison_edition"
                fields={{ value: "id", label: "nom_maison_edition" }}
                initValue={{ value: "", label: "" }}
                onChange={props.setSelectValue}
                required={true}
              />
            </div>
          </>
        )}
        <div
          className="w-full mt-2"
          style={{ color: props.isDisabled && "#707070" }}>
          <span>
            {props.t(
              "Sélectionnez le produit de la demande avant d'approuver la demande (doit avoir été déposé au préalable avec le même numéro d'identifiant)"
            )}
          </span>
          <AutoCompleteSelect
            isDisabled={props.isDisabled}
            placeholder={props.t("Filtrer produit")}
            data={props.documentsData}
            name="produit"
            onChange={props.setDocumentValue}
            fields={{ value: "id", label: "name" }}
            initValue={{ value: "", label: "" }}
            required={false}
          />
        </div>
        <Button
          className={
            props.statut === "révision"
              ? props.id_maison_edition === "" || props.id_produit === ""
                ? "donaButton button-color--grey m-0 px-4"
                : "donaButton button-color--invertTurquoisepale text-right"
              : props.id_produit === ""
              ? "donaButton button-color--grey m-0 px-4"
              : "donaButton button-color--invertTurquoisepale text-right"
          }
          style={{
            maxWidth: "none",
            marginTop: "1rem",
            marginBottom: "1rem"
          }}
          disabled={
            props.statut === "révision"
              ? props.id_maison_edition === "" ||
                props.id_produit === "" ||
                props.isSaving
                ? true
                : false
              : props.id_produit === "" || props.isSaving
              ? true
              : false
          }
          onClick={(e) => props.submit(e, "Approuvée")}>
          {props.isSaving ? (
            <FontAwesomeIcon icon="spinner" spin />
          ) : (
            props.t("Sauvegarder")
          )}
        </Button>
        <Button
          className="donaButton button-color--invertMauve"
          style={{
            marginLeft: "20px"
          }}
          onClick={props.toggleDocumentDropDownMenu}>
          {props.t("Fermer")}
        </Button>
      </fieldset>
    );
  }
}
/*
    Rejection Text Component
    -Precondition: All props functions not empty. If there exists state refuse reason, its displayed.
    - Action One: Submit Form on status reject. 
    - Action Two: Close modal.
    - Post condition: 
      - On action one and there exist a value in textarea, handleRejectSubmit. 
      - On action two, modal is closed and values are resetted. 
    */
function RejectionText(props) {
  //Conditional re-rendering, prevents code to rerender, on state change.
  if (!props.show) {
    return null;
  } else {
    return (
      <fieldset
        className="w-full border border-donaGray px-10 py-6 mb-5 "
        id="processRequestReject">
        <legend style={{ color: "#707070" }}>
          {props.t("Rejet de la demande de produit")}
        </legend>

        <div>
          {props.userGroup === "superadmin" && props.statut === "révision" && (
            <>
              <div className="w-full">
                <label htmlFor="id_maison_edition">
                  {props.t(
                    "Sélectionnez l'éditeur pour l'associer (optionnel)"
                  )}
                </label>
                <AutoCompleteSelect
                  placeholder={props.t("Filtrer Éditeur")}
                  data={props.maisonsEditionsData}
                  name="id_maison_edition"
                  fields={{ value: "id", label: "nom_maison_edition" }}
                  initValue={{ value: "", label: "" }}
                  onChange={props.setSelectValue}
                  required={false}
                />
              </div>
            </>
          )}

          <div className="raison-refus mt-2">
            <label htmlFor="id_maison_edition">
              {props.t("Sélectionnez la raison du refus")}
            </label>
            <AutoCompleteSelect
              placeholder={props.t("Filtrer la raison du refus")}
              data={props.raisons}
              name="id_raisons"
              fields={{
                value: "raison_id",
                label: props.langue === "fr" ? "titre_fr" : "titre_en"
              }}
              initValue={{ value: "", label: "" }}
              onChange={props.setRaisonRefus}
              required={true}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="raison_refus">
              {props.t("Message du courriel")}
            </label>
            <textarea
              value={props.raison_refus}
              onChange={props.handleChange}
              className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
              id="raison_refus"
              type="text"
              style={{
                width: "100%",
                height: "8rem"
              }}
            />
          </div>
          <Button
            onChange={props.handleChange}
            className={
              props.raison_refus
                ? "donaButton button-color--invertTurquoisepale text-right"
                : "donaButton button-color--grey m-0 px-4"
            }
            style={{
              maxWidth: "none"
            }}
            disabled={!props.raison_refus || props.isSaving ? true : false}
            onClick={(e) => props.submit(e, "rejetée")}>
            {props.isSaving ? (
              <FontAwesomeIcon icon="spinner" spin />
            ) : (
              props.t("Sauvegarder")
            )}
          </Button>
          <Button
            className="donaButton button-color--invertMauve"
            style={{
              marginLeft: "20px"
            }}
            onClick={props.toggleRejectionTextBox}>
            {props.t("Fermer")}
          </Button>
        </div>
      </fieldset>
    );
  }
}

function ModifyPublisherDropDown(props) {
  //Conditional re-rendering, prevents code to rerender, on state change.
  if (!props.show) {
    return null;
  } else {
    return (
      <fieldset
        className="w-full border border-donaGray px-10 py-6 mb-5 "
        id="processRequestApprove">
        <legend style={{ color: "#707070" }}>
          {props.t("Changer l'éditeur")}
        </legend>

        <>
          <span>
            {props.langue === "fr"
              ? "Sélectionnez l'éditeur pour l'associer"
              : "Choose the publisher to assign the request"}
          </span>
          <div className="w-full mt-2">
            <AutoCompleteSelect
              placeholder={props.t("Filtrer Éditeur")}
              data={props.maisonsEditionsData}
              name="id_maison_edition"
              fields={{ value: "id", label: "nom_maison_edition" }}
              initValue={{ value: "", label: props.nom_maison_edition || "" }}
              onChange={props.UpdatePublisherSelectValue}
              required={true}
            />
          </div>
        </>

        <Button
          className={
            props.id_maison_edition === null || props.isSaving
              ? "donaButton button-color--grey m-0 px-4"
              : "donaButton button-color--invertTurquoisepale text-right"
          }
          style={{
            maxWidth: "none",
            marginTop: "1rem",
            marginBottom: "1rem"
          }}
          disabled={
            props.id_maison_edition === null || props.isSaving ? true : false
          }
          onClick={(e) => props.submit(e)}>
          {props.isSaving ? (
            <FontAwesomeIcon icon="spinner" spin />
          ) : (
            props.t("Sauvegarder")
          )}
        </Button>
        <Button
          className="donaButton button-color--invertMauve"
          style={{
            marginLeft: "20px"
          }}
          onClick={props.toggleDocumentDropDownMenu}>
          {props.t("Fermer")}
        </Button>
      </fieldset>
    );
  }
}

class DemandesDetails extends Component {
  constructor(props) {
    super(props);
    let format_statut = "";
    if (this.props.match.params.statut === "revision") {
      format_statut = "révision";
    } else if (this.props.match.params.statut === "refuse") {
      format_statut = "Refusée";
    } else if (this.props.match.params.statut === "approuvee") {
      format_statut = "Approuvée";
    } else {
      format_statut = this.props.match.params.statut;
    }
    this.state = {
      isLoading: true,
      formData: {},
      demandeDetailsData: [],
      demandeDetailsDocumentsData: "",
      id_demande: "",
      id_produit: "",
      statut: format_statut,
      demandeur: "",
      courrielDemandeur: "",
      identifiant: "",
      titre_produit: "",
      niveau: "",
      nom_maison_edition: "",
      hyperlien_ouvrage: "",
      raison_demande: "",
      raison_refus: "",
      raison_titre: "",
      raison_id: "",
      showRejectionTextBox: false,
      showDocumentDropDown: false,
      showPublisherDropDown: false,
      showModifyPublisherDropDown: false,
      id_maison_edition: null,
      maisons_editions: [],
      resetValue: false,
      isSaving: false,
      id_org: "",
      titre_reel: "",
      isSauvegarder: false,
      errorStatusCode: "",
      raisons: [],
      langue: "",
      isDisabled: format_statut === "révision" ? true : false
    };
    this.toggleDocumentDropDownMenu = this.toggleDocumentDropDownMenu.bind(
      this
    );
    this.toggleRejectionTextBox = this.toggleRejectionTextBox.bind(this);
    this.togglePublisherDropDownMenu = this.togglePublisherDropDownMenu.bind(
      this
    );
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let demandeDetailsData = [];
    let maisons_editions = [];
    let raisons = [];
    let raison_refus = "";
    let raison_titre = "";
    let demandeur = "";
    let sortDocuments = [];

    /*Array to store List of Documents for Drop Down Menu */
    let documents = [{ id: "", name: "" }];
    const userGroup =
      this.props.userGroup || window.localStorage.getItem("userGroup");

    try {
      /*General Data of the Editeurs*/

      {
        userGroup === "superadmin" &&
          (this.state.statut === "révision" ||
            this.state.statut === "attente") &&
          (maisons_editions = await HttpDataSource.getPublishersData(
            userGroup,
            "0"
          ));
      }

      if (this.state.statut === "révision" || this.state.statut === "Refusée") {
        raisons = await HttpDataSource.getRaisonsData(userGroup, "0");
      } else if (this.state.statut === "attente") {
        if (userGroup === "superadmin" || userGroup === "admin") {
          raisons = await HttpDataSource.getRaisonsData("admin", "0");
        }
      }

      /*General Data of the Demande*/
      const data = await HttpDataSource.getDemandesDetailsData(
        this.props.match.params.statut,
        this.props.match.params.id
      );

      if (data.result === null) {
        this.props.history.push("/404");
        window.location.reload();
      } else {
        demandeDetailsData = data.result[0];
        let dataSelect = "";
        /*Data for array Documents */

        if (this.state.statut === "attente") {
          dataSelect = await HttpDataSource.getDocumentsData(
            userGroup,
            demandeDetailsData.id_org
          );

          /*Restructuring data to match component */
          dataSelect.result &&
            dataSelect.result.map((x) => {
              let tempField = {
                id: x.id_doc,
                name: x.titre_document + "  (ISBN: " + x.isbn_issn + ")"
              };
              return documents.push(tempField);
            });

          // sorting documents to asc order
          sortDocuments = documents.sort(function (a, b) {
            var titreA = a.name.toUpperCase();
            var titreB = b.name.toUpperCase();
            if (titreA < titreB) {
              return -1;
            }
            if (titreA > titreB) {
              return 1;
            }
            return 0;
          });
        }

        if (this.state.statut === "Refusée") {
          if (demandeDetailsData.id_raison) {
            raison_refus = raisons.result.find(
              (x) => x.raison_id === demandeDetailsData.id_raison
            );
          }

          if (this.props.i18n.language === "en") {
            raison_titre = raison_refus.titre_en;
          } else {
            raison_titre = raison_refus.titre_fr;
          }
        }

        if (demandeDetailsData.prenom) {
          demandeur = demandeDetailsData.prenom + " ";
        }

        if (demandeDetailsData.nom) {
          demandeur += demandeDetailsData.nom;
        }

        this.setState({
          isLoading: false,
          formData: {
            ...this.state.formData,
            id_demande: demandeDetailsData.id_demande,
            statut: this.state.statut
          },
          demandeDetailsData: data.result,
          demandeDetailsDocumentsData: sortDocuments,
          id_demande: demandeDetailsData.id_demande,
          demandeur,
          courriel: demandeDetailsData.courriel,
          identifiant: demandeDetailsData.identifiant,
          titre_produit: demandeDetailsData.titre_produit,
          niveau: demandeDetailsData.niveau || "",
          id_maison_edition: demandeDetailsData.id_org || null,
          nom_maison_edition: demandeDetailsData.nom_maison_edition
            ? demandeDetailsData.nom_maison_edition.nom
            : "",
          hyperlien_ouvrage: demandeDetailsData.hyperlien_ouvrage || "",
          raison_demande: demandeDetailsData.raison_demande,
          raison_refus: demandeDetailsData.raison_refus,
          raison_titre,
          id_org: demandeDetailsData.id_org || "",
          titre_reel: demandeDetailsData.titre_reel || "",
          maisons_editions: maisons_editions.result || [],
          raisons: raisons.result || [],
          telephone: demandeDetailsData.telephone || "",
          langue: demandeDetailsData.langue || ""
        });
        this.initialState = this.state;
      }
    } catch (error) {
      let message = error.response.data.error;
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        this.getOptions()
      );
      this.setState({ errorStatusCode: error.response.status });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.location.reload();
    }
  }

  async getDocuments(idPublisher) {
    /*Array to store List of Documents for Drop Down Menu */
    let documents = [];
    /*Data for array Documents */
    const dataSelect = await HttpDataSource.getDocumentsData(
      "noncopibecadmin",
      idPublisher
    );

    /*Restructuring data to match component */
    dataSelect.result &&
      dataSelect.result.map((x) => {
        let tempField = {
          id: x.id_doc,
          name: x.titre_document + "  (ISBN: " + x.isbn_issn + ")"
        };
        return documents.push(tempField);
      });

    let sortDocuments = documents.sort(function (a, b) {
      var titreA = a.name.toUpperCase();
      var titreB = b.name.toUpperCase();
      if (titreA < titreB) {
        return -1;
      }
      if (titreA > titreB) {
        return 1;
      }
      return 0;
    });

    return sortDocuments;
  }

  /*
    Return a string of sprecifications for cogoToast
  */
  getOptions = () => {
    return {
      position: "bottom-right"
    };
  };

  //Setter Methods

  /*
    Assigns/Changes a value in state formData
    Precondition: event must not be null. 
    Postcondition: an object value in formData is modified.
  */
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  /*
    Assigns Maison d'Edition's value.
    Precondition: value must not be null. 
    Postcondition: id_produit's value is re-assigned.
  */
  setSelectValue = async (selected) => {
    if (selected === "") {
      const userGroup =
        this.props.userGroup || window.localStorage.getItem("userGroup");
      if (this.state.statut === "révision" || this.state.statut === "attente") {
        let raisons = await HttpDataSource.getRaisonsData(userGroup, "0");
        this.setState({
          id_maison_edition: null,
          raisons: raisons.result
        });
      } else {
        this.setState({
          id_maison_edition: null
        });
      }
    } else {
      let id_publisher = selected.value;
      let documents = await this.getDocuments(id_publisher);

      if (this.state.id_maison_edition) {
        this.setState({
          id_maison_edition: selected.value,
          demandeDetailsDocumentsData: documents,
          isDisabled: false,
          nom_maison_edition: selected.label
        });
      } else {
        let raisons = await HttpDataSource.getRaisonsData("admin", "0");
        this.setState({
          id_maison_edition: selected.value,
          demandeDetailsDocumentsData: documents,
          raisons: raisons.result,
          isDisabled: false,
          nom_maison_edition: selected.label
        });
      }
    }
  };

  UpdatePublisherSelectValue = async (selected) => {
    if (selected === "") {
      this.setState({
        id_maison_edition: null
      });
    } else {
      this.setState({
        id_maison_edition: selected.value
      });
    }
  };
  /*
    Assigns document's value from the drop down list.
    Precondition: value must not be null. 
    Postcondition: id_produit's value is re-assigned.
  */
  setDocumentValue = (selected) => {
    this.setState({
      id_produit: selected.value ? selected.value : selected
    });
  };

  //Handling Methods

  toggleDocumentDropDownMenu = () => {
    this.setState((state) => ({
      showDocumentDropDown: !state.showDocumentDropDown,
      showModifyPublisherDropDown: false,
      showRejectionTextBox: false,
      showPublisherDropDown: false,
      id_maison_edition: null,
      raison_refus: "",
      id_produit: ""
    }));
  };

  setRaisonRefus = (selected_value) => {
    let raisons = this.state.raisons;
    let raison_refus = "";
    let raison_id = "";
    if (selected_value) {
      let raison_message = raisons.find(
        (item) => item.raison_id === selected_value.value
      );
      if (this.state.langue === "en") {
        raison_refus = raison_message.message_en
          ? raison_message.message_en
          : "";
        raison_id = raison_message.raison_id;
      } else {
        raison_refus = raison_message.message_fr
          ? raison_message.message_fr
          : "";
        raison_id = raison_message.raison_id;
      }
    }
    this.setState({
      raison_refus,
      raison_id
    });
  };

  toggleRejectionTextBox = () => {
    this.setState((state) => ({
      showRejectionTextBox: !state.showRejectionTextBox,
      showModifyPublisherDropDown: false,
      showDocumentDropDown: false,
      showPublisherDropDown: false,
      id_maison_edition: null,
      id_produit: "",
      raison_refus: ""
    }));
  };

  togglePublisherDropDownMenu = () => {
    this.setState((state) => ({
      showPublisherDropDown: !state.showPublisherDropDown,
      showModifyPublisherDropDown: false,
      showRejectionTextBox: false,
      showDocumentDropDown: false,
      id_maison_edition: null,
      raison_refus: ""
    }));
  };

  toggleModifyPublisherDropDownMenu = () => {
    this.setState((state) => ({
      showModifyPublisherDropDown: !state.showModifyPublisherDropDown,
      showRejectionTextBox: false,
      showPublisherDropDown: false,
      id_maison_edition: null,
      raison_refus: "",
      id_produit: ""
    }));
  };

  //Form Submittion Methods

  sauvegarderEtAssignerSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    try {
      let formData = this.state.formData;
      formData = {
        values: {
          ...formData,
          identifiant: this.state.identifiant,
          titre: this.state.titre_produit,
          raison_refus: null,
          id_raison: null,
          //auteurs: this.state.auteurs,
          id_maison_edition: this.state.id_maison_edition,
          hyperlien_ouvrage: this.state.hyperlien_ouvrage,
          raison_demande: this.state.raison_demande,
          niveau: this.state.niveau,
          statut: "en attente",
          langue: this.state.langue
        },
        user: "admin@copibec"
      };

      const data = await HttpDataSource.createDemandesDetailsData(formData);

      if (data) {
        setTimeout(
          () =>
            cogoToast.success(
              <div aria-live="polite">
                {this.props.t("Demande de produit sauvegarder")}
              </div>,
              this.getOptions()
            ),
          1200
        );
        setTimeout(() => this.props.history.push("/demandes"), 5200);
      }
    } catch (error) {
      let message = "";

      if (error.response.status === 409) {
        message = "ISBN already exists, please enter a new one";
        this.identifiant.focus();
      } else {
        message = error.response.data.error;
      }

      this.setState({ errorMessage: this.props.t(message) });

      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        this.getOptions()
      );
    }
  };

  /*
    Sends document and form, with approved or rejected status.
    Precondition: event must not be null. 
    Postcondition: On successful post request, message success, on failure, error message. 
  */
  ApprouveOrRejeteSubmit = async (event, statut) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    let message = "";
    try {
      let formData = this.state.formData;
      if (statut === "Approuvée") {
        formData = {
          values: {
            ...formData,
            identifiant: this.state.identifiant,
            id_produit: this.state.id_produit,
            id_maison_edition:
              this.state.statut === "attente"
                ? this.state.id_org
                : this.state.id_maison_edition,
            statut: "approuvée",
            raison_refus: null,
            langue: this.state.langue,
            id_raison: null
          },
          user: "admin@copibec"
        };
        message = "Demande de produit approuvé";
      } else {
        formData = {
          values: {
            ...formData,
            identifiant: this.state.identifiant,
            id_produit: null,
            id_maison_edition:
              this.state.statut === "attente"
                ? this.state.id_org
                : this.state.id_maison_edition,
            statut: "refusée",
            raison_refus: this.state.raison_refus,
            langue: this.state.langue,
            id_raison: this.state.raison_id
          },
          user: "admin@copibec"
        };
        message = "Demande de produit rejeté";
      }

      const data = await HttpDataSource.approuveOrRejectDemandesDetailsData(
        formData
      );

      if (data) {
        cogoToast.success(
          <div aria-live="polite">{this.props.t(message)}</div>,
          this.getOptions()
        );
        setTimeout(() => this.props.history.push("/demandes"), 4800);
      }
    } catch (message) {
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        this.getOptions()
      );
    }
  };
  // to be deciced if we remove this
  clearChanges = (event) => {
    event.preventDefault();
    this.setState(this.initialState);
    this.props.history.push("/demandes");
  };

  InvalidMsg(t, input) {
    var validityState_object = input.target.validity;
    if (validityState_object.valueMissing) {
      input.target.setCustomValidity(t("Veuillez remplir ce champ svp"));
    }
  }

  getNiveauLabels(code) {
    let label = "";

    switch (code) {
      case "SRP":
        label = "Primaire / Secondaire / CFP / CÉA";
        break;

      case "SRC":
        label = "Collégial";
        break;

      case "SRU":
        label = "Universitaire";
        break;

      default:
        label = "";
    }

    return label;
  }

  render() {
    if (this.state.errorStatusCode === 403) {
      return <Err403 />;
    }

    const { t } = this.props;

    //Loading Form Animation

    let skeletonLoaderItems = [];

    const SkeletonLoader = () => (
      <>
        <div className="flex mb-3">
          <div className="md:1/2 mr-3">
            <Skeleton width={520} height={30} />
          </div>
          <div className="md:1/2 ml-3">
            <Skeleton width={530} height={30} />
          </div>
        </div>
      </>
    );

    for (var i = 0; i < 9; i++) {
      skeletonLoaderItems.push(<SkeletonLoader key={i} />);
    }

    //Action Hangling

    /*
    Transfer Request Buttons
    - Action One: Save form new values.
    - Action Two: Return form values to original state.
    */
    const TransferRequestActions = () => (
      <div className="text-center">
        <Button
          className="donaButton button-color--purple"
          onClick={this.togglePublisherDropDownMenu}>
          {/* addd new apinurl */}
          {t("Assigner à l'éditeur")}
        </Button>
        <Button
          className="donaButton button-color--invertMauve "
          style={{
            marginLeft: "10px"
          }}
          onClick={this.clearChanges}>
          {t("Retourner")}
        </Button>
      </div>
    );

    /*
    Process Request Buttons
    - Action One: Approve the request and show drop down menu, to select document.
    - Action Two: Refuse the request and show textarea for refusal reason.
    */
    const ProcessRequestActions = (props) => (
      <div className="text-center">
        <Button
          className="donaButton button-color--purple"
          style={{
            marginRight: "10px"
          }}
          onClick={this.toggleDocumentDropDownMenu}>
          {t("Approuver")}
        </Button>
        <Button
          className="donaButton button-color--purple"
          style={{
            marginRight: "10px"
          }}
          onClick={this.toggleRejectionTextBox}>
          {t("Rejeter")}
        </Button>
        {props.statut === "attente" && props.userGroup === "superadmin" && (
          <Button
            className="donaButton button-color--purple"
            style={{
              marginRight: "10px"
            }}
            onClick={this.toggleModifyPublisherDropDownMenu}>
            {t("Changer L'éditeur")}
          </Button>
        )}
      </div>
    );

    /*
    ApprovedDocument Component
    - Retrieved the name of the document, from the approved form.
    */
    const ApprovedDocument = () => (
      <div className="flex p-2">
        <div className="w-1/5  h-7 sm:mr-2 xl:mr-0">
          <label htmlFor="produit" className="border-none font-bold break-word">
            {t("Produit sélectionné à l’approbation")}
          </label>
        </div>
        <div className="w-4/5" name="product">
          {this.state.titre_reel}
        </div>
      </div>
    );

    /*
    RejectResponse Component
    - Retrieved the motive, from the rejected form.
    */

    //const createMarkup = () => <>"__html": {this.state.raison_refus};</>;

    function createMarkup(raison_refus) {
      if (raison_refus) {
        return { __html: raison_refus };
      } else {
        return { __html: "" };
      }
    }

    const RejectedResponse = (props) => (
      <>
        <div className="flex p-2">
          <div className="w-1/5  h-7 sm:mr-2 xl:mr-0">
            <label
              htmlFor="raison_titre"
              className="border-none font-bold break-word">
              {t("Raison du refus de la demande")}
            </label>
          </div>
          <div className="w-4/5" id="raison_titre" name="raison_title">
            {this.state.raison_titre}
          </div>
        </div>
        <div className="bg-donaGray flex p-2">
          <div className="w-1/5  h-7 sm:mr-2 xl:mr-0">
            <label
              htmlFor="raison_refus"
              className="border-none font-bold break-word">
              {t("Message envoyé")}
            </label>
          </div>
          <div
            className="w-4/5"
            id="raison_refus"
            name="raison_refusal"
            dangerouslySetInnerHTML={createMarkup(props.raison_refus)}
          />
        </div>
      </>
    );

    return (
      <>
        <Helmet>
          <title>{t("Demandes Détails")}</title>
          <meta name="description" content={t("Demandes Détails")} />
        </Helmet>

        <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 flex-nowrap border border-donaPurple rounded">
          <h1 className="text-white font-bold">
            {t(
              "Demandes d'ajout de produit - Détails de la demande sélectionnée"
            )}
          </h1>
        </div>

        <form className="text-black" onInvalid={(e) => this.InvalidMsg(t, e)}>
          <>
            {this.state.isLoading ? (
              skeletonLoaderItems
            ) : (
              <>
                {this.state.errorMessage && (
                  <MessageBlock
                    alertMessage={this.state.errorMessage}
                    className="border px-4 py-3 rounded relative mt-6 mb-2"
                    roleType="alert"
                  />
                )}
                <fieldset>
                  <legend className="hidden">Non-editable Details</legend>
                  <div className="flex p-2 flex-nowrap">
                    <div
                      className="w-1/5  h-7 sm:mr-2 xl:mr-0"
                      id="numéro"
                      name="number">
                      <label
                        htmlFor="id_demande"
                        className="border-none font-bold">
                        {t("Numéro")}
                      </label>
                    </div>
                    <div className="w-4/5  h-7" id="demande" name="request">
                      {this.state.id_demande}
                    </div>
                  </div>

                  <div className="bg-donaGray flex p-2 flex-nowrap">
                    <div className="w-1/5  h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="statut"
                        className=" border-none font-bold">
                        {t("Statut")}
                      </label>
                    </div>
                    <div className="w-4/5  h-7">
                      {this.state.statut === "révision"
                        ? "Révision par Copibec"
                        : this.state.statut}
                    </div>
                  </div>

                  <div className="flex p-2 flex-nowrap">
                    <div className="w-1/5  h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="date"
                        className="md:w-56 w-32 border-none font-bold">
                        {t("Date")}
                      </label>
                    </div>
                    <div className="w-4/5 h-7">
                      {dateConverter(
                        this.state.demandeDetailsData[0].date_et_heure
                      )}
                    </div>
                  </div>

                  {this.props.userGroup !== "admin" && (
                    <>
                      <div className="bg-donaGray flex p-2 flex-nowrap">
                        <div className="w-1/5  h-7 sm:mr-2 xl:mr-0">
                          <label
                            htmlFor="demandeur"
                            className="md:w-56 border-none font-bold">
                            {t("Demandeur")}
                          </label>
                        </div>
                        <div
                          className="w-4/5 h-7"
                          id="demandeur"
                          name="full-name">
                          {this.state.demandeur}
                        </div>
                      </div>
                      <div className="flex p-2 flex-nowrap">
                        <div className="w-1/5  h-7 sm:mr-2 xl:mr-0">
                          <label
                            htmlFor="courrielDemandeur"
                            className="md:w-56 border-none font-bold ">
                            {t("Courriel du demandeur")}
                          </label>
                        </div>
                        <div
                          className="w-4/5 h-7"
                          id="courrielDemandeur"
                          name="email">
                          {this.state.courriel}
                        </div>
                      </div>
                      <div className="bg-donaGray flex p-2 flex-nowrap">
                        <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                          <label
                            htmlFor="telephone"
                            className="md:w-56 border-none font-bold">
                            {t("Téléphone du demandeur")}
                          </label>
                        </div>
                        <div className="w-4/5 h-7" id="telephone" name="phone">
                          {this.state.telephone}
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    className={
                      this.props.userGroup !== "admin"
                        ? "flex p-2 flex-nowrap"
                        : "bg-donaGray flex p-2 flex-nowrap"
                    }>
                    <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="langue"
                        className="md:w-56 border-none font-bold">
                        {t("Langue du demandeur")}
                      </label>
                    </div>
                    <div className="w-4/5 h-7" id="langue" name="language">
                      {this.state.langue === "fr"
                        ? t("Français")
                        : t("Anglais")}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.userGroup !== "admin"
                        ? "bg-donaGray flex p-2 flex-nowrap"
                        : "flex p-2 flex-nowrap"
                    }>
                    <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="identifiant"
                        className="md:w-56 border-none font-bold">
                        {t("Identifiant")}
                      </label>
                    </div>
                    {this.state.statut === "révision" ? (
                      <div className="w-4/5">
                        <input
                          id="identifiant"
                          name="isbn"
                          className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                          onChange={this.handleChange}
                          value={this.state.identifiant}
                          placeholder={t("identifiant")}
                          required
                          ref={(input) => {
                            this.identifiant = input;
                          }}
                        />
                      </div>
                    ) : (
                      <div className="w-4/5" id="identifiant" name="isbn">
                        {this.state.identifiant}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      this.props.userGroup !== "admin"
                        ? "flex p-2 flex-nowrap"
                        : "bg-donaGray flex p-2 flex-nowrap"
                    }>
                    <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="titre_produit"
                        className="md:w-56 border-none font-bold">
                        {t("Titre du produit")}
                      </label>
                    </div>
                    {this.state.statut === "révision" ? (
                      <div className="w-4/5">
                        <input
                          id="titre_produit"
                          name="title"
                          className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                          onChange={this.handleChange}
                          value={this.state.titre_produit}
                          placeholder={t("Titre")}
                          required
                        />
                      </div>
                    ) : (
                      <div id="titre_produit" name="title" className="w-4/5">
                        {this.state.titre_produit}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      this.props.userGroup !== "admin"
                        ? "bg-donaGray flex p-2 flex-nowrap"
                        : "flex p-2 flex-nowrap"
                    }>
                    <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="niveau"
                        className={
                          this.state.statut === "révision"
                            ? "md:w-56 border-none font-bold"
                            : "md:w-56 border-none font-bold"
                        }>
                        {t("Niveau d'enseignement")}
                      </label>
                    </div>
                    {this.state.statut === "révision" ? (
                      <div className="w-4/5">
                        <select
                          id="niveau"
                          name="level"
                          className={
                            !(this.state.statut === "révision")
                              ? "bg-donaGray w-4/5"
                              : "w-full h-8 shadow  leading-tight"
                          }
                          value={this.state.niveau}
                          disabled={
                            this.state.statut === "révision" ? false : true
                          }
                          onChange={this.handleChange}
                          required={
                            this.state.statut === "révision" ? false : true
                          }>
                          <option value="">{t("Faire un choix")}</option>
                          <option value="SRP">
                            {t("Primaire / Secondaire / CFP / CÉA")}
                          </option>
                          <option value="SRC">{t("Collégial")}</option>
                          <option value="SRU">{t("Universitaire")}</option>
                        </select>
                      </div>
                    ) : (
                      <div id="niveau" name="nivel" className="w-4/5">
                        {this.getNiveauLabels(this.state.niveau)}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      this.props.userGroup !== "admin"
                        ? "flex p-2 flex-nowrap"
                        : "bg-donaGray flex p-2 flex-nowrap"
                    }>
                    <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="nom_maison_edition"
                        className="md:w-56 border-none font-bold">
                        {t("Éditeur")}
                      </label>
                    </div>
                    <div
                      id="nom_maison_edition"
                      name="organization"
                      className="w-4/5">
                      {this.state.nom_maison_edition}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.userGroup !== "admin"
                        ? "bg-donaGray flex p-2 flex-nowrap"
                        : "flex p-2 flex-nowrap"
                    }>
                    <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="hyperlien_ouvrage"
                        className="md:w-56 border-none font-bold">
                        {t("Site web du produit")}
                      </label>
                    </div>
                    {this.state.statut === "révision" ? (
                      <div className="w-4/5">
                        <input
                          className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                          id="hyperlien_ouvrage"
                          name="link"
                          onChange={this.handleChange}
                          value={this.state.hyperlien_ouvrage}
                          placeholder={t("Hyperlien")}
                          required
                        />
                      </div>
                    ) : (
                      <div id="hyperlien_ouvrage" name="link" className="w-4/5">
                        {this.state.hyperlien_ouvrage}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      this.props.userGroup !== "admin"
                        ? "flex p-2 flex-nowrap"
                        : "bg-donaGray flex p-2 flex-nowrap"
                    }>
                    <div className="w-1/5 h-7 sm:mr-2 xl:mr-0">
                      <label
                        htmlFor="raison_demande"
                        className="md:w-56 border-none font-bold">
                        {t("Raison de la demande")}
                      </label>
                    </div>
                    {this.state.statut === "révision" ? (
                      <div className="w-4/5">
                        <textarea
                          className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline h-5"
                          id="raison_demande"
                          name="description"
                          style={{ height: "8rem" }}
                          onChange={this.handleChange}
                          value={this.state.raison_demande}
                          placeholder={t("Raison de la demande")}
                          rows="5"
                          required
                        />
                      </div>
                    ) : (
                      <div
                        id="raison_demande"
                        name="description"
                        className="w-4/5">
                        {this.state.raison_demande}
                      </div>
                    )}
                  </div>
                </fieldset>
              </>
            )}
          </>

          {/* Control flow rendering */}
          {this.state.isLoading ? (
            ""
          ) : (
            <>
              <hr key="hr" className="border border-donaGray mb-3 p-0 mt-4" />
              {this.state.statut === "révision" ? (
                <>
                  <div className="flex justify-center">
                    <ProcessRequestActions
                      statut={this.state.statut}
                      userGroup={this.props.userGroup}
                    />
                    <TransferRequestActions />
                  </div>
                  <DocumentSelectionDropDown
                    statut="révision"
                    maisonsEditionsData={this.state.maisons_editions}
                    isSaving={this.state.isSaving}
                    id_produit={this.state.id_produit}
                    id_maison_edition={this.state.id_maison_edition}
                    show={this.state.showDocumentDropDown}
                    setSelectValue={this.setSelectValue}
                    documentsData={this.state.demandeDetailsDocumentsData}
                    setDocumentValue={this.setDocumentValue}
                    submit={this.ApprouveOrRejeteSubmit} //to change to exact one
                    toggleDocumentDropDownMenu={this.toggleDocumentDropDownMenu}
                    t={this.props.t}
                    isDisabled={this.state.isDisabled}
                  />
                  <RejectionText
                    statut="révision"
                    userGroup={this.props.userGroup}
                    id_maison_edition={this.state.id_maison_edition}
                    maisonsEditionsData={this.state.maisons_editions}
                    isSaving={this.state.isSaving}
                    raisons={this.state.raisons}
                    setRaisonRefus={this.setRaisonRefus}
                    show={this.state.showRejectionTextBox}
                    setSelectValue={this.setSelectValue}
                    raison_refus={this.state.raison_refus}
                    handleChange={this.handleChange}
                    submit={this.ApprouveOrRejeteSubmit}
                    toggleRejectionTextBox={this.toggleRejectionTextBox}
                    t={this.props.t}
                    langue={this.props.i18n.language}
                    isDisabled={this.state.isDisabled}
                  />
                  <PublisherSelectionDropDown
                    isSaving={this.state.isSaving}
                    show={this.state.showPublisherDropDown}
                    data={this.state.maisons_editions}
                    setSelectValue={this.setSelectValue}
                    submit={this.sauvegarderEtAssignerSubmit} //to change to exact one
                    togglePublisherDropDownMenu={
                      this.togglePublisherDropDownMenu
                    }
                    id_maison_edition={this.state.id_maison_edition}
                    t={this.props.t}
                    langue={this.props.i18n.language}
                  />
                </>
              ) : this.state.statut === "attente" ? (
                <>
                  <div className="flex justify-center">
                    <ProcessRequestActions
                      statut={this.state.statut}
                      userGroup={this.props.userGroup}
                    />
                    <Button
                      className="donaButton button-color--invertMauve "
                      onClick={() => {
                        this.props.history.push("/demandes");
                      }}>
                      {t("Retourner")}
                    </Button>
                  </div>
                  <DocumentSelectionDropDown
                    statut="attente"
                    isSaving={this.state.isSaving}
                    id_produit={this.state.id_produit}
                    id_maison_edition={this.state.id_maison_edition}
                    setSelectValue={this.setSelectValue}
                    show={this.state.showDocumentDropDown}
                    documentsData={this.state.demandeDetailsDocumentsData}
                    setDocumentValue={this.setDocumentValue}
                    submit={this.ApprouveOrRejeteSubmit} //to change to exact one
                    toggleDocumentDropDownMenu={this.toggleDocumentDropDownMenu}
                    t={this.props.t}
                  />
                  <RejectionText
                    userGroup={this.props.userGroup}
                    statut="attente"
                    id_maison_edition={this.state.id_maison_edition}
                    maisonsEditionsData={this.state.maisons_editions}
                    isSaving={this.state.isSaving}
                    setSelectValue={this.setSelectValue}
                    show={this.state.showRejectionTextBox}
                    raisons={this.state.raisons}
                    setRaisonRefus={this.setRaisonRefus}
                    raison_refus={this.state.raison_refus}
                    handleChange={this.handleChange}
                    submit={this.ApprouveOrRejeteSubmit}
                    toggleRejectionTextBox={this.toggleRejectionTextBox}
                    t={this.props.t}
                    langue={this.props.i18n.language}
                  />

                  {this.props.userGroup !== "admin" && (
                    <ModifyPublisherDropDown
                      statut="attente"
                      nom_maison_edition={this.state.nom_maison_edition}
                      isSaving={this.state.isSaving}
                      id_maison_edition={this.state.id_maison_edition}
                      maisonsEditionsData={this.state.maisons_editions}
                      UpdatePublisherSelectValue={
                        this.UpdatePublisherSelectValue
                      }
                      show={this.state.showModifyPublisherDropDown}
                      submit={this.sauvegarderEtAssignerSubmit}
                      t={this.props.t}
                      langue={this.props.i18n.language}
                    />
                  )}
                </>
              ) : this.state.statut === "Approuvée" ? (
                <ApprovedDocument />
              ) : (
                <RejectedResponse raison_refus={this.state.raison_refus} />
              )}
            </>
          )}
        </form>
      </>
    );
  }
}

export default DemandesDetails;
