import React, { Component } from "react";
import { Redirect, withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { withAuthConsumer } from "../authcontext/AuthConsumer";
import LeftNav from "../components/LeftNav";
import Err403 from "../containers/Err403";
import "@reach/skip-nav/styles.css";

export default (WrappedComponent) => {
  class SignedInHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        screenWidth: window.innerWidth
      };
    }

    componentDidMount() {
      window.addEventListener("resize", () => {
        this.setState({ screenWidth: window.innerWidth });
      });
    }

    groupsAccessRoutes(group, pathname) {
      let canAccessRoutes = false;

      const groupsRoutes = {
        admin: [
          "tableau-de-bord",
          "produits",
          "produits-deposer",
          "produits-details",
          "offres-groupes",
          "offres-groupes-details",
          "demandes",
          "demandes-details",
          "historique-achats-produits",
          "historique-achats-produits-details",
          "conditions-d-acces",
          "parametres",
          "aide",
          "faq"
        ],
        approver: [
          "tableau-de-bord",
          "bons-de-commande",
          "commandes",
          "commande-details",
          "historique-achats-representants",
          "historique-achats-representants-details",
          "conditions-d-acces",
          "parametres",
          "aide",
          "faq"
        ]
      };

      switch (group) {
        case "superadmin":
          canAccessRoutes = true;
          break;

        case "admin":
          canAccessRoutes = groupsRoutes["admin"].includes(pathname);
          break;

        case "approver":
          canAccessRoutes = groupsRoutes["approver"].includes(pathname);
          break;

        default:
          canAccessRoutes = false;
      }

      return canAccessRoutes;
    }

    skipNav() {
      document.getElementById("skipMenu").focus();
    }

    render() {
      const isAuthenticated = window.localStorage.getItem("isAuthenticated");
      const userGroup = window.localStorage.getItem("userGroup");
      let path = this.props.location.pathname.split("/");
      const userCanAccessRoutes = this.groupsAccessRoutes(userGroup, path[1]);

      if (!isAuthenticated) {
        return <Redirect to="/connexion" />;
      }

      return (
        <>
          <button className="skipMain" onClick={this.skipNav}>
            {this.props.t("Passer au contenu")}
          </button>
          <main className="flex mainContent">
            {userCanAccessRoutes ? (
              <>
                {700 < this.state.screenWidth ? (
                  <>
                    <div className="p-2" style={{ width: "19rem" }}>
                      <LeftNav {...this.props} />
                    </div>
                    <div
                      className="w-4/5 xl:overflow-visible lg:overflow-visible overflow-hidden xl:p-8 xl:pb-0"
                      style={{ borderLeft: "1px solid #EAEBEC" }}>
                      <div id="skipMenu" tabIndex="-1"></div>
                      <WrappedComponent {...this.props} />
                    </div>
                  </>
                ) : (
                  <div className="p-4 w-full">
                    <div className="mb-2">
                      <LeftNav {...this.props} />
                    </div>
                    <div className="w-full">
                      <WrappedComponent {...this.props} />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Err403 />
            )}
          </main>
          <Footer />
        </>
      );
    }
  }

  return withRouter(withTranslation()(withAuthConsumer(SignedInHOC)));
};
