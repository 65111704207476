import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import config from "../config";

export const Faq = (props) => {
  const { t } = props;
  const [faqUrl, setFaqUrl] = useState();

  const getGroupFaqUrlLangue = (group, language) => {
    let faqUrl = "";

    if (language && language === "fr") {
      if (group === "superadmin") {
        faqUrl = config.encryptUtilsApi.FAQ_SUPER_ADMIN_FR;
      } else if (group === "admin") {
        faqUrl = config.encryptUtilsApi.FAQ_ADMIN_FR;
      } else {
        faqUrl = config.encryptUtilsApi.FAQ_APPROUVER_FR;
      }
    }
    if (language && language === "en") {
      if (group === "superadmin") {
        faqUrl = config.encryptUtilsApi.FAQ_SUPER_ADMIN_EN;
      } else if (group === "admin") {
        faqUrl = config.encryptUtilsApi.FAQ_ADMIN_EN;
      } else {
        faqUrl = config.encryptUtilsApi.FAQ_APPROUVER_EN;
      }
    }

    setFaqUrl(faqUrl);
  };

  useEffect(() => {
    const userGroup =
      props.userGroup || window.localStorage.getItem("userGroup");
    getGroupFaqUrlLangue(userGroup, props.i18n.language);
  }, [props.i18n.language, props.userGroup]);

  return (
    <>
      <Helmet>
        <title>{t("Aide")}</title>
        <meta name="aide" content={t("Faq")} />
      </Helmet>
      <div>
        <div className="flex justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded mb-4">
          <h1 className="text-white font-bold mt-1">{t("Faq")}</h1>
        </div>
        <div className="lg:w-1/2 md:w-11/12 w-full border border-donaGray p-4 my-3 lg:mx-1 mx-auto shadow-lg">
          <p className="italic text-black underline text-left">
            <a href={faqUrl}>Faq</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Faq;
