import React, { Component } from "react";
import Auth from "@aws-amplify/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageBlock from "../components/MessageBlock";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCheck);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmation: "",
      type: "password",
      action: "Show",
      updated: this.props.history.location.state === undefined ? false : true,
      newpassword: "",
      errorMessage: "",
      openModal: false,
      showPassword: false,
      auth: this.props.history.location.state || null
    };
  }

  async componentDidMount() {
    const passwordField = document.getElementById("newpassword");
    if (passwordField) {
      passwordField.addEventListener("keydown", function (event) {
        let caps = event.getModifierState && event.getModifierState("CapsLock");
        let capsLock = document.getElementById("capsLock");
        if (capsLock) {
          capsLock.style.display = caps ? "block" : "none";
        }
      });
      passwordField.addEventListener("keyup", function (event) {
        let caps = event.getModifierState && event.getModifierState("CapsLock");
        let capsLock = document.getElementById("capsLock");
        if (capsLock) {
          capsLock.style.display = caps ? "block" : "none";
        }
      });
    }
  }

  validateConfirmationForm() {
    return (
      this.state.newpassword.match(/[A-Z]/) &&
      this.state.newpassword.match(/[a-z]/) &&
      this.state.newpassword.length > 7 &&
      /\d/.test(this.state.newpassword) &&
      this.checkSpecialChar(this.state.newpassword)
    );
  }

  validatePassword() {
    return (
      this.state.newpassword.match(/[A-Z]/) &&
      this.state.newpassword.match(/[a-z]/) &&
      this.state.newpassword.length > 7 &&
      /\d/.test(this.state.newpassword) &&
      this.checkSpecialChar(this.state.newpassword)
    );
  }

  checkSpecialChar(password) {
    var format = /[^A-Z\s\d]/gi;
    if (format.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleShowPassword = (event) => {
    event.preventDefault();

    this.setState({
      type: this.state.type === "input" ? "password" : "input",
      action: this.state.action === "Show" ? "Hide" : "Show"
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let email = this.state.email.toLowerCase();

    try {
      await Auth.completeNewPassword(
        this.state.auth.auth,
        this.state.newpassword,
        {}
      );
      this.setState({ isLoading: false, errorMessage: "", openModal: true });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false, errorMessage: e.message });
    }
  };

  showPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword
    }));

    if (this.myPassword.type === "password") {
      this.myPassword.type = "text";
    } else {
      this.myPassword.type = "password";
    }
  };

  renderForm() {
    const { t } = this.props;
    return (
      <form className="xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-full xs:w-full w-full">
        <div className="block mb-6">
          <label htmlFor="newpassword">{t("Nouveau mot de passe")}</label>
          <div className="action-input">
            <input
              className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline mb-3"
              id="newpassword"
              name="new-password"
              type="password"
              placeholder="******************"
              ref={(input) => {
                this.myPassword = input;
              }}
              onChange={this.handleChange}
              defaultValue={this.state.newpassword}
              required
            />

            <span
              toggle="#password-field"
              className={
                this.state.showPassword
                  ? "fa fa-fw fa-eye field-icon toggle-password"
                  : "fa fa-fw fa-eye-slash field-icon toggle-password"
              }
              tabIndex="0"
              onClick={this.showPassword}></span>
          </div>
          <p
            className="text-red-500 pb-4"
            id="capsLock"
            style={{ display: "none" }}>
            {t("Touche Maj activée")}
          </p>
        </div>
        <div className="flex flex-wrap w-full text-xs pb-6">
          <div className="w-1/2 inline-flex">
            <span
              className={
                this.state.newpassword.match(/[A-Z]/) ? "greenIcon" : "greyIcon"
              }
              id="uppercase">
              <FontAwesomeIcon icon="check" />
            </span>
            <p>{t("Un caractère majuscule")}</p>
          </div>
          <div className="w-1/2 inline-flex">
            <span
              className={
                this.checkSpecialChar(this.state.newpassword)
                  ? "greenIcon"
                  : "greyIcon"
              }
              id="special">
              <FontAwesomeIcon icon="check" />
            </span>
            <p>{t("Un caractère spécial")}</p>
          </div>
          <div className="w-1/2 inline-flex">
            <span
              className={
                this.state.newpassword.match(/[a-z]/) ? "greenIcon" : "greyIcon"
              }
              id="lowercase">
              <FontAwesomeIcon icon="check" />
            </span>
            <p>{t("Un caractère minuscule")}</p>
          </div>
          <div className="w-1/2 inline-flex">
            <span
              className={
                this.state.newpassword.length > 7 ? "greenIcon" : "greyIcon"
              }
              id="eight">
              <FontAwesomeIcon icon="check" />
            </span>
            <p>{t("Huit caractères minimum")}</p>
          </div>
          <div className="w-1/2 inline-flex">
            <span
              className={
                /\d/.test(this.state.newpassword) ? "greenIcon" : "greyIcon"
              }
              id="number">
              <FontAwesomeIcon icon="check" />
            </span>
            <p>{t("Un nombre")}</p>
          </div>
        </div>
        {this.state.errorMessage && (
          <MessageBlock
            alertMessage={this.state.errorMessage}
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 mb-4"
            roleType="alert"
          />
        )}

        <div className="md:flex justify-center mb-3 text-white x-full">
          <Button
            className="shadow mt-3 bg-white hover:bg-purple-400 focus:shadow-outline focus:outline-none font-bold block py-1 px-10 rounded-full w-3/5 bg-donaPurple"
            disabled={!this.validateConfirmationForm() || this.state.isLoading}
            onClick={this.handleSubmit}>
            {this.state.isLoading ? (
              <FontAwesomeIcon icon="spinner" spin />
            ) : (
              t("Réinitialisez")
            )}
          </Button>
        </div>

        <Modal
          name="resetPasswordModal"
          headerText={t("La réinitialisation du mot de passe est réussie")}
          actionButtonText="Login"
          isModalOpen={this.state.openModal}
          cancelButtonText="Cancel"
          actionButtonFunction={() => this.props.history.push("/connexion")}
          icon={faCheck}>
          <p className="text-md leading-5 text-gray-900">
            {t(
              "Votre mot de passe a été réinitialisé,cliquez pour revenir en arrière et vous connecter."
            )}
          </p>
        </Modal>
      </form>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <main className="ForgotPassword">
        <br />
        <div className="login-segment">
          <h1 className="text-center text-black">
            {t("Réinitialisez votre mot de passe")}
          </h1>
          <div className="mx-auto bg-white rounded-lg p-6 xl:w-6/12 lg:w-8/12 md:w-full sm:w-full xs:w-full w-full mb-5 mt-5">
            {this.renderForm()}
          </div>
        </div>
      </main>
    );
  }
}

export default ResetPassword;
