import cogoToast from "cogo-toast";
import DebouncedInput from "react-debounce-input";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Table from "../components/Table";
import HttpDataSource from "../api/HttpDataSource";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateConverter } from "../libs/utility";
import MessageBlock from "../components/MessageBlock";

class DocumentsAchetesUtilisateursList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      documentsUtilisateurs: [],
      originalDocumentsUtilisateurs: [],
      searchDocumentsUtilisateurs: "",
      showMore: false,
      errorMessage: ""
    };
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let documentsUtilisateurs = [];
    let options = { position: "bottom-right" };
    const userGroup = window.localStorage.getItem("userGroup");
    try {
      const data = await HttpDataSource.getDocumentsAchetesUtilisateursData(
        this.props.userGroup || userGroup,
        this.props.idPublisher
      );

      data.result &&
        data.result.map((x) => {
          let total = x.quantite * x.prix;
          x.total = Number(Math.round(total + "e2") + "e-2").toFixed(2);
          return documentsUtilisateurs.push(x);
        });

      let data_length = documentsUtilisateurs.length;

      this.setState({
        documentsUtilisateurs: this.props.boardTable
          ? documentsUtilisateurs.slice(0, 10)
          : documentsUtilisateurs,
        originalDocumentsUtilisateurs: this.props.boardTable
          ? documentsUtilisateurs.slice(0, 10)
          : documentsUtilisateurs,
        isLoading: false,
        showMore: data_length > 10
      });
    } catch (error) {
      let message = "";
      if (error.message) {
        message = error.message + "!,please contact the site administrador";
      } else {
        message =
          error.response.data.error + "!,please contact the site administrador";
      }
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({
        errorMessage: message
      });
    }
  }

  onChange = (e) => {
    let originalDocumentsUtilisateurs = this.state
      .originalDocumentsUtilisateurs;
    let searchDocumentsUtilisateurs = e.target.value;

    if (searchDocumentsUtilisateurs) {
      let filterDemandesHistoriques = originalDocumentsUtilisateurs.filter(
        function (item) {
          if (Number(searchDocumentsUtilisateurs)) {
            return (
              item.quantite === Number(searchDocumentsUtilisateurs) ||
              (item.total &&
                item.total === Number(searchDocumentsUtilisateurs)) ||
              (item.date_et_heure &&
                dateConverter(item.date_et_heure).includes(
                  searchDocumentsUtilisateurs
                )) ||
              (item.isbn_issn &&
                item.isbn_issn
                  .toLowerCase()
                  .includes(
                    searchDocumentsUtilisateurs.toString().toLowerCase()
                  ))
            );
          } else {
            return (
              (item.date_et_heure &&
                dateConverter(item.date_et_heure).includes(
                  searchDocumentsUtilisateurs
                )) ||
              (item.titre &&
                item.titre
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(
                    searchDocumentsUtilisateurs
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  )) ||
              (item.nom_org.nom &&
                item.nom_org.nom
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(
                    searchDocumentsUtilisateurs
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  ))
            );
          }
        }
      );
      this.setState({
        documentsUtilisateurs: filterDemandesHistoriques,
        searchDocumentsUtilisateurs
      });
    } else {
      this.setState({
        documentsUtilisateurs: originalDocumentsUtilisateurs,
        searchDocumentsUtilisateurs
      });
    }
    this.ref.focus();
  };

  render() {
    const { t } = this.props;

    this.columns = [
      {
        dataField: "titre",
        text: t("Titre du produit"),
        classes:
          "px-5 py-4 text-left border-b border-gray-200 text-md leading-5 text-donaPurple underline font-bold",
        sort: this.props.boardTable ? false : true,
        headerClasses: "w-64 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        attrs: { role: "rowheader", scope: "row" },
        formatter: (cell, row) => (
          <Link
            to={`historique-achats-produits-details/${row.id_commande_produit}`}>
            {row.titre}
          </Link>
        )
      },
      {
        dataField: "isbn_issn",
        text: t("Identifiant"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        sort: this.props.boardTable ? false : true,
        headerClasses: "w-32 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 }
      },
      {
        dataField: "date_et_heure",
        text: t("Date"),
        headerClasses: "w-40 whitespace-no-wrap text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        formatter: (cell, row) => (
          <span>{dateConverter(row.date_et_heure)}</span>
        ),
        sort: this.props.boardTable ? false : true
      },
      {
        dataField: "nom_org.nom",
        text: t("Maison d'édition"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 whitespace-no-wrap text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        sort: this.props.boardTable ? false : true
      },
      {
        dataField: "quantite",
        text: t("Quantité achetée"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-20 whitespace-normal text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "total",
        text: t("Coût total"),
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-20 whitespace-normal text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell) => `${cell ? cell + "$" : ""}`
      }
    ];

    const CaptionElement = () => (
      <>
        {this.props.boardTable ? (
          <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h2 className="text-white font-bold mt-2">
              {t("Historique des achats de mes produits")}
            </h2>
          </div>
        ) : (
          <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h1 className="text-white font-bold mt-2">
              {t("Historique des achats de mes produits")}
            </h1>
            <div
              className="text-white text-left self-center mr-auto pl-1"
              aria-live="polite">
              {this.state.isLoading === false &&
                "(" +
                  this.state.documentsUtilisateurs.length +
                  " " +
                  (this.state.documentsUtilisateurs.length > 1
                    ? t("enregistrements")
                    : t("enregistrement")) +
                  ")"}
            </div>
            <div className="relative">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute pointer-events-none  inset-y-0 ml-3 mt-3 flex items-center"
              />
              <DebouncedInput
                inputRef={(ref) => {
                  this.ref = ref;
                }}
                minLength={1}
                debounceTimeout={1200}
                type="search"
                onChange={this.onChange}
                className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 pl-10 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={t("Recherche sur table")}
                value={this.state.searchDocumentsUtilisateurs}
                aria-label={t("Rechercher dans le tableau")}
              />
            </div>
          </div>
        )}
      </>
    );
    return (
      <>
        {!this.props.boardTable && (
          <Helmet>
            <title>{t("Achats utilisateurs Liste")}</title>
            <meta name="description" content={t("Achats utilisateurs Liste")} />
          </Helmet>
        )}

        {this.state.errorMessage ? (
          <MessageBlock
            alertMessage={this.state.errorMessage}
            className="border px-4 py-3 rounded relative mt-6 mb-2"
            roleType="alert"
          />
        ) : (
          <Table
            keyField="id_commande_produit"
            showMore={this.state.showMore}
            boardTable={this.props.boardTable}
            data={this.state.documentsUtilisateurs}
            caption={<CaptionElement />}
            columns={this.columns}
            loading={this.state.isLoading}
            classes="table-auto"
            noDataMessage={t("Pas de produits disponible")}
            showMorePagePath="/historique-achats-produits"
            csvFileName={t(
              "historiques_de_mes_produits_achetés_par_les_utilisateurs.csv"
            )}
            t={t}
          />
        )}
      </>
    );
  }
}

DocumentsAchetesUtilisateursList.propTypes = {
  boardTable: PropTypes.bool
};

DocumentsAchetesUtilisateursList.defaultProps = {
  boardTable: false
};

export default DocumentsAchetesUtilisateursList;
