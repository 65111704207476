import Header from "../components/Header";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withAuthConsumer } from "../authcontext/AuthConsumer";

export default (WrappedComponent) => {
  class SignedOutHOC extends Component {
    render() {
      return (
        <>
          <Header />
          <main className="mainContent">
            <WrappedComponent {...this.props} />
          </main>
        </>
      );
    }
  }

  return withRouter(withTranslation()(withAuthConsumer(SignedOutHOC)));
};
