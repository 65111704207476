import React from "react";
import Provider from "./authcontext/AuthProvider";

import Routes from "./Routes";

const Root = () => (
  <Provider>
    <Routes />
  </Provider>
);

export default Root;
