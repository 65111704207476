import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from "../components/Button";
import {
  faSearch,
  faSortUp,
  faSortDown,
  faAngleDoubleRight,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

library.add(faSearch, faSortUp, faSortDown, faAngleDoubleRight, faDownload);

export default function Table(props) {
  const {
    keyField,
    boardTable,
    showMore,
    caption,
    columns,
    loading,
    noDataMessage,
    showMorePagePath,
    t
  } = props;

  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const sortOption = {
    sortCaret: (order, column) => {
      if (!order) {
        return <FontAwesomeIcon icon={faSortUp} size="2x" className="mx-2" />;
      } else if (order === "asc") {
        return <FontAwesomeIcon icon={faSortUp} size="2x" className="mx-2" />;
      } else if (order === "desc") {
        return (
          <FontAwesomeIcon
            icon={faSortDown}
            size="2x"
            className="mx-2"
            aria-sort={t("descending")}
          />
        );
      }
      return null;
    }
  };

  const ExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Button
        className="button-color--invertTurquoisepale my-2 py-1 mr-3 rounded-lg "
        aria-label="Exporter au format CSV"
        onClick={handleClick}>
        <FontAwesomeIcon
          icon={faDownload}
          size="2x"
          style={{ height: "25px" }}
          className="mx-1"
        />
      </Button>
    );
  };

  const rowStyle = (row, rowIndex) => {
    row.index = rowIndex;
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "transparent";
    } else {
      style.backgroundColor = "#EAEBEC";
    }
    style.borderTop = "none";

    return style;
  };

  const defaultSorted = [
    {
      dataField: keyField,
      order: "desc"
    }
  ];

  return (
    <>
      {loading ? (
        <>
          {caption}
          {t("En chargement...")}
          <FontAwesomeIcon icon="spinner" size="xs" spin className="mt-2" />
        </>
      ) : (
        <div
          className={
            props.boardTable ? "overflow-x-auto mb-6" : "overflow-x-auto"
          }>
          <ToolkitProvider
            keyField={props.keyField}
            data={data}
            columns={columns}
            exportCSV={{
              fileName: props.csvFileName
            }}>
            {(props) => (
              <>
                <BootstrapTable
                  {...props.baseProps}
                  sort={sortOption}
                  defaultSorted={defaultSorted}
                  rowStyle={rowStyle}
                  caption={caption}
                  noDataIndication={() => <div>{noDataMessage}</div>}
                />
                {boardTable && showMore && (
                  <div className="flex py-2 justify-center mb-2">
                    <Link
                      className="underline font-bold text-donaPurple"
                      to={showMorePagePath}>
                      {t("Voir Plus")}
                      <FontAwesomeIcon
                        icon={faAngleDoubleRight}
                        size="1x"
                        className="mx-1 underline"
                      />
                    </Link>
                  </div>
                )}
                {!boardTable &&
                  !(props.baseProps.columns[0].dataField === "titre_produit") &&
                  !(
                    props.baseProps.columns[0].dataField === "titre_document"
                  ) && <ExportCSV {...props.csvProps} />}
              </>
            )}
          </ToolkitProvider>
        </div>
      )}
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  noDataMessage: PropTypes.string,
  search: PropTypes.bool,
  boardTable: PropTypes.bool,
  showMore: PropTypes.bool,
  csvFileName: PropTypes.string
};
