import PropTypes from "prop-types";
import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// fake data generator
// const getItems = (count) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `item ${k}`
//   }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 25;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? "#84277E" : "",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#eaebec" : "white",
  display: "flex",
  overflow: "auto"
});

class DraggableCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async componentDidMount() {
    this.setState({ items: this.props.items });
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({ items: this.props.items });
    }
  }

  trashOnClick = (index) => {
    const cpitems = [...this.state.items];
    cpitems.splice(index, 1);
    this.setState({ items: cpitems });
    this.props.removeCard(index);
  };

  onKeyDown = (event, index) => {
    var key = event.keyCode || event.charCode;

    if (key === 8 || key === 46) {
      this.trashOnClick(index);
    }
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        items
      },
      () => {
        this.props.onChange(items);
      }
    );
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}>
              {this.state.items.map((item, index) => (
                <Draggable
                  key={`item-${index}`}
                  draggableId={`item-${index}`}
                  index={index}>
                  {(provided, snapshot) => (
                    <div className="trash-container cursor-pointer">
                      <span
                        className="close float-left"
                        onClick={() => this.trashOnClick(index)}>
                        &times;
                      </span>

                      <div
                        onKeyDown={(event) => this.onKeyDown(event, index)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <div
                          className="text-white"
                          style={{ maxWidth: "100px" }}
                          onKeyPress={(event) => this.onKeyDown(event)}>
                          <img
                            src={
                              typeof item.content === "object"
                                ? URL.createObjectURL(item.content)
                                : item
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

DraggableCards.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  removeCard: PropTypes.func.isRequired
};

export default DraggableCards;
