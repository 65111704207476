import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import cogoToast from "cogo-toast";
import { dateConverter } from "../libs/utility";
import Err403 from "../containers/Err403";
import HttpDataSource from "../api/HttpDataSource";

export const DocumentsAchetesUtilisateursDetails = (props) => {
  window && window.scroll(0, 0);
  const { t } = props;
  let autors = [];
  let skeletonLoaderItems = [];
  const [demandeDetailsData, setDemandeDetailsData] = useState([]);
  const [errorStatusCode, setErrorStatusCode] = useState("");

  const SkeletonLoader = () => (
    <tr>
      <th className="w-40 border-none font-bold">
        <Skeleton width={150} height={30} />
      </th>
      <td>
        <Skeleton width={260} height={30} />
      </td>
    </tr>
  );

  for (var i = 0; i < 13; i++) {
    skeletonLoaderItems.push(<SkeletonLoader key={i} />);
  }

  useEffect(() => {
    const fetchData = async () => {
      let options = { position: "bottom-right" };
      try {
        const data = await HttpDataSource.getDocumentsAchetesUtilisateursDetailsData(
          props.match.params.id
        );
        if (data.result === null) {
          props.history.push("/404");
          window.location.reload();
        }
        setDemandeDetailsData(data.result);
      } catch (error) {
        let message = error.response.data.error;
        cogoToast.error(
          <div aria-live="polite">{props.t(message)}</div>,
          options
        );
        setErrorStatusCode(error.response.status);
      }
    };

    fetchData();
  }, [props, props.history, props.match.params.id]);

  if (errorStatusCode === 403) {
    return <Err403 />;
  }

  if (demandeDetailsData !== null)
    if (demandeDetailsData.length > 0) {
      if (demandeDetailsData[0].auteurs) {
        demandeDetailsData[0].auteurs.map((auteur, index) => {
          if (auteur.pseudonyme) {
            autors.push(
              <li
                className="bg-donaGray"
                style={{
                  display: "list-item",
                  backgroundColor: "white",
                  margin: "0",
                  padding: "0"
                }}
                key={index}>
                {`${auteur.pseudonyme}`}{" "}
              </li>
            );
          } else {
            autors.push(
              <li
                className="bg-donaGray"
                style={{
                  display: "list-item",
                  backgroundColor: "white",
                  margin: "0",
                  padding: "0"
                }}
                key={index}>
                {`${auteur.prenom} ${auteur.nom}`}
              </li>
            );
          }
        });
      }
    }

  return (
    <>
      <Helmet>
        <title>{t("Détails de l’achat sélectionné")}</title>
        <meta name="description" content={t("Achats Utilisateurs Détails")} />
      </Helmet>
      <div className="flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
        <h1 className="text-white font-bold">
          {t(
            "Historique des achats de mes produits - Détails de l'achat sélectionné"
          )}
        </h1>
      </div>
      <div className="px-5">
        <table className="xl:w-2/5 lg:w-2/3 w-full my-6 shadow-lg border-2 border-donaPurple rounded mx-auto">
          <tbody>
            {!demandeDetailsData.length ? (
              skeletonLoaderItems
            ) : (
              <>
                <tr className="bg-donaGray md:block grid">
                  <th
                    scope="col"
                    className=" w-56 border-none font-bold text-left py-1">
                    {t("Numéro de commande")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {demandeDetailsData[0].id_commande_produit}
                  </td>
                </tr>
                <tr className="bg-donaGray md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Date de l'achat")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {dateConverter(demandeDetailsData[0].date_et_heure)}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("ISBN")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {demandeDetailsData[0].isbn_issn}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Titre du produit")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {demandeDetailsData[0].titre_produit}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Type")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {t(demandeDetailsData[0].type_produit)}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Niveau")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {t(demandeDetailsData[0].niveau)}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Langue")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {demandeDetailsData[0].langue === null
                      ? t("Non-disponible")
                      : demandeDetailsData[0].langue === "fr"
                      ? t("Français")
                      : demandeDetailsData[0].langue === "en"
                      ? t("Anglaish")
                      : t("Espagnol")}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Auteur(s)")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {autors.length > 0 ? (
                      <ul className="list-disc">{autors}</ul>
                    ) : (
                      t("Non-disponible")
                    )}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Maison d'edition")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">{`${
                    JSON.parse(demandeDetailsData[0].maison_edition).nom !==
                    undefined
                      ? JSON.parse(demandeDetailsData[0].maison_edition).nom
                      : ""
                  }`}</td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Année de publication")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {demandeDetailsData[0].date_parution &&
                      demandeDetailsData[0].date_parution.substring(0, 4)}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Disponible sur Dona")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {demandeDetailsData[0].disponible ? t("Oui") : t("Non")}
                  </td>
                </tr>
                <tr className="border-t border-black md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Prix unitaire")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {props.i18n.language === "fr"
                      ? demandeDetailsData[0].prix.replace(".", ",") + "$"
                      : "$" + demandeDetailsData[0].prix}
                  </td>
                </tr>
                <tr className="md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Quantité achetée")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {demandeDetailsData[0].quantite}
                  </td>
                </tr>
                {demandeDetailsData[0].escompte !== "0" && (
                  <tr className="md:block grid ">
                    <th
                      scope="col"
                      className="w-56 border-none font-bold text-left py-1">
                      {t("Rabais")}
                    </th>
                    <td className="md:text-left text-center py-1 text-black">
                      {props.i18n.language === "fr"
                        ? demandeDetailsData[0].escompte.replace(".", ",") + "$"
                        : "$" + demandeDetailsData[0].escompte}
                    </td>
                  </tr>
                )}
                <tr className="border-t-2 border-black bg-donaGray md:block grid ">
                  <th
                    scope="col"
                    className="w-56 border-none font-bold text-left py-1">
                    {t("Total")}
                  </th>
                  <td className="md:text-left text-center py-1 text-black">
                    {props.i18n.language === "fr"
                      ? (
                          demandeDetailsData[0].prix *
                            demandeDetailsData[0].quantite -
                          demandeDetailsData[0].escompte
                        )
                          .toFixed(2)
                          .replace(".", ",") + "$"
                      : "$" +
                        (
                          demandeDetailsData[0].prix *
                            demandeDetailsData[0].quantite -
                          demandeDetailsData[0].escompte
                        ).toFixed(2)}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DocumentsAchetesUtilisateursDetails;
