import Auth from "@aws-amplify/auth";
import React from "react";
import PropTypes from "prop-types";
import cogoToast from "cogo-toast";
import Modal from "../components/Modal";

const Logout = (props) => {
  const { t } = props;

  const handleLogout = async () => {
    let options = { position: "bottom-right" };
    try {
      await Auth.signOut();
      props.userHasAuthenticated(false);
      window.localStorage.clear();
      cogoToast.success(
        <div aria-live="polite">{t("Déconnexion Réussie")}</div>,
        options
      );
      props.history.push("/");
    } catch (e) {
      cogoToast.error(<div aria-live="polite">{t(e.message)}</div>, options);
      console.log("error", e.message);
    }
  };

  return (
    <Modal
      setModalOpen={() => props.setModalOpen()}
      name="logOutModal"
      headerText={t("Fermeture de Session")}
      actionButtonText={t("Fermer Session")}
      isModalOpen={props.isModalOpen}
      cancelButtonText={t("Annuler")}
      icon={props.icon}
      actionButtonFunction={() => handleLogout()}>
      <p className="text-md leading-5 text-gray-900">
        {t("Êtes-vous certain de vouloir fermer votre session?")}
      </p>
    </Modal>
  );
};

Logout.propTypes = { icon: PropTypes.any };

export default Logout;
