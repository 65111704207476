import React from "react";
import PropTypes from "prop-types";

const MessageBlock = React.forwardRef((props, ref) => {
  const { alertMessage, className, roleType, ...rest } = props;

  return (
    <div className={className} role={roleType} {...rest}>
      <div className="flex">
        <div className="py-1">
          <svg
            className={`fill-current h-6 w-6  mr-4 ${
              roleType === "success" ? "text-teal-500" : "text-red-500"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div className="py-1">
          <p>
            <strong className="font-bold">
              {roleType === "alert" ? "Ooops!" : "Success!"}
            </strong>
          </p>
          <p>
            <span className="block sm:inline">{alertMessage} </span>
          </p>
        </div>
      </div>
    </div>
  );
});

MessageBlock.propTypes = {
  className: PropTypes.string.isRequired,
  roleType: PropTypes.string.isRequired,
  alertMessage: PropTypes.string
};

export default MessageBlock;
