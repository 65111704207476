import cogoToast from "cogo-toast";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Table from "../components/Table";
import HttpDataSource from "../api/HttpDataSource";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DebouncedInput from "react-debounce-input";
import { dateConverter } from "../libs/utility";
import MessageBlock from "../components/MessageBlock";

class HistoriquesRepresentantsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      historiquesRepresentants: [],
      originalHistoriquesRepresentants: [],
      searchDemandesHistoriques: "",
      showMore: false,
      errorMessage: ""
    };
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let historiquesRepresentants = [];
    let options = { position: "bottom-right" };
    const userGroup = window.localStorage.getItem("userGroup");

    try {
      const data = await HttpDataSource.getHistoriquesRepresentantsData(
        this.props.userGroup || userGroup,
        this.props.idPublisher
      );

      data.result &&
        data.result.map((x) => {
          return historiquesRepresentants.push(x);
        });

      let data_length = historiquesRepresentants.length;

      this.setState({
        historiquesRepresentants: this.props.boardTable
          ? historiquesRepresentants
              .sort(function (a, b) {
                return b.id_commande - a.id_commande;
              })
              .slice(0, 10)
          : historiquesRepresentants,
        originalHistoriquesRepresentants: this.props.boardTable
          ? historiquesRepresentants.sort(function (a, b) {
              return b.id_commande - a.id_commande;
            })
          : historiquesRepresentants,
        isLoading: false,
        showMore: data_length > 10
      });
    } catch (error) {
      let message = "";
      if (error.message) {
        message = error.message + "!,please contact the site administrador";
      } else {
        message =
          error.response.data.error + "!,please contact the site administrador";
      }
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({
        errorMessage: message
      });
    }
  }

  UploadDocument = () => {
    this.props.history.push("/produits-deposer");
  };
  onChange = (e) => {
    let originalHistoriquesRepresentants = this.state
      .originalHistoriquesRepresentants;
    let searchDemandesHistoriques = e.target.value;

    if (searchDemandesHistoriques) {
      let filterDemandesHistoriques = originalHistoriquesRepresentants.filter(
        function (item) {
          if (Number(searchDemandesHistoriques)) {
            return (
              Number(item.cout_total) === Number(searchDemandesHistoriques) ||
              Number(item.total_docs) === Number(searchDemandesHistoriques) ||
              item.id_commande === Number(searchDemandesHistoriques) ||
              (item.date_et_heure &&
                dateConverter(item.date_et_heure).includes(
                  searchDemandesHistoriques
                ))
            );
          } else {
            return (
              (item.institution.nom &&
                item.institution.nom
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(
                    searchDemandesHistoriques
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  )) ||
              (item.date_et_heure &&
                dateConverter(item.date_et_heure).includes(
                  searchDemandesHistoriques
                )) ||
              (item.representant &&
                item.representant
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(
                    searchDemandesHistoriques
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  )) ||
              (item.statut &&
                item.statut
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(
                    searchDemandesHistoriques
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  ))
            );
          }
        }
      );
      this.setState({
        historiquesRepresentants: filterDemandesHistoriques,
        searchDemandesHistoriques
      });
    } else {
      this.setState({
        historiquesRepresentants: originalHistoriquesRepresentants,
        searchDemandesHistoriques
      });
    }
    this.ref.focus();
  };

  render() {
    const { t } = this.props;

    this.columns = [
      {
        dataField: "id_commande",
        text: t("No."),
        headerClasses: "w-20 text-center text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        attrs: { role: "rowheader", scope: "row" },
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-donaPurple underline font-bold break-words",
        formatter: (cell, row) => (
          <Link
            to={`historique-achats-representants-details/${row.id_commande}`}>
            {row.id_commande}
          </Link>
        )
      },
      {
        dataField: "date_et_heure",
        text: t("Date"),
        headerClasses:
          "w-40 px-4 py-2 whitespace-no-wrap text-donaPurple font-bold",
        headerAttrs: { scope: "col" },
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black break-words",
        formatter: (cell, row) => (
          <span>{dateConverter(row.date_et_heure)}</span>
        ),
        sort: this.props.boardTable ? false : true
      },
      {
        dataField: "cout_total",
        text: t("Coût total avec taxes"), // data not showing ask humberto
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-40 px-4 py-2  text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell) => `${cell ? cell + "$" : ""}`
      },
      {
        dataField: "total_docs",
        text: t("Quantité achetée"), // data not showing ask humberto
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-32 whitespace-wrap text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "institution.nom",
        text: t("Institution"),
        classes: "px-5 py-4 text-center border-b text-md leading-5 text-black",
        headerClasses: "w-40 text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      },
      {
        dataField: "representant",
        text: t("Requérant"),
        classes: "px-5 py-4 text-center border-b text-md leading-5 text-black",
        headerClasses:
          "w-40 whitespace-no-wrap text-donaPurple font-bold break-words",
        headerAttrs: { scope: "col" },
        sort: this.props.boardTable ? false : true
      },
      {
        dataField: "statut",
        text: t("Statut"),
        classes:
          "px-4 text-center border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-48 text-center text-donaPurple font-bold",
        headerAttrs: { scope: "col", tabIndex: "-1" }
      }
    ];

    const CaptionElement = () => (
      <>
        {this.props.boardTable ? (
          <div className="flex justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h2 className="text-white font-bold mt-2">
              {t("Historiques des  bons de commandes par les représentants")}
            </h2>
          </div>
        ) : (
          <div className="flex justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
            <h1 className="text-white font-bold mt-2">
              {t("Historique des achats")}
            </h1>
            <div
              className="text-white text-left self-center mr-auto pl-1"
              aria-live="polite">
              {this.state.isLoading === false &&
                "(" +
                  this.state.historiquesRepresentants.length +
                  " " +
                  (this.state.historiquesRepresentants.length > 1
                    ? t("enregistrements")
                    : t("enregistrement")) +
                  ")"}
            </div>
            <div className="relative">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute pointer-events-none  inset-y-0 ml-3 mt-3 flex items-center"
              />
              <DebouncedInput
                inputRef={(ref) => {
                  this.ref = ref;
                }}
                minLength={1}
                debounceTimeout={1200}
                type="search"
                onChange={this.onChange}
                className="w-80 shadow appearance-none border rounded  py-2 px-3 text-gray-700 pl-10 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={t("Recherche sur table")}
                value={this.state.searchDemandesHistoriques}
                aria-label={t("Rechercher dans le tableau")}
              />
            </div>
          </div>
        )}
      </>
    );
    return (
      <>
        {!this.props.boardTable && (
          <Helmet>
            <title>
              {t("Historique d’achats représentants d'institution")}
            </title>
            <meta
              name="description"
              content={t("Historique d’achats représentants d'institution")}
            />
          </Helmet>
        )}

        {this.state.errorMessage ? (
          <MessageBlock
            alertMessage={this.state.errorMessage}
            className="border px-4 py-3 rounded relative mt-6 mb-2"
            roleType="alert"
          />
        ) : (
          <Table
            keyField="id_commande"
            showMore={this.state.showMore}
            boardTable={this.props.boardTable}
            data={this.state.historiquesRepresentants}
            caption={<CaptionElement />}
            columns={this.columns}
            loading={this.state.isLoading}
            classes="table-auto"
            noDataMessage={t("Pas de Commandes Disponible")}
            csvFileName={t(
              "historiques_des_commandes_d'achats_par_les_representants.csv"
            )}
            t={t}
            showMorePagePath="/historique-achats-representants"
          />
        )}
      </>
    );
  }
}

HistoriquesRepresentantsList.propTypes = {
  boardTable: PropTypes.bool
};

HistoriquesRepresentantsList.defaultProps = {
  boardTable: false
};

export default HistoriquesRepresentantsList;
