import React from "react";
import "../styles/Err404.css";
import { Link } from "react-router-dom";

const Err403 = () => (
  <>
    <div className="background"></div>
    <div className="container">
      <div className="left-section">
        <div className="inner-content">
          <h1 className="heading">403</h1>
          <p className="subheading">
            Forbidden - It looks like you have tried to access a page that your
            group does not have sufficient permissions to view.
          </p>
          <Link to={"/connexion"}>
            <span className="underline text-center text-white">
              Return to the login page
            </span>
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default Err403;
