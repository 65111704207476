import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // if not using I18nextProvider
  .init({
    debug: false,
    whitelist: ["fr", "en"],
    nonExplicitWhitelist: true,
    load: "languageOnly",
    fallbackLng: ["fr", "en"],
    lng: window.localStorage.getItem("userLanguage") || navigator.language,
    ns: ["translations"],
    defaultNS: "translations",
    react: {
      useSuspense: false
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
