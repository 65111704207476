import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import Auth from "@aws-amplify/auth";
import crypto from "crypto";
import config from "../config";

export default class HttpRequest {
  static async getHash(id) {
    let user = await Auth.currentUserCredentials();
    let hashData = `${id}${user.identityId}`;
    let hash = crypto
      .createHash("sha256")
      .update(hashData, "utf8")
      .digest("hex");
    return hash;
  }

  static async fetchDocumentsChunk(group, id_publisher) {
    try {
      let response = "";

      if (group === "superadmin") {
        let override_publisher = id_publisher ? id_publisher : 0;
        let endpoint = id_publisher
          ? "DocumentsGatewayAPI"
          : "DocumentsCopibecGatewayAPI";

        response = API.get(endpoint, override_publisher, {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      } else {
        response = API.get("DocumentsGatewayAPI", id_publisher, {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchPublishersChunk(group, id_publisher) {
    try {
      let response = "";
      if (group === "superadmin") {
        response = API.get("PublishersCopibecGatewayAPI", "0", {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      } else {
        response = API.get("PublishersGatewayAPI", id_publisher, {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchDocumentsDetailsChunk(id) {
    try {
      const response = API.get("DocumentsDetailsGatewayAPI", id, {
        queryStringParameters: {
          hash: await this.getHash(id)
        }
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchGroupesDocumentsDetailsChunk(id) {
    try {
      const response = API.get("GroupesDocumentsDetailsGatewayAPI", id, {
        queryStringParameters: {
          hash: await this.getHash(id)
        }
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  static updateDocumentsDetailsChunk(data) {
    try {
      const bodyParams = {
        body: { ...data }
      };

      const response = API.post(
        "UpdateDocumentsDetailsGatewayAPI",
        "",
        bodyParams
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchDemandesChunk(group, showAll, id_publisher) {
    try {
      //Need to fixed this after demo
      let response = "";
      if (group === "superadmin") {
        if (showAll) {
          response = API.get("DemandesCopibecAllGatewayAPI", "0", {
            queryStringParameters: {
              hash: await this.getHash(id_publisher)
            }
          });
        } else {
          response = API.get("DemandesCopibecGatewayAPI", "0", {
            queryStringParameters: {
              hash: await this.getHash(id_publisher)
            }
          });
        }
      } else {
        if (showAll) {
          response = API.get("DemandesAllGatewayAPI", id_publisher, {
            queryStringParameters: {
              hash: await this.getHash(id_publisher)
            }
          });
        } else {
          response = API.get("DemandesGatewayAPI", id_publisher, {
            queryStringParameters: {
              hash: await this.getHash(id_publisher)
            }
          });
        }
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchDemandesDetailsChunk(statut, id) {
    try {
      let response = "";

      switch (statut) {
        case "revision":
          response = API.get("DemandesDetailsCopibecGatewayAPI", id, {
            queryStringParameters: {
              hash: await this.getHash(id)
            }
          });
          break;

        case "attente":
          response = API.get("DemandesDetailsGatewayAPI", id, {
            queryStringParameters: {
              hash: await this.getHash(id)
            }
          });
          break;

        case "approuvee":
          response = API.get("DemandesDetailsApprouveeGatewayAPI", id, {
            queryStringParameters: {
              hash: await this.getHash(id)
            }
          });
          break;

        default:
          response = API.get("DemandesDetailsRefuseeGatewayAPI", id, {
            queryStringParameters: {
              hash: await this.getHash(id)
            }
          });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  //new one
  static approuveOrRejectDemandesDetailsChunk(data) {
    try {
      const bodyParams = {
        body: { ...data }
      };
      let response = "";
      response = API.post(
        "CreateDemandesDetailsApprouveOrRejeteGatewayAPI",
        "",
        bodyParams
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static fetchDemandesDetailsDocumentsChunk(isbn) {
    try {
      const response = API.get("DemandesDetailsDocumentsGatewayAPI", 97827);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static createDemandesDetailsChunk(data) {
    try {
      const bodyParams = {
        body: { ...data }
      };

      const response = API.post(
        "CreateDemandesDetailsGatewayAPI",
        "",
        bodyParams
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchDocumentsAchetesUtilisateursChunk(group, id_publisher) {
    try {
      let response = "";

      if (group === "superadmin") {
        response = API.get(
          "DocumentsCopibecAchetesUtilisateursGatewayAPI",
          "0",
          {
            queryStringParameters: {
              hash: await this.getHash(id_publisher)
            }
          }
        );
      } else {
        response = API.get(
          "DocumentsAchetesUtilisateursGatewayAPI",
          id_publisher,
          {
            queryStringParameters: {
              hash: await this.getHash(id_publisher)
            }
          }
        );
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchDocumentsAchetesUtilisateursDetailsChunk(id) {
    try {
      const response = API.get(
        "DocumentsAchetesUtilisateursDetailsGatewayAPI",
        id,
        {
          queryStringParameters: {
            hash: await this.getHash(id)
          }
        }
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchCommandesAchatsChunk(group, id_publisher) {
    try {
      let response = "";

      if (group === "superadmin") {
        response = API.get("CommandesCopibecAchatsGatewayAPI", "0", {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      } else {
        response = API.get("CommandesAchatsGatewayAPI", id_publisher, {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchCommandesAchatsDocumentsDetailsChunk(id) {
    try {
      const response = API.get(
        "CommandesAchatsDocumentsDetailsGatewayAPI",
        id,
        {
          queryStringParameters: {
            hash: await this.getHash(id)
          }
        }
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  ///check id for this function

  static async fetchCommandesAchatsOrderDetailsChunk(id) {
    try {
      const response = API.get("CommandesAchatsOrderDetailsGatewayAPI", id, {
        queryStringParameters: {
          hash: await this.getHash(id)
        }
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static approveOrRefuseCommandesAchatsOrderDetailsChunk(data) {
    try {
      const bodyParams = {
        body: { ...data }
      };

      const response = API.post(
        "ApproveOrRefuseCommandesAchatsOrderDetailsGatewayAPI",
        "",
        bodyParams
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchHistoriquesRepresentantsChunk(group, id_publisher) {
    try {
      let response = "";

      if (group === "superadmin") {
        response = API.get("HistoriquesCopibecRepresentantsGatewayAPI", "0", {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      } else {
        response = API.get("HistoriquesRepresentantsGatewayAPI", id_publisher, {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchHistoriquesRepresentantsOrderDetailsChunk(id) {
    try {
      const response = API.get(
        "HistoriquesRepresentantsOrderDetailsGatewayAPI",
        id,
        {
          queryStringParameters: {
            hash: await this.getHash(id)
          }
        }
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchHistoriquesRepresentantsDocumentsDetailsChunk(id) {
    try {
      const response = API.get(
        "HistoriquesRepresentantsDocumentsDetailsGatewayAPI",
        id,
        {
          queryStringParameters: {
            hash: await this.getHash(id)
          }
        }
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static postPublicationChunk(data) {
    try {
      const bodyParams = {
        body: { ...data }
      };

      const response = API.post("PublicationGatewayAPI", "", bodyParams);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static S3DeleteChunk(file) {
    try {
      const response = Storage.remove(file, {
        contentType: file.type,
        level: "protected",
        bucket: config.s3Image.BUCKET
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static S3GetChunk(file) {
    try {
      const response = Storage.get(file, {
        level: "protected"
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchOffresGroupesChunk(group, id_publisher) {
    try {
      let response = "";

      if (group === "superadmin") {
        response = API.get("OffresGroupesCopibecGatewayAPI", "0", {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      } else {
        response = API.get("OffresGroupesGatewayAPI", id_publisher, {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchDocumentDetailsOffresGroupesChunk(id) {
    try {
      const response = API.get("DocumentDetailsOffresGroupesGatewayAPI", id, {
        queryStringParameters: {
          hash: await this.getHash(id)
        }
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static createDocumentDetailsOffresGroupesChunk(data) {
    try {
      const bodyParams = {
        body: { ...data }
      };

      const response = API.post(
        "CreateDocumentDetailsGroupesGatewayAPI",
        "",
        bodyParams
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static updateDocumentDetailsOffresGroupesChunk(data) {
    try {
      const bodyParams = {
        body: { ...data }
      };

      const response = API.post(
        "UpdateDocumentDetailsGroupesGatewayAPI",
        "",
        bodyParams
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchComboOffresGroupesChunk(group, id_publisher) {
    try {
      let response = "";

      if (group === "superadmin") {
        response = API.get("ComboCopibecOffresGroupesGatewayAPI", 0, {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      } else {
        response = API.get("ComboOffresGroupesGatewayAPI", id_publisher);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchDocumentsGroupesChunk(id) {
    try {
      const response = API.get("DocumentsGroupesGatewayAPI", id, {
        queryStringParameters: {
          hash: await this.getHash(id)
        }
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchRaisonsChunk(group, id_publisher) {
    try {
      let response = "";
      if (group === "superadmin") {
        response = API.get("RaisonsSuperAdminGatewayAPI", "", {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      } else {
        response = API.get("RaisonsAdminGatewayAPI", "", {
          queryStringParameters: {
            hash: await this.getHash(id_publisher)
          }
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }
}
