import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusCircle,
  faTrashAlt,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import React, { Component } from "react";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import AutoCompleteSelect from "../components/AutoCompleteSelect";
import Button from "../components/Button";
import MessageBlock from "../components/MessageBlock";
import DraggableCards from "../components/DraggableCards";
import HttpDataSource from "../api/HttpDataSource";
import { dateConverter, processImages } from "../libs/utility";
import "../styles/DocumentDetails.css";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/datepicker.css";

library.add(faPlusCircle, faTrashAlt, faInfoCircle);

export default class UploadDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      titre: "",
      description: "",
      id_maison_edition: "",
      maison_edition: { value: "", label: "" },
      identifiant: "",
      vendre_separe: true,
      niveau: "primaire/secondaire",
      date_parution: null,
      page_initiale: "",
      page_finale: "",
      nombre_pages: "",
      disponible: false,
      langue: "fr",
      documents: [],
      hyperlien_image: [],
      hyperlien_image_body: "",
      nom_fichier_body: "",
      nom_fichier: "",
      prix: "",
      maisons_editions: [],
      auteurs: [{ prenom: "", nom: "", pseudonyme: "" }],
      type_publication: "default",
      errorMessage: "",
      successMessage: "",
      editeur_public: ""
    };
    this.initialState = "";
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let format_maisons_editions = [];

    const userGroup =
      this.props.userGroup || window.localStorage.getItem("userGroup");
    const idPublisher =
      this.props.idPublisher || window.localStorage.getItem("idPublisher");

    const maisons_editions = await HttpDataSource.getPublishersData(
      userGroup,
      idPublisher
    );

    if (this.props.userGroup !== "superadmin") {
      maisons_editions.result.map((item) => {
        format_maisons_editions.push({
          id_organisation: item.id_organisation,
          nom: item.nom.nom
        });
      });
      this.setState({
        maisons_editions: format_maisons_editions
      });
    } else {
      this.setState({
        maisons_editions: maisons_editions.result
      });
    }

    this.initialState = this.state;
  }

  validateConfirmationForm() {
    return (
      this.state.identifiant.length > 0 &&
      this.state.titre.length > 0 &&
      this.state.id_maison_edition !== "" &&
      this.state.nom_fichier != "" &&
      this.state.prix !== "" &&
      this.state.type_publication !== "default"
    );
  }

  getPublishersData = async (inputValue) => {
    if (inputValue.length >= 3) {
      const dataOptions = await HttpDataSource.getPublishersData(
        inputValue.toLowerCase()
      );

      const dataOptionsFormated = dataOptions.result.map((suggestion) => {
        return {
          value: suggestion.id,
          label: suggestion.nom_maison_edition
        };
      });

      return dataOptionsFormated;
    }
  };

  setSelectValue = (selected) => {
    if (selected === "") {
      this.setState({
        id_maison_edition: null,
        maison_edition: { value: "", label: "" }
      });
    } else {
      this.setState({
        id_maison_edition: selected.value,
        maison_edition: { value: selected.value, label: selected.label }
      });
    }
  };

  handleChange = (event) => {
    /*to prevent from pasting characters on identifier field*/
    if (event.target.id === "identifiant") {
      let numbers = event.target.value.replace(/[^0-9]/g, "");
      this.setState({
        [event.target.id]: numbers
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
    event.target.setCustomValidity("");
  };

  onChangeDate = (date) => {
    this.setState({
      date_parution: date
    });
  };

  handleChoice = (event, name) => {
    this.setState({
      [name]: event
    });
  };

  async uploadFileToS3(filename) {
    let options = { position: "bottom-right" };
    try {
      return await HttpDataSource.S3Upload(
        filename,
        this.state.nom_fichier_body
      );
    } catch (e) {
      this.setState({ errorMessage: e, isLoading: false });
      cogoToast.error(<div aria-live="polite">{this.props.t(e)}</div>, options);
      return false;
    }
  }

  async removeS3Files(processedImages) {
    // removing upload images from S3
    processedImages.length > 0 &&
      processedImages.map(async (s3Image) => {
        await HttpDataSource.S3DeleteImage(
          s3Image.substring(s3Image.lastIndexOf("/") + 1)
        );
      });
    this.state.nom_fichier &&
      (await HttpDataSource.S3Delete(this.state.nom_fichier));
  }

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    let document = {};
    let errorMessage = "";
    let processedImages = "";
    let hyperlien_image = [];
    let isValide = true;
    let s3_stored_document = "";
    let options = { position: "bottom-right" };
    this.setState({ isLoading: true });

    try {
      if (
        this.state.identifiant.length < 10 ||
        this.state.identifiant.length > 13
      ) {
        window && window.scroll(0, 0);
        isValide = false;
        errorMessage = this.props.t(
          "Svp veuillez rentrer un identifiant d'un minimum 10 et maximun 13 chiffres"
        );

        this.setState({
          isLoading: false,
          errorMessage
        });
        cogoToast.error(<div aria-live="polite">{errorMessage}</div>, options);
        return false;
      }

      if (!this.state.nom_fichier_body) {
        isValide = false;
        errorMessage = this.props.t("Svp veuillez ajouter un fichier!");

        this.setState({
          isLoading: false,
          errorMessage
        });
        cogoToast.error(<div aria-live="polite">{errorMessage}</div>, options);
        return false;
      }

      if (isValide) {
        //upload doc to s3
        let extension = this.state.nom_fichier_body.type.split("/")[1];
        let filename = `${this.state.identifiant}.${extension}`;
        s3_stored_document = await this.uploadFileToS3(filename);

        let metadata = `${JSON.stringify({
          page_initiale: Number(this.state.page_initiale),
          page_finale: Number(this.state.page_finale),
          nombre_pages: Number(this.state.nombre_pages),
          editeur_public: this.state.editeur_public || null
        })}`;

        let isAuteurs = this.state.auteurs[0].nom ? true : false;

        if (this.state.hyperlien_image.length > 0) {
          //upload images to s3
          processedImages = await processImages(
            this.state.hyperlien_image,
            this.state.identifiant,
            this.state.identifiant
          );
          hyperlien_image = [...processedImages];
        }

        document = {
          values: {
            titre: this.state.titre,
            identifiant: this.state.identifiant,
            type_publication: this.state.type_publication,
            id_maison_edition: this.state.id_maison_edition, //api to be provided
            date_parution: this.state.date_parution
              ? dateConverter(this.state.date_parution)
              : null,
            lieu_parution: null,
            vendre_separe: this.state.vendre_separe,
            langue: this.state.langue,
            prix: this.state.prix,
            eligible_rabais_volume: false,
            eligible_autres_rabais: false,
            disponible: this.state.disponible,
            metadata,
            description: this.state.description,
            auteurs: isAuteurs ? JSON.stringify(this.state.auteurs) : null,
            nom_fichier: s3_stored_document.key,
            hyperlien_image,
            niveau: this.state.niveau
          },
          user: "admin@copibec"
        };

        const data = await HttpDataSource.postPublication(document);

        if (data) {
          cogoToast.success(
            <div aria-live="polite">
              {this.props.t("global_formsubmit_ajout_produit")}
            </div>,
            options
          );
          setTimeout(() => this.props.history.push("/produits"), 2300);
        }
      }
    } catch (error) {
      let message = "";

      if (error.message) {
        this.setErrorMessage(error.message);
        return false;
      }

      if (error.response.status === 409) {
        message = "ISBN already exists, please enter a new one";
        this.identifiant.focus();
        // removing upload images from S3
        this.removeS3Files(processedImages);
        this.setErrorMessage(message);
      } else {
        message = error.response.data.error;
        // removing upload images from S3
        this.removeS3Files(processedImages);
        this.setErrorMessage(message);
      }
    }
  };

  setErrorMessage = (message) => {
    let options = { position: "bottom-right" };
    this.setState({ errorMessage: this.props.t(message), isLoading: false });
    cogoToast.error(
      <div aria-live="polite">{this.props.t(message)}</div>,
      options
    );
  };

  handleChangeAuteurs = (event) => {
    const cpauteurs = [...this.state.auteurs];
    const inputName = event.target.id.split("-")[0];
    const inputIndex = parseInt(event.target.id.split("-")[1]);
    const value = event.target.value;
    cpauteurs[inputIndex][inputName] = value;
    this.setState({ auteurs: cpauteurs });
  };

  addAuteurs = (e) => {
    e.preventDefault();
    const defaultValue = {
      prenom: "",
      nom: "",
      pseudonyme: ""
    };

    const newAuteurs = [...this.state.auteurs, defaultValue];
    this.setState({ auteurs: newAuteurs });
  };

  removeAuteurs = (e, idx) => {
    e.preventDefault();
    const cpauteurs = [...this.state.auteurs];
    cpauteurs.splice(idx, 1);
    this.setState({ auteurs: cpauteurs });
    document.getElementById("add").focus();
  };

  addDocuments = (e) => {
    if (e.target.files[0].name.split(".")[1] !== "pdf") {
      let options = { position: "bottom-right" };
      let message = "Veuillez ajouter un fichier pdf valide!";
      this.setState({ errorMessage: this.props.t(message), isLoading: false });
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
    } else {
      const nom_fichier = e.target.files[0].name;
      const nom_fichier_body = e.target.files[0];
      this.setState({ nom_fichier, nom_fichier_body });
    }
  };

  addLien_fichier_image = (e) => {
    let cover_images = [];
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      let images = { content: files[i], isNew: true };
      cover_images.push(images);
    }

    const hyperlien_image = [...this.state.hyperlien_image, ...cover_images];

    this.setState({
      hyperlien_image,
      hyperlien_image_body: ""
    });
  };

  removeCard = (idx) => {
    const cp_hyperlien_image = [...this.state.hyperlien_image];
    cp_hyperlien_image.splice(idx, 1);
    this.setState({ hyperlien_image: cp_hyperlien_image });
  };

  onChangeContent = (items) => {
    this.setState({ hyperlien_image: items });
  };

  InvalidMsg(t, input) {
    var validityState_object = input.target.validity;
    if (validityState_object.valueMissing) {
      input.target.setCustomValidity(t("Veuillez remplir ce champ svp"));
    }
  }

  render() {
    const { t } = this.props;
    let formatedNomFichier = [];
    this.state.nom_fichier &&
      formatedNomFichier.push(<li key={0}>{this.state.nom_fichier}</li>);
    return (
      <>
        <Helmet>
          <title>{t("Déposer un produit")}</title>
          <meta name="description" content={t("Télécharger un document")} />
        </Helmet>
        <div className="bg-white rounded-lg mb-5">
          <h1
            className="text-white justify-between flex-wrap-6 bg-donaPurple py-2 px-2 border border-donaPurple rounded"
            style={{
              backgroundColor: "#84277E"
            }}>
            {t("Déposer un produit")}
          </h1>

          <form
            onSubmit={this.handleConfirmationSubmit}
            onInvalid={(e) => this.InvalidMsg(t, e)}
            className="xl:w-3/5 lg:w-4/5 w-11/12 pt-3 m-0 mb-5 mx-auto">
            {this.state.errorMessage.length > 0 && (
              <MessageBlock
                alertMessage={this.state.errorMessage}
                className="border px-4 py-3 rounded mt-6 mb-2"
                roleType="alert"
              />
            )}
            <div className="text-left">
              <strong className="text-sm text-donaPurple">
                {t(
                  "Tous les champs marqués d'un astérisque (*) sont obligatoires."
                )}
              </strong>
            </div>
            {this.state.successMessage && (
              <MessageBlock
                alertMessage={this.state.successMessage}
                className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-2"
                roleType="success"
              />
            )}
            <fieldset className="mt-3 border border-donaGray px-10 py-6 mb-5 shadow-lg">
              <legend>{t("Information")}</legend>
              <label className="donaFormFieldLabel" htmlFor="identifiant">
                {t("Identifiant")} *
              </label>
              <input
                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                id="identifiant"
                name="isbn"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={this.handleChange}
                value={this.state.identifiant}
                required
                autoFocus
                ref={(input) => {
                  this.identifiant = input;
                }}
              />
              <small>Format:1234567897 min:10 max:13 {t("chiffres")}</small>
              <label className="donaFormFieldLabel" htmlFor="titre">
                {t("Titre du produit")} *
              </label>
              <input
                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                id="titre"
                name="title"
                onChange={this.handleChange}
                value={this.state.titre}
                required
              />
              <label className="donaFormFieldLabel" htmlFor="type_publication">
                {t("Type d'œuvre")} *
              </label>
              <select
                className="w-full h-8 bg-donaGray donaButton"
                name="type"
                id="type_publication"
                value={this.state.type_publication}
                onChange={this.handleChange}
                required>
                <option value="">{t("Faire un sélection")}</option>
                <option value="aide-mémoire">{t("Aide-mémoire")}</option>
                <option value="cahier">{t("Cahier")}</option>
                <option value="carnet">{t("Carnet")}</option>
                <option value="code grammatical">
                  {t("Code grammatical")}
                </option>
                <option value="essai">{t("Essai")}</option>
                <option value="fascicule">{t("Fascicule")}</option>
                <option value="guide de stage">{t("Guide de stage")}</option>
                <option value="ligne du temps">{t("Ligne du temps")}</option>
                <option value="manuel">{t("Manuel")}</option>
                <option value="méthode de soins">
                  {t("Méthode de soins")}
                </option>
                <option value="recueil de solutions">
                  {t("Recueil de solutions")}
                </option>
                <option value="recueil de textes">
                  {t("Recueil de textes")}
                </option>
                <option value="roman">{t("Roman")}</option>
              </select>
              <label className="donaFormFieldLabel" htmlFor="niveau">
                {t("Niveau")} *
              </label>
              <select
                className="w-full h-8 bg-donaGray donaButton"
                id="niveau"
                name="level"
                value={this.state.niveau}
                onChange={this.handleChange}>
                <option value="primaire/secondaire">
                  {t("Primaire/Secondaire")}
                </option>
                <option value="collégial/universitaire">
                  {t("Collégial/Universitaire")}
                </option>
              </select>
              <label className="donaFormFieldLabel" htmlFor="langue">
                {t("Langue")}
              </label>
              <select
                className="w-full h-8 bg-donaGray donaButton"
                id="langue"
                name="language"
                onChange={this.handleChange}
                value={this.state.langue}>
                <option value="fr">{t("Français")}</option>
                <option value="en">{t("Anglais")}</option>
                <option value="es">{t("Espagnol")}</option>
              </select>
              {this.state.type_publication === "article" && (
                <>
                  <label className="donaFormFieldLabel" htmlFor="nombre_pages">
                    {t("Nombre de pages")}
                  </label>
                  <input
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="nombre_pages"
                    type="number"
                    name="pageCount"
                    onChange={this.handleChange}
                    value={this.state.nombre_pages}
                    required
                  />

                  <label className="donaFormFieldLabel" htmlFor="page_initiale">
                    {t("Page initial")}
                  </label>
                  <input
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="page_initiale"
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.page_initiale}
                    required
                  />

                  <label className="donaFormFieldLabel" htmlFor="page_finale">
                    {t("Page finale")}
                  </label>
                  <input
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="page_finale"
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.page_finale}
                    required
                  />
                </>
              )}
              <label
                className="donaFormFieldLabel"
                htmlFor="documentDescription">
                {t("Description")}
              </label>
              <textarea
                rows="10"
                cols="5"
                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                id="description"
                name="description"
                type="text"
                onChange={this.handleChange}
                value={this.state.description}
                style={{ height: "6rem" }}></textarea>
            </fieldset>

            <fieldset className="border border-donaGray px-10 py-6 pb-6 mb-5 shadow-lg">
              <legend>{t("Auteur(s)")}</legend>
              {this.state.auteurs.map((element, idx) => {
                return (
                  <fieldset
                    key={`fieldset-${idx}`}
                    className="bg-donaGray border-2 border-white p-2 mb-6">
                    <legend className="bg-white p-1">
                      {t("Auteur")} - {idx + 1}
                    </legend>
                    <div className="flex flex-wrap" key={idx}>
                      <div className="md:w-3/12 w-full mr-3">
                        <label
                          className="donaFormFieldLabel"
                          htmlFor={`prenom-${idx}`}>
                          {t("Prénom")}
                        </label>
                        <input
                          className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                          id={`prenom-${idx}`}
                          name="given-name"
                          type="text"
                          value={element.prenom}
                          onChange={this.handleChangeAuteurs}
                        />
                      </div>
                      <div className="md:w-3/12 w-full  mr-3">
                        <label
                          className="donaFormFieldLabel"
                          htmlFor={`nom-${idx}`}>
                          {t("Nom")}
                        </label>
                        <input
                          className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                          id={`nom-${idx}`}
                          name="family-name"
                          type="text"
                          value={element.nom}
                          onChange={this.handleChangeAuteurs}
                        />
                      </div>
                      <div className="md:w-3/12 w-full  mr-3">
                        <label
                          className="donaFormFieldLabel whitespace-no-wrap"
                          htmlFor={`pseudonyme-${idx}`}>
                          {t("Pseudonyme")}
                        </label>
                        <input
                          className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                          id={`pseudonyme-${idx}`}
                          name="pseudonyme"
                          type="text"
                          value={element.pseudonyme}
                          onChange={this.handleChangeAuteurs}
                        />
                      </div>

                      <div className="w-2/12 mt-6">
                        {idx === 0 && (
                          <>
                            <button
                              type="button"
                              aria-label="add"
                              id="add"
                              onClick={this.addAuteurs}>
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                size="2x"
                                color="#348280"
                                className="cursor-pointer "
                              />
                            </button>
                            {this.state.auteurs.length > 1 && (
                              <button
                                type="button"
                                aria-label="remove"
                                id="remove"
                                onClick={(e) => this.removeAuteurs(e, idx)}>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className="cursor-pointer"
                                  size="2x"
                                  color="#84277E"
                                />
                              </button>
                            )}
                          </>
                        )}
                        {idx > 0 && (
                          <>
                            <button alt="add" id="add">
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                size="2x"
                                color="#348280"
                                className="cursor-pointer"
                                onClick={this.addAuteurs}
                              />
                            </button>
                            <button alt="remove" id="remove">
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="cursor-pointer"
                                size="2x"
                                color="#84277E"
                                onClick={(e) => this.removeAuteurs(e, idx)}
                              />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </fieldset>
                );
              })}
            </fieldset>

            <fieldset className="border border-donaGray px-10 py-6 mb-5 shadow-lg">
              <legend>{t("Informations de l'éditeur")}</legend>
              <label
                className="donaFormFieldLabel"
                htmlFor="react-select-2-input">
                {t("Éditeur")}*
              </label>
              <AutoCompleteSelect
                placeholder={t("Filtrer Éditeur")}
                data={this.state.maisons_editions}
                name="id_maison_edition"
                fields={
                  this.props.userGroup === "superadmin"
                    ? { value: "id", label: "nom_maison_edition" }
                    : { value: "id_organisation", label: "nom" }
                }
                initValue={this.state.maison_edition}
                onChange={this.setSelectValue}
                required={true}
              />

              <label
                className="donaFormFieldLabel mt-3"
                htmlFor="editeur_public">
                {t("Nom d'éditeur affiché dans le catalogue DONA")}
              </label>
              <input
                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                id="editeur_public"
                name="organization"
                type="text"
                onChange={this.handleChange}
                value={this.state.editeur_public}
              />

              <label
                className="donaFormFieldLabel mt-3"
                htmlFor="date_parution">
                {t("Année de publication")}
              </label>
              <DatePicker
                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                id="date_parution"
                type="date"
                // onChange={this.handleChange}
                value={this.state.date_parution}
                selected={this.state.date_parution}
                onChange={this.onChangeDate}
                dateFormat="yyyy"
                placeholderText={t("aaaa")}
                isClearable
                showYearPicker
              />
            </fieldset>

            <fieldset className="border border-donaGray px-10 py-6 mb-5 shadow-lg">
              <legend>{t("Préférences de vente")}</legend>
              <label className="donaFormFieldLabel" htmlFor="disponible">
                {t("Disponible sur Dona")}*
              </label>
              <div className="md:flex md:items-center mb-3">
                <div className="md">
                  <Switch
                    onChange={(e) => this.handleChoice(e, "disponible")}
                    checked={this.state.disponible}
                    className="react-switch"
                    id="disponible"
                  />
                </div>
                <div className="md ml-2">
                  {this.state.disponible ? t("Oui") : t("Non")}.
                </div>
              </div>
              <label className="donaFormFieldLabel" htmlFor="vendre_separe">
                {t("Vente individuelle")}*
              </label>
              <div className="md:flex md:items-center mb-3">
                <div className="md">
                  <Switch
                    onChange={(e) => this.handleChoice(e, "vendre_separe")}
                    checked={this.state.vendre_separe}
                    className="react-switch"
                    id="vendre_separe"
                  />
                </div>
                <div className="md ml-2">
                  {this.state.vendre_separe ? t("Oui") : t("Non")}.
                </div>
              </div>
              <label htmlFor="prix" className="donaFormFieldLabel">
                {t("Prix de la version papier")}*
              </label>
              <input
                className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                id="prix"
                name="price"
                type="number"
                onChange={this.handleChange}
                value={this.state.prix}
                placeholder="$"
                step=".01"
                required
              />
            </fieldset>

            <fieldset
              className="border border-donaGray md:px-10 px-5 pt-2 pb-6 mb-5 shadow-lg"
              style={{ textAlign: "-webkit-center" }}>
              <legend>
                {t("Fichier numérique du produit")}
                <button
                  type="button"
                  onClick={() => {
                    let isVisible = document.getElementById("fileSpec").style
                      .display;
                    if (isVisible === "none") {
                      document.getElementById("fileSpec").style.display =
                        "block";
                    } else {
                      document.getElementById("fileSpec").style.display =
                        "none";
                    }
                  }}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#348280"
                    size="1x"
                    className="cursor-pointer mx-2"
                  />
                </button>
              </legend>
              <p
                id="fileSpec"
                style={{
                  display: "none"
                }}
                className="bg-donaGray text-xs italic text-black p-4 rounded mb-4 text-left mx-auto">
                Type de fichier: PDF seulement
                <br />
                Taille maximale du fichier: 250 Mo
              </p>
              <ul className="mb-2">{formatedNomFichier}</ul>
              <label
                htmlFor="nom_fichier"
                className="rounded-full button-color--invertTurquoisepale md:px-12 px-2  py-1 text-center">
                {formatedNomFichier.length > 0
                  ? t("Remplacer le fichier")
                  : t("Ajouter un fichier")}
                *
              </label>
              <input
                id="nom_fichier"
                type="file"
                className="opacity-0 w-0"
                accept=".pdf"
                onChange={this.addDocuments}
              />
            </fieldset>

            <fieldset
              className="border border-donaGray md:px-10 px-5 pt-4 pb-6 mb-5 shadow-lg"
              style={{ textAlign: "-webkit-center" }}>
              <legend>
                {t("Image(s) couverture(s)")}
                <button
                  type="button"
                  onClick={() => {
                    let isVisible = document.getElementById("imageSpec").style
                      .display;
                    if (isVisible === "none") {
                      document.getElementById("imageSpec").style.display =
                        "block";
                    } else {
                      document.getElementById("imageSpec").style.display =
                        "none";
                    }
                  }}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#348280"
                    size="1x"
                    className="cursor-pointer mx-2"
                  />
                </button>
              </legend>
              <p
                id="imageSpec"
                style={{
                  display: "none"
                }}
                className="bg-donaGray text-xs italic text-black p-4 rounded text-left mx-auto">
                Taille (recommandée): 2560px x 1600px (pour le mode portrait)
                <br />
                Type de fichier: JPG, PNG
                <br />
                Résolution: 72 PPP
                <br />
                Taille maximale du fichier: 2 Mo
              </p>
              <DraggableCards
                items={this.state.hyperlien_image}
                onChange={this.onChangeContent}
                removeCard={this.removeCard}
                className="mb-4"
              />
              <div className="mt-6">
                <label
                  htmlFor="hyperlien_image"
                  className="rounded-full button-color--invertTurquoisepale md:px-6 px-2  py-1 text-center w-10">
                  {t("Ajouter une(des) image(s)")}
                </label>
              </div>

              <input
                value={this.state.hyperlien_image_body}
                type="file"
                id="hyperlien_image"
                multiple
                className="opacity-0 w-0"
                accept=".png, .jpg, .jpeg, .gif"
                onChange={this.addLien_fichier_image}
              />
            </fieldset>

            <div className="flex justify-center my-10">
              <Button
                type="submit"
                className={
                  this.validateConfirmationForm()
                    ? "donaButton button-color--purple w-64"
                    : "donaButton bg-donaGray w-64"
                }
                disabled={this.state.isLoading}>
                {this.state.isLoading ? (
                  <FontAwesomeIcon icon="spinner" spin />
                ) : (
                  t("Sauvegarder")
                )}
              </Button>
              <Button
                type="submit"
                className="ml-5 donaButton button-color--invertMauve w-64"
                disabled={this.state.isLoading}
                onClick={() => {
                  this.props.history.push("/produits");
                }}>
                {t("Annuler")}
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
