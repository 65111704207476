import React from "react";
import { Helmet } from "react-helmet";

export const TermsAndConditions = (props) => {
  window && window.scroll(0, 0);
  const { t } = props;

  return (
    <>
      <Helmet>
        <title>{t("local_terms_title")}</title>
        <meta name="description" content={t("local_terms_title")} />
      </Helmet>
      <div className="w-full mx-auto leading-10 px-0 text-black">
        <h1
          className="text-white justify-between flex-wrap-6 bg-donaPurple px-2 border border-donaPurple rounded uppercase"
          style={{
            backgroundColor: "#84277E"
          }}>
          {t("local_terms_title")}
        </h1>
        <div className="md:p-10">
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              1. {t("local_terms_subtitle_propriete")}
            </h2>

            {t("local_terms_content_propriete")}
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              2. {t("local_terms_subtitle_surveillance")}
            </h2>

            {t("local_terms_content_surveillance")}
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              3. {t("local_terms_subtitle_restriction")}
            </h2>
            <ol type="i">
              <li className="mb-2">{t("local_terms_content_restriction")}</li>
              <li className="mb-2">
                (i) {t("local_terms_subcontent_restriction_1")}
              </li>
              <li className="mb-2">
                (ii) {t("local_terms_subcontent_restriction_2")}
              </li>
              <li className="mb-2">
                {" "}
                (iii) {t("local_terms_subcontent_restriction_3")}
              </li>
              <li className="mb-2">
                (iv) {t("local_terms_subcontent_restriction_4")}
              </li>
            </ol>
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              4. {t("local_terms_subtitle_vieprivee")}
            </h2>
            {t("local_terms_content_vieprivee_1_1")}
            <a
              href={t("local_terms_url_vieprivee_pdc")}
              target="_blank"
              rel="noopener noreferrer"
              className="underline font-semibold text-donaPurple hover:text-donaBlue">
              {t("local_terms_link_vieprivee_pdc")}
            </a>
            {t("local_terms_content_vieprivee_1_2")}
          </div>
          <div className="text-justify text-lg mb-5">
            {t("local_terms_content_vieprivee_2")}
          </div>
          <div className="text-justify text-lg mb-5">
            {t("local_terms_content_vieprivee_3_1")}
            <a
              href={t("local_terms_url_vieprivee_pdc")}
              target="_blank"
              rel="noopener noreferrer"
              className="underline font-semibold text-donaPurple hover:text-donaBlue">
              {t("local_terms_link_vieprivee_pdc")}
            </a>
            {t("local_terms_content_vieprivee_3_2")}
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              5. {t("local_terms_subtitle_courriel")}
            </h2>

            {t("local_terms_content_courriel")}
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              6. {t("local_terms_subtitle_hyperliens")}
            </h2>
            <div className="text-justify text-lg mb-5">
              {t("local_terms_content_hyperliens_1")}
            </div>
            <div className="text-justify text-lg mb-5">
              {t("local_terms_content_hyperliens_2")}
            </div>
            <div className="text-justify text-lg mb-5">
              {t("local_terms_content_hyperliens_3")}
            </div>
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              7. {t("local_terms_subtitle_garantie")}
            </h2>
            {t("local_terms_content_garantie")}
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              8. {t("local_terms_subtitle_exclusion")}
            </h2>
            <ol type="i">
              <li className="mb-2">{t("local_terms_content_exclusion_1")}</li>
              <li className="mb-2">
                i) {t("local_terms_subcontent_exclusion_1")}
              </li>
              <li className="mb-2">
                ii) {t("local_terms_subcontent_exclusion_2")}
              </li>
              <li className="mb-2">
                iii) {t("local_terms_subcontent_exclusion_3")}
              </li>
              <li className="mb-2">
                iv) {t("local_terms_subcontent_exclusion_4")}
              </li>
              <li className="mb-2">
                v) {t("local_terms_subcontent_exclusion_5")}
              </li>
            </ol>
            <div className="text-justify text-lg mb-5">
              {t("local_terms_content_exclusion_2")}
            </div>
            <div className="text-justify text-lg mb-5">
              {t("local_terms_content_exclusion_3")}
            </div>
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              9. {t("local_terms_subtitle_indemnisation")}
            </h2>
            {t("local_terms_content_indemnisation")}

            <ol type="i">
              <li className="mb-2">
                (i) {t("local_terms_subcontent_indemnisation_1")}
              </li>
              <li className="mb-2">
                (ii) {t("local_terms_subcontent_indemnisation_2")}
              </li>
            </ol>
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">10. {t("local_terms_subtitle_loi")}</h2>

            {t("local_terms_content_loi")}
          </div>
          <div className="text-justify text-lg mb-5">
            <h2 className="font-bold">
              11. {t("local_terms_subtitle_modifications")}
            </h2>
            <ol type="i">
              <li className="mb-2">
                (i) {t("local_terms_subcontent_modifications_1_1")} <br />{" "}
                {t("local_terms_subcontent_modifications_1_2")}
              </li>
              <li className="mb-2">
                (ii) {t("local_terms_subcontent_modifications_2")}
              </li>
            </ol>
            <div className="text-justify text-lg mt-12">
              {t("local_terms_note_maj")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
