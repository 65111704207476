import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logout from "../containers/Logout";
import logodona from "../images/logodona.png";
import Button from "./Button";
import "../styles/leftNav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../config";
import {
  faFileUpload,
  faSignOutAlt,
  faBookOpen,
  faCog,
  faObjectGroup,
  faInfoCircle,
  faFileInvoice,
  faHistory,
  faShoppingBasket,
  faUsers,
  faBoxes,
  faFileSignature
} from "@fortawesome/free-solid-svg-icons";
import { faFile, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faFile,
  faFileUpload,
  faQuestionCircle,
  faBookOpen,
  faSignOutAlt,
  faCog,
  faObjectGroup,
  faInfoCircle,
  faFileInvoice,
  faHistory,
  faShoppingBasket,
  faUsers,
  faBoxes,
  faFileSignature
);

const LeftNav = (props) => {
  const userGroup = props.userGroup || window.localStorage.getItem("userGroup");

  const getGroupFaqUrlLangue = (group) => {
    let faqUrl = "";
    let language = props.i18n.language;

    if (language && language === "fr") {
      if (group === "superadmin") {
        faqUrl = config.encryptUtilsApi.FAQ_SUPER_ADMIN_FR;
      } else if (group === "admin") {
        faqUrl = config.encryptUtilsApi.FAQ_ADMIN_FR;
      } else {
        faqUrl = config.encryptUtilsApi.FAQ_APPROUVER_FR;
      }
    }
    if (language && language === "en") {
      if (group === "superadmin") {
        faqUrl = config.encryptUtilsApi.FAQ_SUPER_ADMIN_EN;
      } else if (group === "admin") {
        faqUrl = config.encryptUtilsApi.FAQ_ADMIN_EN;
      } else {
        faqUrl = config.encryptUtilsApi.FAQ_APPROUVER_EN;
      }
    }

    return faqUrl;
  };

  const navItems = [
    {
      name: "global_leftnav_tableaudebord",
      url: "/tableau-de-bord",
      icon: faObjectGroup,
      groups: ["superadmin", "admin", "approver"],
      type: "internal"
    },
    {
      name: "global_leftnav_produits",
      url: "/produits",
      icon: faFile,
      groups: ["superadmin", "admin"],
      type: "internal"
    },
    {
      name: "global_leftnav_combos",
      url: "/offres-groupes",
      icon: faUsers,
      groups: ["superadmin", "admin"],
      type: "internal"
    },
    {
      name: "global_leftnav_demandes",
      url: "/demandes",
      icon: faFileInvoice,
      groups: ["superadmin", "admin"],
      type: "internal"
    },
    {
      name: "global_leftnav_historique",
      url: "/historique-achats-produits",
      icon: faBoxes,
      groups: ["superadmin", "admin"],
      type: "internal"
    },
    {
      name: "global_leftnav_bonsdecommande",
      url: "/bons-de-commande",
      icon: faShoppingBasket,
      groups: ["superadmin", "approver"],
      type: "internal"
    },
    {
      name: "global_leftnav_achatsreps",
      url: "/historique-achats-representants",
      icon: faHistory,
      groups: ["superadmin", "approver"],
      type: "internal"
    },
    {
      name: "global_leftnav_catalogue",
      url: "https://copibecdona.ca/",
      icon: faBookOpen,
      groups: ["superadmin", "admin", "approver"],
      type: "external"
    },
    {
      name: "global_leftnav_aide",
      url: "/aide",
      icon: faQuestionCircle,
      groups: ["superadmin", "admin", "approver"],
      type: "internal"
    },
    {
      name: "global_leftnav_conditions",
      url: "/conditions-d-acces",
      icon: faFileSignature,
      groups: ["superadmin", "admin", "approver"],
      type: "internal"
    },
    {
      name: "global_leftnav_faq",
      url: getGroupFaqUrlLangue(userGroup),
      icon: faInfoCircle,
      groups: ["superadmin", "admin", "approver"],
      type: "external"
    },
    {
      name: "global_leftnav_params",
      url: "/parametres",
      icon: faCog,
      groups: ["superadmin", "admin", "approver"]
    },
    {
      name: "global_leftnav_session",
      icon: faSignOutAlt
    }
  ];

  const { t } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  });

  const handleKeyDownLogout = (e) => {
    if (e.key === "Enter") {
      setModalOpen(!isModalOpen);
    }
  };

  const handleLogout = (e) => {
    setModalOpen(!isModalOpen);
  };

  const links = [];
  navItems.forEach((item, index) => {
    item.icon !== faSignOutAlt && item.groups.includes(props.userGroup)
      ? item.type === "external"
        ? links.push(
            <li className="leftNav-list-item" key={index}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <div>
                  <FontAwesomeIcon
                    icon={item.icon}
                    size="2x"
                    className="fa-fw my-auto"
                  />
                </div>
                <span className="my-auto"> {t(item.name)}</span>
              </a>
            </li>
          )
        : links.push(
            <li className="leftNav-list-item" key={index}>
              <NavLink
                to={item.url}
                activeClassName="leftNav-list-item-active"
                isActive={(match, location) => {
                  let url = props.location.pathname.split("/")[1];
                  if (
                    url === "offres-groupes-details" &&
                    item.name === t("global_leftnav_combos")
                  ) {
                    return true;
                  } else if (
                    url === "produits-details" &&
                    item.name === t("global_leftnav_produits")
                  ) {
                    return true;
                  } else if (
                    url === "demandes-details" &&
                    item.name === t("global_leftnav_demandes")
                  ) {
                    return true;
                  } else if (
                    url === "historique-achats-produits-details" &&
                    item.name === t("global_leftnav_historique")
                  ) {
                    return true;
                  } else if (
                    url === "commande-details" &&
                    item.name === t("global_leftnav_bonsdecommande")
                  ) {
                    return true;
                  } else if (
                    url === "produits-deposer" &&
                    item.name === t("global_leftnav_produits")
                  ) {
                    return true;
                  } else if (
                    url === "historique-achats-representants-details" &&
                    item.name === t("global_leftnav_achatsreps")
                  ) {
                    return true;
                  } else if (location.pathname === item.url) {
                    return true;
                  }
                }}>
                <div>
                  <FontAwesomeIcon
                    icon={item.icon}
                    size="2x"
                    className="fa-fw my-auto"
                  />
                </div>
                <span className="my-auto"> {t(item.name)}</span>
              </NavLink>
            </li>
          )
      : item.icon === faSignOutAlt &&
        links.push(
          <li
            id="logout"
            className="logout p-3 cursor-pointer flex"
            key={index}
            tabIndex="0"
            onKeyDown={handleKeyDownLogout}
            onClick={handleLogout}>
            <div>
              <FontAwesomeIcon icon={item.icon} size="2x" className="fa-fw" />
            </div>
            <Button
              onClick={handleLogout}
              tabIndex="-1"
              className="my-auto ml-2">
              {t(item.name)}
            </Button>
          </li>
        );
  });

  if (userGroup === "superadmin") {
    links.splice(
      7,
      0,
      <li key="li">
        <hr key="hr" className="border border-donaGray m-0 p-0" />
      </li>
    );
  } else if (userGroup === "admin") {
    links.splice(
      5,
      0,
      <li key="li">
        <hr key="hr" className="border border-donaGray m-0 p-0" />
      </li>
    );
  } else {
    links.splice(
      3,
      0,
      <li key="li">
        <hr key="hr" className="border border-donaGray m-0 p-0" />
      </li>
    );
  }

  return (
    <>
      {700 < screenWidth ? (
        <nav className="leftNav">
          <div className="leftNav-header">
            <div className="leftNav-header-logo-block">
              <img
                className="mx-auto"
                width={120}
                height={100}
                src={logodona}
                alt="Logo Dona"
              />
            </div>
          </div>
          <ul className="leftNav-list fa-ul">{links}</ul>
        </nav>
      ) : (
        <nav className="">
          <div className="flex justify-between flex-wrap">
            <div className="">
              <img width={120} height={100} src={logodona} alt="Logo Dona" />
            </div>
            <div className="">
              <div className="block lg:hidden">
                <button
                  className="px-3 py-2 border rounded hamburger border-donaTurquoise hover:border-white"
                  id="hamburger"
                  onClick={() => setMenuOpen(!isMenuOpen)}>
                  <svg
                    className="h-6 w-6 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    {!isMenuOpen ? (
                      <path
                        fillRule="evenodd"
                        d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
                      />
                    ) : (
                      <path
                        fillRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    )}
                  </svg>
                </button>
                <div className="hamburger_x">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "6px",
              display: isMenuOpen ? "block" : "none"
            }}>
            <ul>{links}</ul>
          </div>
        </nav>
      )}
      {isModalOpen && (
        <Logout
          isModalOpen={isModalOpen}
          setModalOpen={() => setModalOpen()}
          icon={faSignOutAlt}
          {...props}
        />
      )}
    </>
  );
};

export default LeftNav;
