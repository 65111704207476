import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

class AutoCompleteSelect extends React.Component {
  state = {
    data: [],
    selectedOption: null,
    defaultMenuIsOpen: false,
    hiddenInput: "",
    isDisabled: false
  };

  selectRef = null;
  setSelectRef = (ref) => {
    this.selectRef = ref;
  };

  componentDidMount() {
    const data =
      this.props.data.length > 0 &&
      this.props.data.map((suggestion) => {
        return {
          value: suggestion[this.props.fields.value],
          label: suggestion[this.props.fields.label]
        };
      });

    this.setState({
      data,
      selectedOption: this.props.initValue,
      hiddenInput: this.props.initValue.value,
      isDisabled: this.props.isDisabled
    }); // eslint-disable-line react/no-did-mount-set-state
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const data = this.props.data.map((suggestion) => {
        return {
          value: suggestion[this.props.fields.value],
          label: suggestion[this.props.fields.label]
        };
      });

      this.setState({ data }); // eslint-disable-line react/no-did-update-set-state
    }
    if (this.props.initValue.value !== prevProps.initValue.value) {
      this.setState({
        selectedOption: this.props.initValue,
        hiddenInput: this.props.initValue.value
      });
    }

    if (this.props.isDisabled !== prevProps.isDisabled) {
      this.setState({
        isDisabled: this.props.isDisabled
      });
    }
  }

  handleChange = (selectedOption) => {
    if (selectedOption === null) {
      this.setState({
        selectedOption,
        defaultMenuIsOpen: false,
        hiddenInput: ""
      });
      this.props.onChange("");
    } else {
      this.setState({
        selectedOption,
        defaultMenuIsOpen: true,
        hiddenInput: selectedOption.value
      });
      this.props.onChange(selectedOption);
    }
    this.hiddenInput.setCustomValidity("");
  };

  getValue = () => {
    if (!this.state.selectedOption) {
    } else if (this.state.selectedOption.value) {
      return this.state.selectedOption.value;
    }
  };

  noop = () => {
    // no operation (do nothing real quick)
  };

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        border: "1px solid black",
        color: "black"
      }),
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px solid #eaebec",
        color: state.isSelected ? "#203A53" : "black"
      }),
      control: (styles) => ({
        ...styles,
        borderRadius: "black",
        border: "1px solid black"
      }),
      container: (styles, state) => ({
        ...styles,
        borderRadius: 0
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        borderRadius: "0"
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: "black"
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        border: "1px solid #eaebec"
      }),
      placeholder: (styles) => ({ ...styles, color: "black" }),
      noOptionsMessage: (styles) => ({
        ...styles,
        color: "black"
      })
    };

    return (
      <>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <Select
          isDisabled={this.state.isDisabled}
          ref={(e) => this.setSelectRef(e)}
          name={this.props.name}
          isSearchable
          isClearable
          defaultMenuIsOpen={this.state.defaultMenuIsOpen}
          value={this.state.selectedOption}
          onChange={this.handleChange}
          options={this.state.data}
          placeholder={this.props.placeholder}
          styles={customStyles}
        />
        <input
          name="hiddenInput"
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "1px",
            height: "3px",
            position: "absolute"
          }}
          ref={(input) => {
            this.hiddenInput = input;
          }}
          value={this.state.hiddenInput}
          onChange={this.noop}
          onFocus={() => this.selectRef.focus()}
          required={this.props.required}
        />
      </>
    );
  }
}

AutoCompleteSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  fields: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool
};

AutoCompleteSelect.defaultProps = {
  isDisabled: false
};

export default AutoCompleteSelect;
