import Auth from "@aws-amplify/auth";
import React, { useState, useEffect } from "react";
import i18n from "../i18n";
import { Helmet } from "react-helmet";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

library.add(faEdit);

export const Settings = (props) => {
  const { t } = props;
  const [language, setLanguage] = useState(document.documentElement.lang);
  const [fontStatus, setStatus] = useState(true);
  const [currentFont, setCurrentFont] = useState(
    window.localStorage.getItem("fonts") || "Lato, sans-serif"
  );
  const [previousFont, setPreviousFont] = useState(
    window.localStorage.getItem("fonts") || "Lato, sans-serif"
  );

  useEffect(() => {
    document.querySelector("body").style.fontFamily =
      window.localStorage.getItem("fonts") || "Lato, sans-serif";
    setLanguage(window.localStorage.getItem("i18nextLng") || "fr");
    setCurrentFont(window.localStorage.getItem("fonts") || "Lato, sans-serif");
  }, []);

  window && window.scroll(0, 0);

  //Language

  const saveLanguage = async (language) => {
    let user = await Auth.currentAuthenticatedUser();
    let attributes = {
      "custom:preferred_language": language
    };
    window.localStorage.setItem("userLanguage", language);
    await Auth.updateUserAttributes(user, attributes);
    i18n.changeLanguage(language);
    document.documentElement.lang = language;
  };

  //Fonts

  const handleFontChange = (font) => {
    setCurrentFont(font);
    setStatus(false);
  };

  const saveFont = (font) => {
    setStatus(true);
    document.querySelector("body").style.fontFamily = font;
    window.localStorage.setItem("fonts", font);
  };

  const toggleLanguage = () => {
    let btn = document.getElementById("btn_lang");
    let expanded = btn.getAttribute("aria-expanded") === "true" || false;
    btn.setAttribute("aria-expanded", !expanded);

    let isVisible = document.getElementById("languageSection").style.display;

    if (isVisible === "none") {
      document.getElementById("languageSection").style.display = "block";
    } else {
      document.getElementById("languageSection").style.display = "none";
    }
  };

  const toggleFont = () => {
    let btn = document.getElementById("btn_font");
    let expanded = btn.getAttribute("aria-expanded") === "true" || false;
    btn.setAttribute("aria-expanded", !expanded);

    let isVisible = document.getElementById("fontSection").style.display;
    if (isVisible === "none") {
      document.getElementById("fontSection").style.display = "block";
    } else {
      document.getElementById("fontSection").style.display = "none";
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("Paramètres de Configuration")}</title>
        <meta name="description" content={t("Paramètres de Configurantion")} />
      </Helmet>
      <div>
        <div className="flex justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded mb-4">
          <h1 className="text-white font-bold mt-1">
            {t("Paramètres de la plateforme d’administration DONA")}
          </h1>
        </div>
        <div className="lg:w-1/2 md:w-11/12 w-full border border-donaGray p-4 my-3 lg:mx-1 mx-auto shadow-lg">
          <div className="flex">
            <label htmlFor="language" className="donaFormFieldLabel">
              {t("Langue")}
            </label>
            <button
              id="btn_lang"
              aria-expanded="false"
              onClick={toggleLanguage}>
              <FontAwesomeIcon
                icon={faEdit}
                size="1x"
                color="#348280"
                className="cursor-pointer mb-auto"
              />
            </button>
          </div>
          <p id="info_langue" className="text-xs italic text-black text-left">
            {t(
              "Langue de l’interface du portail d’administration et de ses courriels de notification."
            )}
          </p>
          <div
            id="languageSection"
            aria-hidden="true"
            style={{
              display: "none"
            }}
            className="mt-2">
            <select
              id="language"
              aria-describedby="info_langue"
              className="lg:w-96 w-full h-8 bg-donaGray px-2 text-black my-auto mr-4"
              onChange={(event) => {
                setLanguage(event.target.value);
              }}
              value={language}>
              <option value="fr">Français</option>
              <option value="en">English</option>
            </select>
            <div className="mt-3">
              <Button
                type="submit"
                className={
                  window.localStorage.getItem("i18nextLng") === language
                    ? "bg-donaGray rounded-full py-2 px-5 mr-2 text-sm"
                    : "bg-donaPurple rounded-full py-2 px-5 mr-2 text-sm text-white"
                }
                disabled={
                  window.localStorage.getItem("i18nextLng") === language
                    ? true
                    : false
                }
                onClick={() => {
                  saveLanguage(language);
                }}>
                {t("Sauvegarder")}
              </Button>
              <Button
                type="submit"
                className="rounded-full py-2 px-5 button-color--invertMauve mr-2 text-sm"
                onClick={() => {
                  setLanguage(
                    window.localStorage.getItem("i18nextLng") || "fr"
                  );
                }}>
                {t("Annuler")}
              </Button>
            </div>
          </div>
        </div>

        <div className="lg:w-1/2 md:w-11/12 w-full border border-donaGray p-4 my-3 lg:mx-1 mx-auto shadow-lg">
          <div className="flex">
            <label htmlFor="font" className="donaFormFieldLabel">
              {t("Police de caractère")}
            </label>
            <button id="btn_font" aria-expanded="false" onClick={toggleFont}>
              <FontAwesomeIcon
                icon={faEdit}
                size="1x"
                color="#348280"
                className="cursor-pointer mb-auto"
              />
            </button>
          </div>
          <p id="info_font" className="text-xs italic text-black text-left">
            {t(
              "La police Dyslexie est disponible pour les personnes dyslexiques afin d’améliorer la facilité de lecture de cette interface."
            )}
          </p>
          <div
            id="fontSection"
            aria-hidden="true"
            className="mt-2"
            style={{
              display: "none"
            }}>
            <select
              id="font"
              aria-describedby="info_font"
              className="lg:w-96 w-full h-8 bg-donaGray px-2 text-black my-auto mr-4"
              onChange={(event) => {
                handleFontChange(event.target.value);
              }}
              value={currentFont}>
              <option value="Lato">Lato</option>
              <option value="Helvetica">Helvetica</option>
            </select>
            <div className="mt-3">
              <Button
                type="submit"
                className={
                  fontStatus
                    ? "bg-donaGray rounded-full py-2 px-5 mr-2 text-sm"
                    : "bg-donaPurple rounded-full py-2 px-5 mr-2 text-sm text-white"
                }
                disabled={fontStatus ? true : false}
                onClick={() => {
                  saveFont(currentFont);
                }}>
                {t("Sauvegarder")}
              </Button>
              <Button
                type="submit"
                className="rounded-full py-2 px-5 button-color--invertMauve mr-2 text-sm"
                onClick={() => {
                  setCurrentFont(previousFont);
                }}>
                {t("Annuler")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
