import cogoToast from "cogo-toast";
import React, { Component } from "react";

import Auth from "@aws-amplify/auth";
import Button from "../components/Button";
import MessageBlock from "../components/MessageBlock";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import logogvcanada from "../images/logogvcanada.png";
import "../styles/Buttons.css";
import "../styles/Forms.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";

library.add(faEnvelope, faKey);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMessage: "",
      isLoading: false,
      password: "",
      rememberMe: false,
      showPassword: false
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    if (event.target.type === "checkbox") {
      const { checked } = event.target;
      this.setState({
        [event.target.id]: checked
      });
      this.props.setRememberMe(checked);
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
    event.target.setCustomValidity("");
  };

  handleLoginSubmit = async (event) => {
    event.preventDefault();
    let options = { position: "bottom-right" };
    let email = this.state.email.toLowerCase();
    this.setState({ isLoading: true });

    try {
      let signInStatus = await Auth.signIn(email, this.state.password);
      if (
        !signInStatus.signInUserSession &&
        signInStatus.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        this.props.history.push({
          pathname: "/connexion/resetpassword",
          state: { auth: signInStatus }
        });
      } else {
        this.props.setUser(signInStatus.signInUserSession);
        cogoToast.success(
          <div aria-live="polite">{this.props.t("Connexion Réussie")}</div>,
          options
        );
        this.props.history.push("/");
      }
    } catch (e) {
      this.setState({ isLoading: false, errorMessage: e.message });

      cogoToast.error(
        <div aria-live="polite">{this.props.t(e.message)}</div>,
        options
      );
    }
  };

  handleCreateAccount = () => {
    this.props.history.push("/signup");
  };

  InvalidMsg(t, input) {
    var validityState_object = input.target.validity;
    if (validityState_object.valueMissing) {
      input.target.setCustomValidity(t("Veuillez remplir ce champ svp"));
    }
  }

  showPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword
    }));

    if (this.myPassword.type === "password") {
      this.myPassword.type = "text";
    } else {
      this.myPassword.type = "password";
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <title>{t("local_login_title_connecter")}</title>
          <meta name="description" content={t("local_login_title_connecter")} />
        </Helmet>
        <div className="lg:flex lg:h-screen md:flex block content-center">
          <div className="lg:w-2/5 md:w-2/5 bg-donaTurquoise lg:py-64 md:py-56 md:px-5 py-5">
            <div className="text-center">
              <strong className="text-white text-center text-2xl">
                {t("local_login_title_bienvenue")}
              </strong>
              <p className="text-center text-white mb-2">
                {t("local_login_subtitle_bienvenue")}
              </p>

              <p className="text-center text-white">
                {t("local_login_content_bienvenue_1")}
                <br />
                {t("local_login_content_bienvenue_2")}
              </p>
            </div>
          </div>

          <div className="xl:w-2/6 lg:w-2/6 w-full xl:p-20 lg:p-20 md:p-10 p-2 xl:mt-16 lg:mt-16 md:mt-8 mt-2 mx-auto">
            <h1 className="text-donaPurple text-center text-3xl">
              {t("Se connecter")}
            </h1>
            <p className="text-center">{t("local_login_subtitle_connecter")}</p>
            <div className="text-left pt-5">
              <strong id="Login-instructions" className="text-sm">
                {t("Tous les champs sont requis.")}
              </strong>
            </div>

            <form
              className=""
              onInvalid={this.invalidMsg}
              onSubmit={this.handleLoginSubmit}>
              {this.state.errorMessage && (
                <MessageBlock
                  alertMessage={this.state.errorMessage}
                  className="border px-4 py-3 rounded relative mt-6 mb-2"
                  roleType="alert"
                />
              )}
              <div>
                <label className="donaFormFieldLabel" htmlFor="email">
                  {t("Courriel")}
                </label>
                <input
                  className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  defaultValue={this.state.email}
                  required
                />
              </div>
              <div>
                <label className="donaFormFieldLabel" htmlFor="password">
                  {t("Mot de passe")}
                </label>
                <input
                  className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline mb-3"
                  id="password"
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  ref={(input) => {
                    this.myPassword = input;
                  }}
                  defaultValue={this.state.password}
                  required
                />
                <div>
                  <span
                    toggle="#password-field"
                    className={
                      this.state.showPassword
                        ? "fa fa-fw fa-eye field-icon toggle-password"
                        : "fa fa-fw fa-eye-slash field-icon toggle-password"
                    }
                    tabIndex="0"
                    // role="element"
                    aria-label={t("Montrer le mot de passe")}
                    onClick={this.showPassword}></span>
                </div>
              </div>

              <Link
                to="/connexion/forgotpassword"
                className="underline w-full text-center inline-block mb-3">
                {t("Mot de passe oublié?")}
              </Link>

              <div className="text-center mb-3">
                <Button
                  type="submit"
                  className={
                    this.validateForm()
                      ? "donaButton button-color--purple"
                      : "donaButton bg-donaGray"
                  }
                  disabled={!this.validateForm() || this.state.isLoading}>
                  {this.state.isLoading ? (
                    <FontAwesomeIcon icon="spinner" spin />
                  ) : (
                    t("Valider")
                  )}
                </Button>
              </div>

              <p className="mb-8 text-center text-sm">
                {t(
                  "<<Ce projet à été rendu possible en partie grâce au Gouvernement du Canada>>"
                )}

                <br />
                {t(
                  "<<This project has been made possible in part by the Gouvernement of Canada>>"
                )}
              </p>
              <div className="text-center mb-8">
                <a
                  href="https://www.canada.ca"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    className="inline-block"
                    width="250px"
                    src={logogvcanada}
                    alt="Funded by the government of Canada - Financé par le gouvernement du Canada"
                  />
                </a>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
