import React from "react";
import { Helmet } from "react-helmet";

export const Help = (props) => {
  const { t } = props;

  return (
    <>
      <Helmet>
        <title>{t("Aide")}</title>
        <meta name="description" content={t("Aide")} />
      </Helmet>
      <div>
        <div className="flex justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded mb-4">
          <h1 className="text-white font-bold mt-1">{t("Aide")}</h1>
        </div>
        <div className="lg:w-1/2 md:w-11/12 w-full border border-donaGray p-4 my-3 lg:mx-1 mx-auto shadow-lg">
          <h1 className="italic text-black text-left">{t("Nous joindre")}</h1>
          <p className="italic text-black text-left">
            <a href="mailto:supportdona@copibec.ca">supportdona@copibec.ca</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Help;
