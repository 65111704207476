import React, { useState } from "react";
import i18n from "../i18n";
import logocopibec from "../images/logodona.png";
import "../styles/Buttons.css";

const Header = (props) => {
  const [language, setLanguage] = useState(navigator.language);

  const handleLanguage = (event) => {
    window.localStorage.setItem("userLanguage", event.target.value);
    i18n.changeLanguage(event.target.value);
    document.documentElement.lang = event.target.value;
    setLanguage(event.target.value);
  };

  return (
    <header className="bg-donaGray w-full">
      <div
        className="w-full block flex-grow lg:flex lg:items-center lg:w-auto
      md:flex md:items-center md:w-auto sm:flex sm:items-center sm:w-auto">
        <div className="text-sm sm:flex-grow ml-5">
          <img className="sm:h-5" src={logocopibec} alt="Copibec" />
        </div>
        <div>
          <label htmlFor="language" className="mr-3 text-black font-bold">
            {language === "fr" ? "Langue:" : "Language:"}
          </label>
          <select
            id="language"
            onChange={(event) => {
              handleLanguage(event);
            }}
            className="donaButton button-color--purple mr-3"
            value={language}>
            <option value="fr">Français</option>
            <option value="en">English</option>
          </select>
        </div>
      </div>
    </header>
  );
};

export default Header;
