import React from "react";
import Table from "../components/Table";
import { Link } from "react-router-dom";
import HttpDataSource from "../api/HttpDataSource";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DebouncedInput from "react-debounce-input";
import { Helmet } from "react-helmet";

class OffresGroupes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      OffresGroupes: [],
      originalOffresGroupes: [],
      searchOffresGroupesTerm: ""
    };
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    const userGroup =
      this.props.userGroup || window.localStorage.getItem("userGroup");
    const idPublisher =
      this.props.idPublisher || window.localStorage.getItem("idPublisher");

    const data = await HttpDataSource.getOffresGroupesData(
      userGroup,
      idPublisher
    );

    this.setState({
      OffresGroupes: data.result || [],
      originalOffresGroupes: data.result || [],
      isLoading: false
    });
  }
  onChange = (e) => {
    let originalOffresGroupes = this.state.originalOffresGroupes;
    let searchOffresGroupesTerm = e.target.value;

    if (searchOffresGroupesTerm) {
      let filterOffresGroupes = originalOffresGroupes.filter(function (item) {
        if (Number(searchOffresGroupesTerm)) {
          return (
            item.identifiant &&
            item.identifiant
              .toLowerCase()
              .includes(searchOffresGroupesTerm.toLowerCase())
          );
        } else {
          return (
            (item.titre &&
              item.titre
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchOffresGroupesTerm
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                )) ||
            (item.maison_edition &&
              item.maison_edition.nom
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchOffresGroupesTerm
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ))
          );
        }
      });
      this.setState({
        OffresGroupes: filterOffresGroupes,
        searchOffresGroupesTerm
      });
    } else {
      this.setState({
        OffresGroupes: originalOffresGroupes,
        searchOffresGroupesTerm
      });
    }
    this.ref.focus();
  };

  render() {
    const { t } = this.props;
    this.columns = [
      {
        dataField: "identifiant",
        text: t("Identifiant"),
        sort: this.props.boardTable ? false : true,
        classes:
          "px-5 py-4 text-center whitespace-no-wrap border-b border-gray-200 text-md leading-5 text-black",
        headerClasses: "w-3/12 text-donaPurple font-bold whitespace-normal",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 }
      },
      {
        dataField: "titre",
        text: t("Titre"),
        sort: this.props.boardTable ? false : true,
        classes:
          "px-5 py-4 text-left whitespace-normal border-b border-gray-200 text-md leading-5 font-bold text-donaPurple underline",
        headerClasses: "w-5/12 text-donaPurple font-bold whitespace-normal",
        headerAttrs: { scope: "col", tabIndex: this.props.boardTable ? -1 : 0 },
        attrs: { role: "rowheader", scope: "row" },
        formatter: (cell, row) => (
          <Link to={`offres-groupes-details/${row.id_combo}`}>{row.titre}</Link>
        )
      },
      {
        dataField: "maison_edition",
        text: t(" Maison d'édition "),
        classes:
          "px-5 py-4 text-center border-b border-gray-200 text-md leading-5 text-black whitespace-normal",
        headerClasses: "w-4/12 text-donaPurple font-bold whitespace-normal",
        headerAttrs: { scope: "col", tabIndex: "-1" },
        formatter: (cell, row) => (
          <span>{`${row.maison_edition.nom}  ${
            row.maison_edition.prenom && row.maison_edition.prenom
              ? row.maison_edition.prenom
              : ""
          }`}</span>
        )
      }
    ];
    const CaptionElements = () => (
      <div className="lg:flex  justify-between flex-wrap-6 bg-donaPurple p-2 border border-donaPurple rounded">
        <div className="flex lg:my-auto my-2">
          <h1 className="text-white font-bold">
            {t("Liste des offres groupées")}
          </h1>
          <div className="text-white ml-1" aria-live="polite">
            {this.state.isLoading === false &&
              "(" +
                this.state.OffresGroupes.length +
                " " +
                (this.state.OffresGroupes.length > 1
                  ? t("enregistrements")
                  : t("enregistrement")) +
                ")"}
          </div>
        </div>
        <div className="lg:mx-auto lg:text-center text-left my-2">
          <Link
            to={"/offres-groupes-details"}
            className="donaButton button-color--invertTurquoisepale my-0"
            style={{ marginTop: "0", marginBottom: "0" }}>
            {t("Créer une offre groupée")}
          </Link>
        </div>
        <div className="relative lg:my-auto text-left">
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute pointer-events-none  inset-y-0 ml-3 mt-3 flex items-center"
          />
          <DebouncedInput
            inputRef={(ref) => {
              this.ref = ref;
            }}
            minLength={1}
            debounceTimeout={1200}
            type="search"
            onChange={this.onChange}
            className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 pl-10 leading-tight focus:outline-none focus:shadow-outline"
            placeholder={t("Recherche sur table")}
            value={this.state.searchOffresGroupesTerm}
            aria-label={t("Rechercher dans le tableau")}
          />
        </div>
      </div>
    );
    return (
      <>
        {!this.props.boardTable && (
          <Helmet>
            <title>{t("Offres Groupées")}</title>
            <meta name="description" content={t("Offres Groupées")} />
          </Helmet>
        )}
        <Table
          keyField="id_combo"
          loading={this.state.isLoading}
          showMore={false}
          boardTable={false}
          data={this.state.OffresGroupes}
          noDataMessage={t("Pas d'offres groupés Disponible")}
          caption={<CaptionElements />}
          columns={this.columns}
          classes="my-5"
          t={t}
        />
      </>
    );
  }
}
export default OffresGroupes;
