import React from "react";
import DocumentsList from "./DocumentsList";
import DemandesList from "./DemandesList";
import DocumentsAchetesUtilisateursList from "./DocumentsAchetesUtilisateursList";
import CommandesAchatList from "./CommandesAchatsList";
import HistoriquesRepresentantsList from "./HistoriquesRepresentantsList";
import { Helmet } from "react-helmet";

const BoardTableLayout = (props) => {
  const { t } = props;
  return (
    <>
      <Helmet>
        <title>Dona - {t("Tableau de bord")}</title>
        <meta name="description" content={t("Tableau de bord")} />
      </Helmet>
      <h1 className="w-full block text-center shadow-md text-black bg-donaGray p-2 border border-black rounded text-lg py-3 mb-6">
        {t("Tableau de bord")}
      </h1>
      {(props.userGroup === "superadmin" || props.userGroup === "admin") && (
        <DocumentsList {...props} boardTable={true} />
      )}
      {(props.userGroup === "superadmin" || props.userGroup === "admin") && (
        <DemandesList {...props} boardTable={true} />
      )}
      {(props.userGroup === "superadmin" || props.userGroup === "admin") && (
        <DocumentsAchetesUtilisateursList {...props} boardTable={true} />
      )}
      {(props.userGroup === "superadmin" || props.userGroup === "approver") && (
        <CommandesAchatList {...props} boardTable={true} />
      )}
      {(props.userGroup === "superadmin" || props.userGroup === "approver") && (
        <HistoriquesRepresentantsList {...props} boardTable={true} />
      )}
    </>
  );
};

export default BoardTableLayout;
