import * as React from "react";
import { withAuthConsumer } from "../authcontext/AuthConsumer";
import { Redirect, withRouter } from "react-router-dom";

const HomeRedirect = (props) => {
  const isAuthenticated = props.isAuthenticated;

  return (
    <div>
      {!isAuthenticated ? (
        <Redirect to={"/connexion"} />
      ) : (
        <Redirect to={"/tableau-de-bord"} />
      )}
    </div>
  );
};

export default withRouter(withAuthConsumer(HomeRedirect));
