import Amplify from "@aws-amplify/core";
import config from "./config";

const awsconfig = Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },

  Storage: {
    region: config.s3.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    bucket: config.s3.BUCKET
  },

  API: {
    endpoints: [
      {
        name: "DocumentsGatewayAPI",
        endpoint: config.encryptUtilsApi.DOCUMENTS_API_URL
      },

      {
        name: "DocumentsCopibecGatewayAPI",
        endpoint: config.encryptUtilsApi.DOCUMENTS_COPIBEC_API_URL
      },

      {
        name: "DocumentsDetailsGatewayAPI",
        endpoint: config.encryptUtilsApi.DOCUMENTS_DETAILS_API_URL
      },

      {
        name: "UpdateDocumentsDetailsGatewayAPI",
        endpoint: config.encryptUtilsApi.UPDATE_DOCUMENTS_DETAILS_API_URL
      },

      {
        name: "GroupesDocumentsDetailsGatewayAPI",
        endpoint: config.encryptUtilsApi.GROUPES_DOCUMENTS_DETAILS_API_URL
      },

      {
        name: "DemandesDetailsDocumentsGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDES_DETAILS_DOCUMENTS_API_URL
      },

      {
        name: "DemandesGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDES_API_URL
      },

      {
        name: "DemandesAllGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDES_ALL_API_URL
      },

      {
        name: "DemandesCopibecAllGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDES_COPIBEC_ALL_API_URL
      },

      {
        name: "DemandesCopibecGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDES_COPIBEC_API_URL
      },

      {
        name: "DemandesDetailsGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDE_DETAILS_API_URL
      },

      {
        name: "DemandesDetailsApprouveeGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDE_DETAILS_APPROUVEE_API_URL
      },

      {
        name: "DemandesDetailsRefuseeGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDE_DETAILS_REFUSEE_API_URL
      },

      {
        name: "DemandesDetailsCopibecGatewayAPI",
        endpoint: config.encryptUtilsApi.DEMANDE_DETAILS_COPIBEC_API_URL
      },

      {
        name: "CreateDemandesDetailsGatewayAPI",
        endpoint: config.encryptUtilsApi.CREATE_DEMANDE_DETAILS_API_URL
      },

      {
        name: "CreateDemandesDetailsApprouveOrRejeteGatewayAPI",
        endpoint:
          config.encryptUtilsApi.CREATE_DEMANDE_DETAILS_APPROUVE_REJETE_API_URL
      },

      {
        name: "DocumentsAchetesUtilisateursGatewayAPI",
        endpoint: config.encryptUtilsApi.DOCUMENTS_ACHETES_UTILISATEURS_API_URL
      },

      {
        name: "DocumentsCopibecAchetesUtilisateursGatewayAPI",
        endpoint:
          config.encryptUtilsApi.DOCUMENTS_COPIBEC_ACHETES_UTILISATEURS_API_URL
      },

      {
        name: "DocumentsAchetesUtilisateursDetailsGatewayAPI",
        endpoint:
          config.encryptUtilsApi.DOCUMENTS_ACHETES_UTILISATEURS_DETAILS_API_URL
      },

      {
        name: "CommandesAchatsGatewayAPI",
        endpoint: config.encryptUtilsApi.COMMANDES_ACHATS_API_URL
      },

      {
        name: "CommandesCopibecAchatsGatewayAPI",
        endpoint: config.encryptUtilsApi.COMMANDES_COPIBEC_ACHATS_API_URL
      },

      {
        name: "CommandesAchatsDocumentsDetailsGatewayAPI",
        endpoint:
          config.encryptUtilsApi.COMMANDES_ACHATS_DOCUMENTS_DETAILS_API_URL
      },
      {
        name: "CommandesAchatsOrderDetailsGatewayAPI",
        endpoint: config.encryptUtilsApi.COMMANDES_ACHATS_ORDER_DETAILS_API_URL
      },
      {
        name: "ApproveOrRefuseCommandesAchatsOrderDetailsGatewayAPI",
        endpoint:
          config.encryptUtilsApi
            .APPROVE_OR_REFUSE_COMMANDES_ACHATS_ORDER_DETAILS_API_URL
      },

      {
        name: "HistoriquesRepresentantsGatewayAPI",
        endpoint: config.encryptUtilsApi.HISTORIQUES_REPRESENTANTS_API_URL
      },

      {
        name: "HistoriquesCopibecRepresentantsGatewayAPI",
        endpoint:
          config.encryptUtilsApi.HISTORIQUES_COPIBEC_REPRESENTANTS_API_URL
      },

      {
        name: "HistoriquesRepresentantsDocumentsDetailsGatewayAPI",
        endpoint:
          config.encryptUtilsApi
            .HISTORIQUES_REPRESENTANTS_DOCUMENTS_DETAILS_API_URL
      },

      {
        name: "HistoriquesRepresentantsOrderDetailsGatewayAPI",
        endpoint:
          config.encryptUtilsApi.HISTORIQUES_REPRESENTANTS_ORDER_DETAILS_API_URL
      },

      {
        name: "PublicationGatewayAPI",
        endpoint: config.encryptUtilsApi.CREATE_PUPLICATION_API_URL
      },

      {
        name: "PublishersGatewayAPI",
        endpoint: config.encryptUtilsApi.PUBLISHERS_API_URL
      },

      {
        name: "PublishersCopibecGatewayAPI",
        endpoint: config.encryptUtilsApi.PUBLISHERS_COPIBEC_API_URL
      },

      {
        name: "OffresGroupesGatewayAPI",
        endpoint: config.encryptUtilsApi.OFFRES_GROUPES_API_URL
      },

      {
        name: "OffresGroupesCopibecGatewayAPI",
        endpoint: config.encryptUtilsApi.OFFRES_GROUPES_COPIBEC_API_URL
      },

      {
        name: "DocumentDetailsOffresGroupesGatewayAPI",
        endpoint: config.encryptUtilsApi.DOCUMENT_DETAILS_OFFRES_GROUPES_API_URL
      },

      {
        name: "CreateDocumentDetailsGroupesGatewayAPI",
        endpoint: config.encryptUtilsApi.CREATE_DOCUMENT_DETAILS_GROUPES_API_URL
      },

      {
        name: "UpdateDocumentDetailsGroupesGatewayAPI",
        endpoint: config.encryptUtilsApi.UPDATE_DOCUMENT_DETAILS_GROUPES_API_URL
      },

      {
        name: "ComboOffresGroupesGatewayAPI",
        endpoint: config.encryptUtilsApi.COMBO_OFFRES_GROUPES_API_URL
      },

      {
        name: "ComboCopibecOffresGroupesGatewayAPI",
        endpoint: config.encryptUtilsApi.COMBO_COPIBEC_OFFRES_GROUPES_API_URL
      },

      {
        name: "DocumentsGroupesGatewayAPI",
        endpoint: config.encryptUtilsApi.DOCUMENTS_GROUPES_API_URL
      },
      {
        name: "RaisonsSuperAdminGatewayAPI",
        endpoint: config.encryptUtilsApi.RAISONS_SUPER_ADMIN_API_URL
      },
      {
        name: "RaisonsAdminGatewayAPI",
        endpoint: config.encryptUtilsApi.RAISONS_ADMIN_API_URL
      }
    ]
  }
});

export default { ...awsconfig };
