import HttpDataSource from "../api/HttpDataSource";

export function getLang() {
  let language;
  let languageArr;
  if (typeof window !== `undefined`) {
    if (navigator && navigator.languages !== undefined) {
      languageArr = navigator.languages[0].split("-");
    } else if (navigator) {
      return navigator.language;
    }
    if (languageArr) {
      language = languageArr[0];
      return language;
    }
    return language;
  }
  return "fr";
}

export function dateConverter(datevalue) {
  const date = new Date(datevalue);

  const year = date.getFullYear();

  let month = date.getMonth() + 1;

  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month + "-" + dt;
}

export async function processImages(
  hyperlien_image,
  identifiant,
  old_identifiant
) {
  let result;
  let s3_stored_image = [];
  const images = hyperlien_image;
  let countImagesToBeRanamed = 0;

  for (let i = 0; i < images.length; i++) {
    if (images[i].isNew) {
      let extension = images[i].content.type.split("/")[1];
      let filename =
        i === 0
          ? `${identifiant}.${extension}`
          : `${identifiant}-${+i}.${extension}`;

      result = await HttpDataSource.S3ImageUpload(filename, images[i].content);
      console.log(result);

      s3_stored_image.push(process.env.REACT_APP_S3_IMAGE_URL + result.key);
    } else if (identifiant !== old_identifiant) {
      countImagesToBeRanamed += 1;
      /* split url to get file name with extention*/
      let imageName = images[i].split("/")[4];
      /* get only file name from extention*/
      let formatedImage = imageName.split(".")[0];
      let extension = imageName.split(".")[1];
      let imageNameLastIndex = formatedImage[formatedImage.length - 1];
      let renamedImage = "";

      if (countImagesToBeRanamed > 1) {
        renamedImage = `${identifiant + imageNameLastIndex}.${extension}`;
      } else {
        renamedImage = `${identifiant}.${extension}`;
      }
      let copyImage = await HttpDataSource.S3Copy(
        imageName,
        renamedImage,
        "public",
        "image"
      );
      await HttpDataSource.S3DeleteImage(imageName);
      s3_stored_image.push(process.env.REACT_APP_S3_IMAGE_URL + copyImage.key);
    }
  }
  return s3_stored_image;
}
