import React from "react";
import "../styles/Err404.css";

const Err404 = (props) => {
  return (
    <>
      <div className="background"></div>
      <div className="container">
        <div className="left-section">
          <div className="inner-content">
            <h1 className="heading">404</h1>
            <p className="subheading">
              Looks like the page you were looking for is not available.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Err404;
