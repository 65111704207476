import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Button from "../components/Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import AutoCompleteSelect from "../components/AutoCompleteSelect";
import DraggableCards from "../components/DraggableCards";
import Err403 from "../containers/Err403";
import HttpDataSource from "../api/HttpDataSource";
import FilterDualListBox from "../components/FilterDualListBox";
import MessageBlock from "../components/MessageBlock";
import { processImages } from "../libs/utility";

import { Helmet } from "react-helmet";

library.add(faPlusCircle, faTrashAlt);

class OffresGroupesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langue: "fr",
      id_combo: "",
      identifiant: "",
      old_identifiant: "",
      disponible: false,
      isFormSubmit: false,
      description: "",
      niveau: "primaire/secondaire",
      editeur_public: "",
      type_offre_groupee: "aide-mémoire",
      prix: "",
      titre: "",
      maison_edition: { value: "", label: "" },
      id_maison_edition: "",
      isLoading: true,
      errorMessage: "",
      successMessage: "",
      DocumentDetails: [],
      hyperlien_image: [],
      comboOffresGroupes: [],
      documentsGroupes: [],
      cpDocuments: [],
      resetValue: false,
      maisons_editions: [],
      tableDocumentsGroupes: [],
      deleted_images: [],
      invalidInput: "",
      errorStatusCode: ""
    };
    this.initialState = "";
  }

  async componentDidMount() {
    window && window.scroll(0, 0);
    let documentDetailsOffresGroupesData = "";
    let maison_edition = "";
    let documentsGroupesData = "";
    let formatedComboData = [];
    let options = { position: "bottom-right" };
    let format_maisons_editions = [];
    const userGroup =
      this.props.userGroup || window.localStorage.getItem("userGroup");
    const idPublisher =
      this.props.idPublisher || window.localStorage.getItem("idPublisher");

    try {
      const maisons_editions = await HttpDataSource.getPublishersData(
        userGroup,
        idPublisher
      );

      if (this.props.userGroup !== "superadmin") {
        maisons_editions.result.map((item) => {
          format_maisons_editions.push({
            id_organisation: item.id_organisation,
            nom: item.nom.nom
          });
        });
        this.setState({
          maisons_editions: format_maisons_editions
        });
      } else {
        this.setState({
          maisons_editions: maisons_editions.result
        });
      }

      const allDocuments = await HttpDataSource.getComboOffresGroupesData(
        this.props.userGroup,
        this.props.idPublisher
      );

      if (this.props.match.params.id) {
        documentDetailsOffresGroupesData = await HttpDataSource.getDocumentDetailsOffresGroupesData(
          this.props.match.params.id
        );

        if (documentDetailsOffresGroupesData.result[0].id_maison_edition) {
          let filterAlldocuments = allDocuments.result.filter(
            (doc) =>
              doc.id_maison_edition ===
              documentDetailsOffresGroupesData.result[0].id_maison_edition
          );

          filterAlldocuments &&
            filterAlldocuments.forEach((item) => {
              let tempObj = {
                value: item.identifiant,
                label: item.titre + " (ISBN : " + item.identifiant + ") "
              };
              formatedComboData.push(tempObj);
            });
        }

        if (documentDetailsOffresGroupesData.result[0].maison_edition.nom) {
          maison_edition =
            documentDetailsOffresGroupesData.result[0].maison_edition.nom;
        }

        if (documentDetailsOffresGroupesData.result[0].maison_edition.prenom) {
          maison_edition +=
            documentDetailsOffresGroupesData.result[0].maison_edition.prenom;
        }

        documentsGroupesData = await HttpDataSource.getDocumentsGroupesData(
          this.props.match.params.id
        );

        this.setState({
          DocumentDetails: documentDetailsOffresGroupesData.result || [],
          hyperlien_image: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].hyperlien_image
              ? documentDetailsOffresGroupesData.result[0].hyperlien_image
              : []
            : [],
          identifiant: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].identifiant
            : "",
          old_identifiant: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].identifiant
            : "",
          id_combo: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].id_combo
            : "",
          langue:
            documentDetailsOffresGroupesData &&
            documentDetailsOffresGroupesData.result[0].langue
              ? documentDetailsOffresGroupesData.result[0].langue
              : "",
          disponible: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].disponible
            : false,
          description:
            documentDetailsOffresGroupesData &&
            documentDetailsOffresGroupesData.result[0].description
              ? documentDetailsOffresGroupesData.result[0].description
              : "",
          niveau:
            documentDetailsOffresGroupesData &&
            documentDetailsOffresGroupesData.result[0].niveau
              ? documentDetailsOffresGroupesData.result[0].niveau
              : "",
          editeur_public:
            documentDetailsOffresGroupesData &&
            documentDetailsOffresGroupesData.result[0].editeur_public
              ? documentDetailsOffresGroupesData.result[0].editeur_public
              : "",
          type_offre_groupee:
            documentDetailsOffresGroupesData.result[0].type_offre_groupee ||
            "cahier",
          prix: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].prix
            : "",
          titre: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].titre
            : "",

          comboOffresGroupes: formatedComboData,
          cpDocuments: allDocuments.result,
          isLoading: false,
          documentsGroupes: documentsGroupesData
            ? documentsGroupesData.result
            : [],
          tableDocumentsGroupes: documentsGroupesData
            ? documentsGroupesData.result
            : [],

          maison_edition: {
            value: documentDetailsOffresGroupesData
              ? documentDetailsOffresGroupesData.result[0].id_maison_edition
              : "",
            label: maison_edition
          },
          id_maison_edition: documentDetailsOffresGroupesData
            ? documentDetailsOffresGroupesData.result[0].id_maison_edition
            : ""
        });
      } else {
        this.setState({
          comboOffresGroupes: formatedComboData,
          cpDocuments: allDocuments.result,
          isLoading: false
        });
      }

      this.initialState = this.state;
    } catch (error) {
      let message = error.response.data.error;
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );
      this.setState({ errorStatusCode: error.response.status });
    }
  }

  setSelectValue = (selected) => {
    if (selected === "") {
      this.setState({
        id_maison_edition: null,
        maison_edition: { value: "", label: "" },
        comboOffresGroupes: [],
        documentsGroupes: []
      });
    } else {
      let formatedComboData = [];
      this.setState({
        id_maison_edition: null,
        maison_edition: { value: "", label: "" },
        comboOffresGroupes: [],
        documentsGroupes: []
      });

      let filterAlldocuments = this.state.cpDocuments.filter(
        (doc) => doc.id_maison_edition === selected.value
      );

      filterAlldocuments.forEach((item) => {
        let tempObj = {
          value: item.identifiant,
          label: item.titre + " (ISBN : " + item.identifiant + ") "
        };
        formatedComboData.push(tempObj);
      });

      this.setState({
        comboOffresGroupes: formatedComboData,
        id_maison_edition: selected.value,
        maison_edition: { value: selected.value, label: selected.label }
      });
    }
  };

  onChangeDualListBox = (items) => {
    const filterData = this.state.cpDocuments.filter((el) => {
      return items.some((f) => {
        return el.identifiant === f;
      });
    });

    const documentsGroupes = filterData.length > 0 ? [...filterData] : [];
    this.setState({ documentsGroupes });
  };

  onChangeContent = (items) => {
    this.setState({ hyperlien_image: items });
  };

  handleChange = (event) => {
    if (typeof event === "boolean") {
      this.setState({
        disponible: event
      });
    } else if (event.target.id === "identifiant") {
      let numbers = event.target.value.replace(/[^0-9]/g, "");
      this.setState({
        [event.target.id]: numbers
      });
      event.target.setCustomValidity("");
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
      event.target.setCustomValidity("");
    }
  };

  addLien_fichier_image = (e) => {
    let cover_images = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let images = { content: files[i], isNew: true };
      cover_images.push(images);
    }

    const hyperlien_image = [...this.state.hyperlien_image, ...cover_images];

    this.setState({
      hyperlien_image
    });
  };

  removedocumentsGroupes = (idx) => {
    const cpdocumentsGroupes = [...this.state.documentsGroupes];
    cpdocumentsGroupes.splice(idx, 1);
    this.setState({ documentsGroupes: cpdocumentsGroupes });
  };

  removeCard = (index) => {
    const cpitems = [...this.state.hyperlien_image];
    cpitems.splice(index, 1);
    this.setState({ hyperlien_image: cpitems });
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    let data = "";
    let processedImages = "";
    let errorMessage = "";
    let hyperlien_image = null;
    let document = {};
    let ids_produits = [];
    let isValide = true;
    let options = { position: "bottom-right" };

    this.setState({ isFormSubmit: true });

    if (
      this.state.identifiant.length < 10 ||
      this.state.identifiant.length > 13
    ) {
      window && window.scroll(0, 0);
      isValide = false;
      errorMessage = this.props.t(
        "Svp veuillez rentrer un identifiant d'un minimum 10 et maximun 13 chiffres"
      );

      this.setState({
        isFormSubmit: false,
        errorMessage
      });
      cogoToast.error(<div aria-live="polite">{errorMessage}</div>, options);
      return false;
    }

    if (this.state.documentsGroupes.length === 0) {
      window && window.scroll(0, 0);
      isValide = false;
      let message = "Veillez Ajouter un document pour le groupée svp";
      cogoToast.error(
        <div aria-live="polite">{this.props.t(message)}</div>,
        options
      );

      this.setState({ errorMessage: message, isFormSubmit: false });
      return false;
    }

    if (isValide) {
      try {
        if (this.state.hyperlien_image.length > 0) {
          //upload images to s3
          processedImages = await processImages(
            this.state.hyperlien_image,
            this.state.identifiant,
            this.props.match.params.id
              ? this.state.old_identifiant
              : this.state.identifiant
          );

          if (this.state.identifiant !== this.state.old_identifiant) {
            hyperlien_image = [...processedImages];
          } else {
            hyperlien_image = [
              ...this.state.hyperlien_image.filter((x) => !x.isNew),
              ...processedImages
            ];
          }
        }

        this.state.documentsGroupes.length > 0 &&
          this.state.documentsGroupes.map((item) => {
            return ids_produits.push(item.id_produit);
          });

        let metadata = `${JSON.stringify({
          type_offre_groupee: this.state.type_offre_groupee,
          editeur_public: this.state.editeur_public || null
        })}`;

        document = {
          values: {
            id_maison_edition: this.state.id_maison_edition,
            niveau: this.state.niveau,
            langue: this.state.langue,
            titre: this.state.titre,
            prix: this.state.prix,
            metadata,
            disponible: this.state.disponible,
            ids_produits,
            hyperlien_image,
            description: this.state.description,
            nom_fichier: null
          },
          user: "admin@copibec"
        };

        if (this.props.match.params.id) {
          document.values.id_combo = this.state.id_combo;
          document.values.identifiant = this.state.identifiant;
          data = await HttpDataSource.updateDocumentDetailsOffresGroupesData(
            document
          );
        } else {
          document.values.identifiant = this.state.identifiant; //to check with humberto
          data = await HttpDataSource.createDocumentDetailsOffresGroupesData(
            document
          );
        }
        if (data) {
          let message = "";
          if (this.props.match.params.id) {
            message = this.props.t("global_formsubmit_sauvegarde_combo");
          } else {
            message = this.props.t("global_formsubmit_ajout_combo");
            this.initialState.successMessage = this.props.t(
              "global_formsubmit_ajout_combo"
            );
          }

          cogoToast.success(
            <div aria-live="polite">{this.props.t(message)}</div>,
            options
          );

          if (this.props.match.params.id) {
            this.setState({ errorMessage: "", isFormSubmit: false });
          } else {
            this.setState(this.initialState);
          }
          setTimeout(() => this.props.history.push("/offres-groupes"), 2300);
        }
        return true;
      } catch (error) {
        console.log(error);
        let message = "";

        if (error.response.status === 409) {
          message = "ISBN already exists, please enter a new one";
          this.identifiant.focus();
        } else {
          message = error.response.data.error;
        }

        // removing upload2 images from S3
        processedImages.length > 0 &&
          processedImages.map(async (s3Image) => {
            await HttpDataSource.S3DeleteImage(
              s3Image.substring(s3Image.lastIndexOf("/") + 1)
            );
          });

        this.setState({ errorMessage: message, isFormSubmit: false });

        cogoToast.error(
          <div aria-live="polite">{this.props.t(message)}</div>,
          options
        );
      }
    }
  };

  InvalidMsg(t, input) {
    var validityState_object = input.target.validity;
    if (validityState_object.valueMissing) {
      input.target.setCustomValidity(t("Veuillez remplir ce champ svp"));
      this.setState({ invalidInput: input.target });
    }
  }

  //to be decided  if we leave this
  clearChanges = (event) => {
    event.preventDefault();
    if (this.props.match.params.id) {
      this.setState(this.initialState);
      this.setState({ resetValue: true });
    } else {
      this.setState({
        langue: "",
        id_combo: "",
        identifiant: "",
        disponible: false,
        description: "",
        niveau: "",
        prix: "",
        titre: "",
        maison_edition: { value: "", label: "" },
        id_maison_edition: "",
        DocumentDetails: [],
        hyperlien_image: [],
        documentsGroupes: [],
        resetValue: true
      });
    }

    this.state.invalidInput && this.state.invalidInput.setCustomValidity("");
    this.props.history.push("/offres-groupes");
  };

  render() {
    const { t } = this.props;
    let skeletonLoaderItems = [];
    const SkeletonInputsLoader = () => (
      <>
        <div className="flex flex-col">
          <div>
            <Skeleton width={160} height={30} />
          </div>
          <div>
            <Skeleton width={500} height={30} />
          </div>
        </div>
      </>
    );
    for (var i = 0; i < 7; i++) {
      skeletonLoaderItems.push(<SkeletonInputsLoader key={i} />);
    }
    const SkeletonLoader = () => (
      <>
        <div className="flex flex-col">
          <div>
            <Skeleton width={520} height={130} />
          </div>
        </div>
      </>
    );

    if (this.state.errorStatusCode === 403) {
      return <Err403 />;
    }

    return (
      <>
        <Helmet>
          <title>{t("Offres Groupées Détails")}</title>
          <meta name="description" content={t("Offres Groupés Détails")} />
        </Helmet>
        <div>
          <div className="flex text-white justify-between flex-wrap-6 bg-donaPurple py-2 px-2 border border-donaPurple rounded">
            <h1 className="my-auto">{t("Offre groupée/combo")}</h1>
          </div>
          <br />
          <form
            className="xl:w-3/5 lg:w-4/5 w-11/12 pt-3 m-0 mb-5 mx-auto"
            onInvalid={(e) => this.InvalidMsg(t, e)}
            onSubmit={this.handleFormSubmit}>
            {this.state.isLoading ? (
              <>
                {skeletonLoaderItems}
                <SkeletonLoader />
              </>
            ) : (
              <>
                {this.state.errorMessage && (
                  <MessageBlock
                    alertMessage={this.state.errorMessage}
                    className="border px-4 py-3 rounded relative mt-6 mb-2"
                    roleType="alert"
                  />
                )}
                {this.state.successMessage && (
                  <MessageBlock
                    alertMessage={this.state.successMessage}
                    className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-2"
                    roleType="success"
                  />
                )}
                <fieldset className="mt-3 border border-donaGray px-10 py-6 mb-5 shadow-lg">
                  <legend>{t("Information")}</legend>

                  <label
                    className="font-bold text-left block md:h-6 h-12 w-full font-base"
                    htmlFor="identifiant">
                    {t(
                      "Identifiant/ISBN de l'offre groupée/combo (doit être unique)"
                    )}
                  </label>
                  <input
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="identifiant"
                    required
                    value={this.state.identifiant}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={this.handleChange}
                    autoFocus
                    ref={(input) => {
                      this.identifiant = input;
                    }}
                  />
                  <small>Format:1234567897 min:10 max:13 {t("chiffres")}</small>
                  <label className="donaFormFieldLabel mt-2" htmlFor="titre">
                    {t("Titre de l'offre groupée")}
                  </label>
                  <input
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="titre"
                    name="title"
                    required
                    value={this.state.titre}
                    onChange={this.handleChange}
                  />

                  <label
                    className="donaFormFieldLabel"
                    htmlFor="type_offre_groupee">
                    {t("Type d'œuvre")}
                  </label>
                  <select
                    className="w-full h-8 bg-donaGray donaButton"
                    name="type"
                    id="type_offre_groupee"
                    value={this.state.type_offre_groupee}
                    onChange={this.handleChange}>
                    <option value="aide-mémoire">{t("Aide-mémoire")}</option>
                    <option value="cahier">{t("Cahier")}</option>
                    <option value="carnet">{t("Carnet")}</option>
                    <option value="code grammatical">
                      {t("Code grammatical")}
                    </option>
                    <option value="essai">{t("Essai")}</option>
                    <option value="fascicule">{t("Fascicule")}</option>
                    <option value="guide de stage">
                      {t("Guide de stage")}
                    </option>
                    <option value="ligne du temps">
                      {t("Ligne du temps")}
                    </option>
                    <option value="manuel">{t("Manuel")}</option>
                    <option value="méthode de soins">
                      {t("Méthode de soins")}
                    </option>
                    <option value="recueil de solutions">
                      {t("Recueil de solutions")}
                    </option>
                    <option value="recueil de textes">
                      {t("Recueil de textes")}
                    </option>
                    <option value="roman">{t("Roman")}</option>
                  </select>
                  <label className="donaFormFieldLabel" htmlFor="niveau">
                    {t("Niveau")}
                  </label>
                  <select
                    className="w-full bg-donaGray donaButton"
                    id="niveau"
                    name="level"
                    required
                    value={this.state.niveau}
                    onChange={this.handleChange}>
                    <option value="primaire/secondaire">
                      {t("Primaire/Secondaire")}
                    </option>
                    <option value="collégial/universitaire">
                      {t("Collégial/Universitaire")}
                    </option>
                  </select>
                  <label className="donaFormFieldLabel mt-1" htmlFor="langue">
                    {t("Langue")}
                  </label>
                  <select
                    className="w-full h-8 bg-donaGray donaButton"
                    id="langue"
                    name="language"
                    onChange={this.handleChange}
                    value={this.state.langue}>
                    <option value="fr">{t("Français")}</option>
                    <option value="en">{t("Anglais")}</option>
                    <option value="es">{t("Espagnol")}</option>
                  </select>
                  <label
                    className="donaFormFieldLabel mt-2"
                    htmlFor="description">
                    {t("Description l'offre groupée")}
                  </label>
                  <textarea
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="description"
                    name="description"
                    type="text"
                    style={{ width: "100%", height: "6rem" }}
                    value={this.state.description}
                    onChange={this.handleChange}></textarea>
                </fieldset>

                <fieldset className="border border-donaGray px-10 py-6 mb-5 shadow-lg">
                  <legend>{t("Informations de l'éditeur")}</legend>
                  <label
                    className="donaFormFieldLabel"
                    htmlFor="id_maison_edition">
                    {t("Éditeur")}
                  </label>
                  <AutoCompleteSelect
                    placeholder={t("Filtrer Éditeur")}
                    data={this.state.maisons_editions}
                    name="organization"
                    fields={
                      this.props.userGroup === "superadmin"
                        ? { value: "id", label: "nom_maison_edition" }
                        : { value: "id_organisation", label: "nom" }
                    }
                    onChange={this.setSelectValue}
                    initValue={this.state.maison_edition}
                    required={true}
                  />
                  <label
                    className="donaFormFieldLabel mt-3"
                    htmlFor="editeur_public">
                    {t("Nom d'éditeur affiché dans le catalogue DONA")}
                  </label>
                  <input
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="editeur_public"
                    name="organization"
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.editeur_public}
                  />
                </fieldset>
                <fieldset className="border border-donaGray px-10 py-6 mb-5 shadow-lg">
                  <legend>{t("Préférences de vente")}</legend>
                  <label
                    className="donaFormFieldLabel mt-2"
                    htmlFor="disponible">
                    {t("Disponible sur Dona")}
                  </label>
                  <div className="md:flex md:items-center mb-3 mt-1">
                    <div className="md">
                      <Switch
                        id="disponible"
                        onChange={this.handleChange}
                        checked={this.state.disponible}
                        className="react-switch"
                      />
                    </div>
                    <div className="md ml-2">
                      {this.state.disponible ? t("Oui") : t("Non")}.
                    </div>
                  </div>
                  <label className="donaFormFieldLabel mt-2" htmlFor="prix">
                    {t("Prix de l'offre groupée")}
                  </label>
                  <input
                    className="donaFormField shadow appearance-none leading-tight focus:outline-none focus:shadow-outline"
                    id="prix"
                    name="price"
                    required
                    value={this.state.prix}
                    placeholder="$"
                    step=".01"
                    type="number"
                    onChange={this.handleChange}
                  />
                </fieldset>
                <div className="p-6 bg-donaGray mt-6">
                  <label
                    className="donaFormFieldLabel"
                    htmlFor="filter_document">
                    {t(
                      "Ajouter un produit pour l'offre groupée (doit avoir été déposé au préalable)"
                    )}
                  </label>
                  {this.state.comboOffresGroupes.length > 0 && (
                    <FilterDualListBox
                      selected={this.state.documentsGroupes}
                      options={this.state.comboOffresGroupes}
                      onChange={this.onChangeDualListBox}
                    />
                  )}
                </div>

                {this.state.documentsGroupes.length > 0 ? (
                  <table>
                    <caption
                      className="mt-6"
                      style={{
                        fontWeight: "bold",
                        textAlign: "initial",
                        height: "1.667em",
                        width: "100%",
                        fontsize: "1em"
                      }}>
                      {t("Produit(s) déjà ajouté(s) dans l'offre groupée")}
                    </caption>
                    <tbody>
                      <tr className="bg-donaBlue border-white">
                        <th
                          scope="col"
                          className="w-10 text-center text-white border-0">
                          {t("Index")}
                        </th>
                        <th
                          scope="col"
                          className="w-3/12 text-center text-white border-0">
                          {t("ISBN")}
                        </th>
                        <th
                          scope="col"
                          className="w-9/12 text-center text-white border-0">
                          {t("Titre")}
                        </th>
                      </tr>
                      {this.state.documentsGroupes.map((document, index) => {
                        return (
                          <tr
                            key={`tr-${index}`}
                            className="text-center"
                            style={{
                              backgroundColor: index % 2 === 0 ? "#eaebec" : ""
                            }}>
                            <td key={`document-${index}`} className="p-2">
                              {document.id_produit}
                            </td>
                            <td key={`isbn-${index}`} className="p-2">
                              {document.identifiant}
                            </td>
                            <td
                              key={`titre-${index}`}
                              className="text-left p-2">
                              {document.titre}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="w-full p-4 bg-donaGray text-center">
                    {t("Aucun produit ajouté")}
                  </div>
                )}

                <fieldset
                  className="border border-donaGray px-10 pt-4 pb-6 mb-5 shadow-lg mt-6"
                  style={{ textAlign: "-webkit-center" }}>
                  <legend>
                    {t("Image(s) couverture(s) pour l'offre groupée")}
                  </legend>

                  {this.state.hyperlien_image.length > 0 && (
                    <DraggableCards
                      items={this.state.hyperlien_image}
                      onChange={this.onChangeContent}
                      removeCard={this.removeCard}
                    />
                  )}

                  <div className="my-5 mt-6">
                    <label className="rounded-full button-color--invertTurquoisepale w-64 px-4  py-1 text-center">
                      <input
                        type="file"
                        multiple
                        className="invisible w-0"
                        onChange={this.addLien_fichier_image}
                        accept=".png, .jpg, .jpeg, .gif"
                      />
                      {t("Ajouter une(des) image(s)")}
                    </label>
                  </div>
                </fieldset>

                <div className="mt-10 mb-10 text-center">
                  <Button
                    disabled={this.state.isFormSubmit}
                    className="donaButton button-color--purple w-64"
                    type="submit">
                    {this.state.isFormSubmit ? (
                      <FontAwesomeIcon icon="spinner" spin />
                    ) : (
                      t("Sauvegarder")
                    )}
                  </Button>
                  <Button
                    className="donaButton button-color--invertMauve mx-3 w-64"
                    onClick={this.clearChanges}>
                    {t("Annuler")}
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      </>
    );
  }
}
export default OffresGroupesDetails;
