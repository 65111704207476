import React from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "../styles/FilterDualListBox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faChevronLeft, faChevronRight);

class FilterDualListBox extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = { selected: [], data: [] };
  }

  componentDidMount() {
    this.setState({
      data: this.props.options,
      selected: this.formatedComboData(this.props.selected)
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({ selected: this.formatedComboData(this.props.selected) });
    }
    if (this.props.options !== prevProps.options) {
      this.setState({ data: this.props.options });
    }
  }

  onChange(selected) {
    this.setState({ selected }, () => this.props.onChange(selected));
  }

  formatedComboData(array) {
    let tempFormatedComboData = [];
    array.forEach((item) => {
      tempFormatedComboData.push(item.identifiant);
    });
    return tempFormatedComboData;
  }

  render() {
    return (
      <DualListBox
        key={"index"}
        canFilter
        options={this.state.data}
        onChange={this.onChange}
        selected={this.state.selected}
        icons={{
          moveLeft: (
            <FontAwesomeIcon
              key={0}
              icon={faChevronLeft}
              size="1x"
              title="Retirer les produits sélectionnés"
            />
          ),
          moveAllLeft: [
            <FontAwesomeIcon
              key={1}
              icon={faChevronLeft}
              size="1x"
              title="Retirer tous les produits"
            />,
            <FontAwesomeIcon
              key={2}
              icon={faChevronLeft}
              size="1x"
              title="Retirer tous les produits"
            />
          ],
          moveRight: (
            <FontAwesomeIcon
              key={3}
              icon={faChevronRight}
              size="1x"
              title="Ajouter les produits sélectionnés"
            />
          ),
          moveAllRight: [
            <FontAwesomeIcon
              key={4}
              icon={faChevronRight}
              size="1x"
              title="Ajouter tous les produits"
            />,
            <FontAwesomeIcon
              key={5}
              icon={faChevronRight}
              size="1x"
              title="Ajouter tous les produits"
            />
          ]
        }}
      />
    );
  }
}
export default FilterDualListBox;
