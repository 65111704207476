import React from "react";
import { Consumer } from "../libs/ContextApi";

export function withAuthConsumer(Component) {
  return function AuthContextComponent(props) {
    return (
      <Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </Consumer>
    );
  };
}
